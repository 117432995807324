import {Injectable} from '@angular/core';
import {User, UserData, UserResponseModel} from '../models/User';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseModel} from '../models/Common';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};

@Injectable({
    providedIn: 'root'
})

export class AccountService {
    public user: User = null;
    public token: string;
    private userURL = environment.baseUrl + '/User';
    private pwResetURL = environment.baseUrl + '/Account/ResetPassword';
    private pwChangeURL = environment.baseUrl + '/Account/ChangePassword';
    private pwResetRequestURL = environment.baseUrl + '/Account/ForgotPassword';

    constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {
        const data = localStorage.getItem('user');
        if (data && data !== 'undefined') {
            this.user = JSON.parse(data);
            this.token = localStorage.getItem('token');
        }
        httpOptions.headers = httpOptions.headers.set('Authorization', this.getTokenData());

    }

    storeUserData(userData: UserData) {
        localStorage.setItem('user', JSON.stringify(userData.user));
        localStorage.setItem('token', userData.token.access_token);
    }

    getUserData(): User {
        return JSON.parse(localStorage.getItem('user'));
    }

    getTokenData(): string {
        return localStorage.getItem('token');
    }

    removeData() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.token = null;
        this.user = null;
    }

    isAuthenticated() {
        // check whether the token is expired
        return !this.jwtHelper.isTokenExpired(this.getTokenData());
    }

    getUserDetails() {
        const loggedInUser = this.getUserData();
        return this.http.get<ResponseModel<UserResponseModel>>(this.userURL + '/' + loggedInUser.id, httpOptions);
    }

    updateUserDetails(user: UserResponseModel) {
        return this.http.put<ResponseModel<UserResponseModel>>(this.userURL, user, httpOptions);
    }

    resetPassword(resetModel) {
        return this.http.post<any>(this.pwResetURL, resetModel, httpOptions);
    }

    changePassword(changeModel) {
        return this.http.post<any>(this.pwChangeURL, changeModel, httpOptions);
    }

    requestPasswordReset(passwordResetRequestModel) {
        return this.http.post<any>(this.pwResetRequestURL, passwordResetRequestModel, httpOptions);
    }
}
