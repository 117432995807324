import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {EventService} from '../../services/event.service';
import {UtilsService} from '../../services/utils.service';
import {Router} from '@angular/router';
import {EVENT_STATUSES} from '../../models/Constants';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css', '../../advance-request/advance-request-view/advance-request-view.component.css', '../../advance-request/advance-request-form/advance-form.component.css']
})
export class EventsComponent implements OnInit, OnDestroy {
  public events = [];
  public eventStatus = EVENT_STATUSES;
  public selectedEvent;
  public currentUser;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private eventService: EventService,
    private utilService: UtilsService, 
    private router: Router,
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher) { 
      this.mobileQuery = media.matchMedia('(max-width: 700px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    // const userEmail = this.currentUser.email;
    const userEmail = 'user-email@et.jsi.com';

    this.eventService.getEventForUser(userEmail).subscribe(
      res => {
        this.events = res.data;
      });
  }

  formatDateTime(date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const dateTime = new Date(date);
    return (new Date(date)).toLocaleTimeString() + ' ' + months[dateTime.getMonth()] + ' ' +
        this.appendLeadingZeroes(dateTime.getDate());
  }
  appendLeadingZeroes(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n
  }

  getDateRange(stDate, edDate) {
    const startDate = (new Date(stDate)).toDateString().split(' ').slice(1).join(' ');
    const endDate = (new Date(edDate)).toDateString().split(' ').slice(1).join(' ');
    return startDate + ' - ' + endDate;
  }
  createEventRequest() {
    this.router.navigateByUrl('events/request');
  }
  eventRowClicked(event) {
    this.router.navigateByUrl('events/view/' + event.id);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change',this._mobileQueryListener);
  }
}
