<div class="relative">
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
        <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
            <mat-card class="card-background no-padding" style="box-shadow: unset !important">
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="30" class="border-right">
                        <mat-nav-list class="no-padding">
                            <a mat-list-item *ngFor="let lookupType of lookupTypes" [ngClass]="selectedLookupType.code === lookupType.code? 'active': ''"  (click)="selectLookup(lookupType)">{{lookupType.name}}</a>
                        </mat-nav-list>
                    </div>
                    <div fxFlex="70" style="padding: 18px;" fxLayout="column">
                        <div style="text-align: right; padding-bottom: 13px;">
                            <button class="primary-color" mat-raised-button (click)="addNewLookup()">Add New Value</button>
                        </div>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element; let index = index">
                                    <span>{{index + 1}}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name</th>
                                <td mat-cell *matCellDef="let element;">
                                    <span *ngIf="element.id">{{element.name}}</span>
                                    <span *ngIf="!element.id">
                                        <mat-form-field style="width: 100%">
                                            <input matInput placeholder="Name" type="text" name="name" required [(ngModel)]="element.name">
                                        </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef>Code</th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.id">{{element.code}}</span>
                                    <span *ngIf="!element.id"><mat-form-field style="width: 100%">
                                    <input matInput placeholder="Code" type="text" name="code" required [(ngModel)]="element.code">
                                </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>Code</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon style="cursor: pointer;" (click)="saveNewLookup()">check</mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>