export class ResponseModel<T> {
    message: string;
    model: T;
    result: number;
}
export class LookupModel {
    activityCode: string;
    name:         string;
    code:         string;
    id:           number;
    isActive:     boolean;
    createdBy:    number;
    createdDate:  Date;
    modifiedBy:   number;
    modifiedDate: Date;
    rowGuid:      string;
}
export interface DialogData {
    title: string;
    comment: string;
    placeholder: string;
    actionName: string;
    actionClass: string;
}

export class AuditFields {
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    isActive: boolean;
    rowGuid: string;
}

export class TotalCost {
    perDiemFlat: number = 0;
    perDiemHotel: number = 0;
    transport: number = 0;
    total: number = 0;
}
export class OtherCost {
    venue: number = 0;
    stationary: number = 0;
    refreshment: number = 0;
    total: number = 0;
}
