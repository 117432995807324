import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import {Router} from "@angular/router";
import {AccountService} from '../../services/account.service';
import {PasswordResetModel, PasswordResetRequestModel} from '../../models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
   selector: 'ms-forgot-password',
   templateUrl:'./forgot-password-component.html',
   styleUrls: ['./forgot-password-component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
	
	email: string;
  password: string;
  public passwordResetRequestForm: FormGroup;

  constructor(
    private router: Router, private accountService: AccountService, private fb: FormBuilder
  ) { }

    ngOnInit(): void {
      this.passwordResetRequestForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]]
      })
    }

    requestPasswordReset() {
      const passwordResetRequestModel = new PasswordResetRequestModel();
      passwordResetRequestModel.email = this.passwordResetRequestForm.controls.email.value;
      this.accountService.requestPasswordReset(passwordResetRequestModel).subscribe(
          resp => {

          },
          err => {

          },
      () => {

        }
      )
    }

    public checkError = (controlName: string, errorName: string) => {
        return this.passwordResetRequestForm.controls[controlName].hasError(errorName) && this.passwordResetRequestForm.controls[controlName].dirty;
    };

}



