<mat-card class="form-container-card no-padding" *ngIf="!loading">

    <div class="header" style="justify-content: center;     
    background: rgb(255 255 255) !important;
    border-radius: 5px 5px 0 0;
    color: red;
    border: solid red 1px;
    height: 55px;
    padding-top: 4px;">
        <h4 class="title-text" *ngIf="!editMode">EVENT REQUEST FORM</h4>
        <h4 class="title-text" *ngIf="editMode">EVENT REQUEST EDIT FORM</h4>
    </div>
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step *ngIf="!isVenuEditMode" [stepControl]="eventRequestForm">
            <form #form="ngForm" [formGroup]="eventRequestForm" class="form-body">
                <ng-template matStepLabel>Basic Information</ng-template>

                <div fxLayout="column" fxLayoutAlign="center stretch">
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Event Name" type="text" name="eventName"
                                    formControlName="eventName" required>

                                <!-- <mat-error *ngIf="checkError('eventName', 'required')">Event title is
                                    required
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="eventType" placeholder="Event Type" required
                                    [disabled]="editMode">
                                    <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.id">
                                        {{eventType.data.name}}
                                    </mat-option>
                                </mat-select>

                                <!-- <mat-error *ngIf="checkError('eventType', 'required')">Event Type is
                                    required
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <textarea matInput formControlName="eventSummary" cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="2" placeholder="Type your summary here..." required></textarea>
                                <input matInput type="textarea" name="eventSummary">
                            </mat-form-field>

                            <mat-form-field fxFlex="35">
                                <textarea matInput formControlName="eventDescription" cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="2" placeholder="Type your description here..."
                                    required></textarea>
                                <input matInput type="textarea" name="eventDescription">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutAlign="space-around">
                            <!-- <mat-form-field fxFlex="35">
                                <mat-label>Start Date</mat-label>
                                <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                    placeholder="dd/mm/yyyy" required [min]="minStartDate"
                                    (dateChange)="startDateChanged($event)">
                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startPicker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field fxFlex="35">
                                <mat-label>End Date</mat-label>
                                <input formControlName="endDate" matInput [matDatepicker]="endPicker"
                                    placeholder="dd/mm/yyyy" required [min]="minEndDate" [disabled]=disableEndDate>
                                <mat-datepicker-toggle matSuffix [for]="endPicker" [disabled]=disableEndDate>
                                </mat-datepicker-toggle>
                                <mat-datepicker #endPicker [disabled]=disableEndDate></mat-datepicker>
                            </mat-form-field> -->
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="hostInstitution" placeholder="Host Institution" required
                                    [disabled]="editMode">
                                    <mat-option *ngFor="let hostInstitution of hostInstitutions"
                                        [value]="hostInstitution.id">
                                        {{hostInstitution.data.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="toInstitution" placeholder="To Institution" required
                                    [disabled]="editMode" multiple>
                                    <mat-option *ngFor="let hostInstitution of hostInstitutions"
                                        [value]="hostInstitution.id">
                                        {{hostInstitution.data.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="trainingQuarter" placeholder="Training Quarter" required
                                    [disabled]="editMode" multiple>
                                    <mat-option *ngFor="let quarter of trainingQuarters" [value]="quarter.id">
                                        {{quarter.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div fxFlex="35"></div>
                        </div>

                    </div>
                </div>

                <div fxLayout="column">
                    <h4 class="section-label">Project Information</h4>

                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                        fxLayoutAlign="space-around">
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="project" placeholder="Project" required [disabled]="editMode">
                                <mat-option *ngFor="let project of projects" [value]="project.id">
                                    {{project.data.name}}
                                </mat-option>
                            </mat-select>

                            <!-- <mat-error *ngIf="checkError('project', 'required')">Project is
                                required
                            </mat-error> -->
                        </mat-form-field>

                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="workPlanActivity" placeholder="Work Plan Activity" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let activity of workPlanActivities" [value]="activity.id">
                                    {{activity.data.name}}
                                </mat-option>
                            </mat-select>

                            <!-- <mat-error *ngIf="checkError('workPlanActivity', 'required')">Event Type is
                                required
                            </mat-error> -->
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                        fxLayoutAlign="space-around">
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="subContractor" placeholder="Subcontractor" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let hostInstitution of hostInstitutions"
                                    [value]="hostInstitution.id">
                                    {{hostInstitution.data.name}}
                                </mat-option>
                            </mat-select>

                            <!-- <mat-error *ngIf="checkError('project', 'required')">Project is
                                required
                            </mat-error> -->
                        </mat-form-field>

                        <div fxFlex="35">
                        </div>

                    </div>
                </div>

                <div fxLayout="column" formGroupName="poc">
                    <h4 class="section-label">POC Information</h4>

                    <div style="padding-top: 12px;" fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                        fxLayout.xs="column" fxLayoutAlign="space-around">
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="eventRequester" placeholder="Event Requester" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let user of usersList" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="eventApprover" placeholder="Event Approver" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let user of usersList" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-around">
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="programOfficer" placeholder="Program Officer" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let user of usersList" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="meetingManager" placeholder="Meeting Manager" required
                                [disabled]="editMode">
                                <mat-option *ngFor="let user of usersList" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->

                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                        fxLayoutAlign="space-around">
                        <mat-form-field fxFlex="35">
                            <mat-select formControlName="finance" placeholder="Finance" required [disabled]="editMode">
                                <mat-option *ngFor="let user of usersList" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div fxFlex="35"></div>
                    </div>
                </div>

                <div fxLayout="column">
                    <h4 class="section-label">Financial Details</h4>
                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="12px"
                        [style.margin-top]="'20px'" fxLayoutAlign="space-evenly">
                        <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="38">
                            <mat-form-field>
                                <input matInput placeholder="Number of actual training days" type="number" min=0
                                    name="noActualTrainingDays" formControlName="noActualTrainingDays" required>
                            </mat-form-field>
                            <!-- <mat-form-field>
                                <input matInput placeholder="Total number of hotel days" type="number" min=0
                                    name="totalHotelDays" formControlName="totalHotelDays" required>
                            </mat-form-field> -->
                            <!-- <mat-form-field>
                                <input matInput placeholder="Estimated total number of travel days(one way)"
                                    type="number" min=0 name="estimatedOneWayTravelDays"
                                    formControlName="estimatedOneWayTravelDays" required>
                            </mat-form-field> -->
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="estimatedOneWayTravelDays"
                                    placeholder="Estimated total number of travel days(one way)" required
                                    [disabled]="editMode">
                                    <mat-option *ngFor="let days of travelDays" [value]="days.id">
                                        {{days.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                            fxLayoutAlign="space-evenly" fxFlex="38">
                            <mat-form-field fxFlex="25">
                                <input matInput placeholder="Number of hotel days to budget" type="number" min=0
                                    name="noHotelDaysBudget" formControlName="noHotelDaysBudget" required>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input matInput placeholder="Total number of training days" type="number" min=0
                                    name="totalTrainingDays" formControlName="totalTrainingDays" required>
                            </mat-form-field>
                        </div>
                    </div>

                    <div style="margin-top: 25px;" fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                        fxLayout.xs="column" fxLayoutAlign="space-evenly">
                        <mat-form-field fxFlex="25" appearance="none">
                            <mat-slide-toggle formControlName="breakfastProvided" [disabled]="editMode">
                                Breakfast Provided</mat-slide-toggle>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                        <mat-form-field fxFlex="25" appearance="none">
                            <mat-slide-toggle formControlName="lunchProvided" [disabled]="editMode">Lunch
                                Provided</mat-slide-toggle>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                        <mat-form-field fxFlex="25" appearance="none">
                            <mat-slide-toggle formControlName="dinnerProvided" [disabled]="editMode">Dinner
                                Provided</mat-slide-toggle>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                        fxLayoutAlign="space-evenly">
                        <mat-form-field fxFlex="25" appearance="none">
                            <div fxLayout="column">
                                <label style="font-size: smaller;color: gray;padding-left: 46px;">Avg. Cost -
                                    {{REFRESMENT_PER_PERSON_PER_DAY}} birr per person</label>
                                <mat-slide-toggle formControlName="refreshmentProvided" [disabled]="editMode">
                                    Refreshment
                                    Provided</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </div>

                        </mat-form-field>
                        <mat-form-field fxFlex="25" appearance="none">
                            <div fxLayout="column">
                                <label style="font-size: smaller;color: gray;padding-left: 46px;">Avg. Cost -
                                    {{STATIONARY_COST_PER_PERSON}} birr per person</label>
                                <mat-slide-toggle formControlName="stationaryCost" [disabled]="editMode">Stationary
                                    Cost</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </div>
                        </mat-form-field>
                        <mat-form-field fxFlex="25" appearance="none">
                            <div fxLayout="column">
                                <label style="font-size: smaller;color: gray;padding-left: 46px;">Avg. Cost -
                                    {{AVG_AIR_TRAVEL_COST}} birr per person</label>
                                <mat-slide-toggle formControlName="airTimeCost" [disabled]="editMode">Air Time Cost
                                </mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                        fxLayoutAlign="space-evenly">
                        <div fxFlex="3"></div>
                        <mat-form-field fxFlex="35" style="margin-top: 8px;">
                            <input matInput placeholder="Number of unique sessions needed to complete" type="number"
                                min=0 name="noUniqueSessions" formControlName="noUniqueSessions" required>
                        </mat-form-field>
                        <div fxFlex="25">
                        </div>
                        <div fxFlex="25" *ngIf="editMode">

                        </div>
                        <div fxFlex='25' *ngIf="!editMode"></div>

                        <!-- <div fxFlex="25"></div> -->
                    </div>


                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="trainerTraineeForm">
            <form #form="ngForm" [formGroup]="trainerTraineeForm" class="form-body">
                <ng-template matStepLabel>Trainer/ Trainee/ City Information</ng-template>

                <mat-tab-group mat-align-tabs="start">
                    <mat-tab *ngIf="!isVenuEditMode" label="Trainer Information">
                        <div fxLayout="column">
                            <mat-card class="accordion-headers-align" base-card-box-shadow>
                                <div formArrayName="institutionTrainer">
                                    <mat-label class="section-label" style="padding-left: 25px;  padding-right: 5px;"
                                        [matTooltip]=trainerByInstitution matTooltipPosition="after">By Institution
                                    </mat-label>
                                    <mat-card-content
                                        *ngFor="let trainerItem of institutionTrainer.controls; let instIndex = index">
                                        <ng-container [formGroupName]="instIndex">
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                    #{{instIndex + 1}}
                                                </div>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="institutionTrainerType"
                                                        placeholder="Institution" required [disabled]="editMode">
                                                        <mat-option *ngFor="let institution of byInstitution"
                                                            [disabled]=institution.disabled [value]="institution.id">
                                                            {{institution.name}}
                                                        </mat-option>
                                                    </mat-select>

                                                    <!-- <mat-error *ngIf="checkError('pocType', 'required')">Approver is
                                                        required
                                                    </mat-error> -->
                                                </mat-form-field>

                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainer" type="number" min=0
                                                        name="numberOfInstitutionTrainer"
                                                        formControlName="numberOfInstitutionTrainer" required>
                                                </mat-form-field>

                                                <div fxFlex="20">
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkAddHidden('institutionTrainer', instIndex) ? 'none' : 'initial' }"
                                                        (click)="addInstitutionTrainer(instIndex)">
                                                        <mat-icon style="color: #b14900;">add_circle_outline</mat-icon>
                                                    </button>
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkDeleteHidden('institutionTrainer', instIndex) ? 'none' : 'initial' }"
                                                        (click)="removeInstitutionTrainer(instIndex)">
                                                        <mat-icon style="color: #b14900;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-card-content>
                                </div>

                                <div formArrayName="jobTrainer">
                                    <mat-label class="section-label" style="padding-left: 25px;  padding-right: 5px;"
                                        [matTooltip]=trainerByJob matTooltipPosition="after">By Job Classification
                                    </mat-label>
                                    <mat-card-content
                                        *ngFor="let trainerItem of jobTrainer.controls; let jobIndex = index">

                                        <ng-container [formGroupName]="jobIndex">
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                    #{{jobIndex + 1}}
                                                </div>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="jobTrainerType"
                                                        placeholder="Job Classification" required [disabled]="editMode">
                                                        <mat-option *ngFor="let job of byJobClassification"
                                                            [disabled]=job.disabled [value]="job.id">
                                                            {{job.name}}
                                                        </mat-option>
                                                    </mat-select>

                                                    <!-- <mat-error *ngIf="checkError('pocType', 'required')">Approver is
                                                        required
                                                    </mat-error> -->
                                                </mat-form-field>

                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainer" type="number" min=0
                                                        name="numberOfJobTrainer" formControlName="numberOfJobTrainer"
                                                        required>
                                                </mat-form-field>

                                                <div fxFlex="20">
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkAddHidden('jobTrainer', jobIndex) ? 'none' : 'initial' }"
                                                        (click)="addJobTrainer(jobIndex)">
                                                        <mat-icon style="color: #b14900;">add_circle_outline</mat-icon>
                                                    </button>
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkDeleteHidden('jobTrainer', jobIndex) ? 'none' : 'initial' }"
                                                        (click)="removeJobTrainer(jobIndex)">
                                                        <mat-icon style="color: #b14900;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </mat-card-content>
                                </div>

                                <mat-card-actions>
                                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                        fxLayoutAlign="space-around">
                                        <div *ngIf="!trainerValuesMatch" fxFlex="50"
                                            style="text-align: left; font-size: small;font-style: italic;">
                                            <mat-icon class="warning-icon">warning </mat-icon> <span
                                                class="warning-label">Trainer Count by institution and by job doesnt
                                                match </span>
                                        </div>
                                        <div fxFlex [ngStyle]="{'color': !trainerValuesMatch ? 'orange' : 'initial' }"
                                            style="text-align: right;">Total Trainer: {{totalTrainer.value}}</div>
                                    </div>
                                </mat-card-actions>

                            </mat-card>
                        </div>

                        <div fxLayout="column">
                            <h4 class="section-label">Finance Information</h4>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around" style="padding: 10px 0">
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="mAndE"
                                        (change)="onFinanceChange()" [disabled]="nonFlatRateDisabled">M & E
                                    </mat-checkbox>
                                </div>
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="lodging"
                                        (change)="onFinanceChange()" [disabled]="nonFlatRateDisabled">Lodging
                                    </mat-checkbox>
                                </div>
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="flatRate"
                                        (change)="onFinanceChange()" [disabled]="flatRateDisabled">Flat rate
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around">
                                <div fxFlex="35"></div>
                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                    <input matInput placeholder="Number of air travelers expected" type="number" min=0
                                        name="numberOfAirTravelers" formControlName="numberOfAirTravelers" required>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around">
                                <div fxFlex="35"></div>
                                <mat-form-field fxFlex="35">
                                    <input matInput placeholder="Total number of attendees" type="number" min=0
                                        name="totalAttendees" formControlName="totalAttendees"
                                        [disabled]="disableTotalAttendee">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="!isVenuEditMode" label="Trainee Information">
                        <div fxLayout="column">
                            <mat-card class="accordion-headers-align" base-card-box-shadow>
                                <div formArrayName="institutionTrainee">
                                    <mat-label class="section-label" style="padding-left: 25px;  padding-right: 5px;"
                                        [matTooltip]=traineeByInstitution matTooltipPosition="after">By Institution
                                    </mat-label>
                                    <mat-card-content
                                        *ngFor="let traineeItem of institutionTrainee.controls; let instTraineeIndex = index">
                                        <ng-container [formGroupName]="instTraineeIndex">
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                    #{{instTraineeIndex + 1}}
                                                </div>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="institutionTraineeType"
                                                        placeholder="Institution" required [disabled]="editMode">
                                                        <mat-option *ngFor="let institution of byInstitutionTrainee"
                                                            [disabled]=institution.disabled [value]="institution.id">
                                                            {{institution.name}}
                                                        </mat-option>
                                                    </mat-select>

                                                    <!-- <mat-error *ngIf="checkError('traineeType', 'required')">Approver is
                                                        required
                                                    </mat-error> -->
                                                </mat-form-field>

                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainee" type="number" min=0
                                                        name="numberOfInstitutionTrainee"
                                                        formControlName="numberOfInstitutionTrainee" required>
                                                </mat-form-field>

                                                <div fxFlex="20">
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkAddHidden('institutionTrainee', instTraineeIndex) ? 'none' : 'initial' }"
                                                        (click)="addInstitutionTrainee(instTraineeIndex)">
                                                        <mat-icon style="color: #b14900;">add_circle_outline</mat-icon>
                                                    </button>
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkDeleteHidden('institutionTrainee', instTraineeIndex) ? 'none' : 'initial' }"
                                                        (click)="removeInstitutionTrainee(instTraineeIndex)">
                                                        <mat-icon style="color: #b14900;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-card-content>
                                </div>

                                <div formArrayName="jobTrainee">
                                    <mat-label class="section-label" style="padding-left: 25px;  padding-right: 5px;"
                                        [matTooltip]=traineeByJob matTooltipPosition="after">By Job Classification
                                    </mat-label>
                                    <mat-card-content
                                        *ngFor="let traineeItem of jobTrainee.controls; let jobTraineeIndex = index">
                                        <ng-container [formGroupName]="jobTraineeIndex">
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                    #{{jobTraineeIndex + 1}}
                                                </div>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="jobTraineeType"
                                                        placeholder="Job Classification" required [disabled]="editMode">
                                                        <mat-option *ngFor="let job of byJobClassificationTrainee"
                                                            [disabled]=job.disabled [value]="job.id">
                                                            {{job.name}}
                                                        </mat-option>
                                                    </mat-select>

                                                    <!-- <mat-error *ngIf="checkError('traineeType', 'required')">Approver is
                                                        required
                                                    </mat-error> -->
                                                </mat-form-field>

                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainee" type="number" min=0
                                                        name="numberOfJobTrainee" formControlName="numberOfJobTrainee"
                                                        required>
                                                </mat-form-field>

                                                <div fxFlex="20">
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkAddHidden('jobTrainee', jobTraineeIndex) ? 'none' : 'initial' }"
                                                        (click)="addJobTrainee(jobTraineeIndex)">
                                                        <mat-icon style="color: #b14900;">add_circle_outline</mat-icon>
                                                    </button>
                                                    <button mat-button color="primary" mat-icon-button
                                                        [ngStyle]="{'display': checkDeleteHidden('jobTrainee', jobTraineeIndex) ? 'none' : 'initial' }"
                                                        (click)="removeJobTrainee(jobTraineeIndex)">
                                                        <mat-icon style="color: #b14900;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-card-content>
                                </div>

                                <mat-card-actions>
                                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                        fxLayoutAlign="space-around">
                                        <div *ngIf="!traineeValuesMatch" fxFlex="50"
                                            style="text-align: left; font-size: small;font-style: italic;">
                                            <mat-icon class="warning-icon">warning </mat-icon> <span
                                                class="warning-label">Trainee Count by institution and by job doesnt
                                                match </span>
                                        </div>
                                        <div fxFlex [ngStyle]="{'color': !traineeValuesMatch ? 'orange' : 'initial' }"
                                            style="text-align: right;">Total Trainee: {{totalTrainee.value}}</div>
                                    </div>
                                </mat-card-actions>

                            </mat-card>
                        </div>

                        <div fxLayout="column">
                            <h4 class="section-label">Finance Information</h4>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around" style="padding: 10px 0">
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="mAndE"
                                        (change)="onFinanceChange()" [disabled]="nonFlatRateDisabled">M & E
                                    </mat-checkbox>
                                </div>
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="lodging"
                                        (change)="onFinanceChange()" [disabled]="nonFlatRateDisabled">Lodging
                                    </mat-checkbox>
                                </div>
                                <div fxFlex="25">
                                    <mat-checkbox class="example-margin" formControlName="flatRate"
                                        (change)="onFinanceChange()" [disabled]="flatRateDisabled">Flat rate
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around">
                                <div fxFlex="35"></div>
                                <mat-form-field fxFlex="35">
                                    <input matInput placeholder="Number of air travelers expected" type="number" min=0
                                        name="numberOfAirTravelers" formControlName="numberOfAirTravelers" required>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                fxLayoutAlign="space-around">
                                <div fxFlex="35"></div>
                                <mat-form-field fxFlex="35">
                                    <input matInput placeholder="Total number of attendees" type="number" min=0
                                        name="totalAttendees" formControlName="totalAttendees"
                                        [disabled]="disableTotalAttendee">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="City Information">
                        <div fxLayout="column">
                            <mat-card class="accordion-headers-align" base-card-box-shadow>
                                <div fxLayout="column" formArrayName="session">
                                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                        fxLayoutAlign="start">
                                        <div fxFlex="5">
                                        </div>
                                        <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                            <mat-select formControlName="Meeting Manager" placeholder="Meeting Manager"
                                                required [disabled]="editMode">
                                                <mat-option *ngFor="let user of usersList" [disabled]=user.disabled
                                                    [value]="user.id">
                                                    {{user.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <mat-label class="section-label" style="padding-left: 25px;  padding-right: 5px;">
                                        Session Information
                                    </mat-label>
                                    <mat-card-content *ngFor="let sess of session.controls; let i = index">
                                        <ng-container [formGroupName]="i">
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="3" style="padding-top: 15px;">
                                                    #{{i + 1}}
                                                </div>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="Session Manager"
                                                        placeholder="Session Manager" required [disabled]="editMode">
                                                        <mat-option *ngFor="let user of usersList"
                                                            [disabled]=user.disabled [value]="user.id">
                                                            {{user.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <div fxFlex="55" style="padding-top: 15px;">
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                </div>
                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="city" placeholder="City" required
                                                        [disabled]="editMode">
                                                        <mat-option *ngFor="let city of testLookups"
                                                            [disabled]=city.disabled [value]="city.id">
                                                            {{city.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field fxFlex="35">
                                                    <mat-select formControlName="venue" placeholder="Venue" required
                                                        [disabled]="!isVenuEditMode">
                                                        <mat-option *ngFor="let venue of testVenues"
                                                            [disabled]=venue.disabled [value]="venue.id">
                                                            {{venue.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div fxFlex="20">
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column"
                                                fxLayout.xs="column" fxLayoutAlign="space-around">
                                                <div fxFlex="5" style="padding-top: 15px;">
                                                </div>

                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainers" type="number" min=0
                                                        name="numberOfTrainers"
                                                        formControlName="numberOfTrainers" required>
                                                </mat-form-field>
                                                <mat-form-field fxFlex="35" style="padding-top: 7px;">
                                                    <input matInput placeholder="Number of Trainees" type="number" min=0
                                                        name="numberOfTrainees" 
                                                        formControlName="numberOfTrainees" required>
                                                </mat-form-field>

                                                <div fxFlex="20" style="padding-top: 15px;">
                                                    <button mat-button color="primary" mat-icon-button [disabled]="editMode"
                                                        [ngStyle]="{'display': checkAddHidden('session', i) ? 'none' : 'initial' }"
                                                        (click)="addSession()">
                                                        <mat-icon [ngStyle]="{'color': editMode ? 'gray' : '#b14900' }">add_circle_outline</mat-icon>
                                                    </button>
                                                    <button mat-button color="primary" mat-icon-button [disabled]="editMode"
                                                        [ngStyle]="{'display': checkDeleteHidden('session', i) ? 'none' : 'initial' }"
                                                        (click)="removeSession(i)">
                                                        <mat-icon [ngStyle]="{'color': editMode ? 'gray' : '#b14900' }">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-card-content>
                                </div>

                                <!-- <mat-card-actions>
                                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-around">
                                        <div *ngIf="!traineeValuesMatch" fxFlex="50"
                                            style="text-align: left; font-size: small;font-style: italic;">
                                            <mat-icon class="warning-icon">warning </mat-icon> <span
                                                class="warning-label">Trainee Count by institution and by job doesnt
                                                match </span>
                                        </div>
                                        <div fxFlex [ngStyle]="{'color': !traineeValuesMatch ? 'orange' : 'initial' }"
                                            style="text-align: right;">Total Trainee: {{totalTrainee.value}}</div>
                                    </div>
                                </mat-card-actions> -->

                            </mat-card>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
        </mat-step>
        <mat-step *ngIf="!isVenuEditMode">
            <ng-template matStepLabel>Summary</ng-template>
            <mat-card class="form-container-card no-padding">
                <div fxLayout="column" style="padding:20px">
                    <label style="font-size: 20px"><b></b></label>
                    <div fxLayout="row">
                        <span fxFlex>
                            <label
                                style="font-size: x-large;color: #6a6a6a;"><b>{{eventRequestForm.get('eventName').value}}</b></label>
                        </span>
                    </div>
                </div>
                <mat-tab-group>
                    <mat-tab label="Budget">
                        <div style="background-color: whitesmoke !important; padding: 15px;">
                            <div class="inset-container" fxLayout="column">
                                <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                                    <div fxFlex="50" fxLayout="column">
                                        <b class="section-label" style="display: block !important;">Total Non-DHA:
                                            Coordinators/Trainers cost </b>
                                        <table style="background-color: white !important;">
                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Per diem - Trainer</span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTrainerCost.perDiemFlat | number}}</span>
                                                </td>

                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Hotel for Trainer</span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTrainerCost.perDiemHotel | number}}</span>
                                                </td>
                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>

                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Transport </span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTrainerCost.transport | number}}</span>
                                                </td>
                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="title-row">
                                                <td class="mat-cell total-label">
                                                    <b>Total</b>
                                                </td>
                                                <td class="mat-cell total-label">
                                                    <b>{{totalTrainerCost.total | number}}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div fxFlex="50" fxLayout="column">
                                        <b class="section-label" style="display: block !important;">Total Trainee
                                            Cost</b>
                                        <table class="mat-table pr-10" style="background-color: white !important;">
                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Per diem - Trainee</span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTraineeCost.perDiemFlat | number}}</span>
                                                </td>
                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Hotel for Trainee</span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTraineeCost.perDiemHotel | number}}</span>
                                                </td>
                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="mat-row">
                                                <td class="mat-cell">
                                                    <span>Transport </span>
                                                </td>
                                                <td class="mat-cell">
                                                    <span>{{totalTraineeCost.transport | number}}</span>
                                                </td>
                                            </tr>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="title-row">
                                                <td class="mat-cell total-label">
                                                    <b>Total</b>
                                                </td>
                                                <td class="mat-cell total-label">
                                                    <b>{{totalTraineeCost.total | number}}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div fxFlex fxLayout="column">
                                    <b class="section-label" style="display: block !important;">Venue/Other</b>
                                    <table class="pr-10" style="background-color: white !important;">
                                        <tr class="mat-row">
                                            <td class="mat-cell" style="width: 95%;">
                                                <span>Venue</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{totalOtherCost.venue | number}}</span>
                                            </td>

                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Stationary</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{totalOtherCost.stationary | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>

                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Refreshment</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{totalOtherCost.refreshment | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="title-row">
                                            <td class="mat-cell total-label" style="width: 86%;">
                                                <span>Total</span>
                                            </td>
                                            <td class="mat-cell inline">
                                                <b>{{totalOtherCost.total | number}}</b>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <table class="mat-table final-total"
                                    style="background-color: white !important; padding-bottom: 10px; padding-top: 10px;">
                                    <tr class="mat-row">
                                        <td class="mat-cell total-label final-total-labels">
                                            <b>Total Cost</b>
                                        </td>
                                        <td class="mat-cell total-label final-total-labels reverse-flex">
                                            <b>{{totalOtherCost.total + totalTraineeCost.total + totalTrainerCost.total | number}}</b>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </mat-tab>
                    <mat-tab label="Summary">
                        <div style="background-color: whitesmoke !important; padding: 15px;" fxLayout="column">
                            <div class="inset-container" fxLayout="column">
                                <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                                    <div fxFlex="50" fxLayout="column">
                                        <b class="section-label" style="display: block !important;">Trainees</b>
                                        <table class="pr-10" style="background-color: white !important;">
                                            <tr class="title-row">
                                                <td class="pt-10 pb-10">
                                                    <b>By institution</b>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngFor="let item of trainerTraineeForm.value.institutionTrainee; let i=index">
                                                <tr class="mat-row" *ngIf="item['institutionTraineeType'] != null">
                                                    <td class="mat-cell">
                                                        <span>{{ item['institutionTraineeType'] != null ? getOptionName(item['institutionTraineeType'], byInstitutionTrainee) : ''}}</span>
                                                    </td>
                                                    <td class="mat-cell">
                                                        <span>{{item['numberOfInstitutionTrainee'] != null ? item['numberOfInstitutionTrainee']: 0 | number}}</span>
                                                    </td>
                                                </tr>
                                                <mat-divider class="mat-table-divider"></mat-divider>
                                            </ng-container>
                                            <tr class="title-row">
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>By Job Classification</b>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngFor="let item of trainerTraineeForm.value.jobTrainee; let i=index">
                                                <tr class="mat-row" *ngIf="item['jobTraineeType'] != null">
                                                    <td class="mat-cell">
                                                        <span>{{ item['jobTraineeType'] != null? getOptionName(item['jobTraineeType'], byJobClassificationTrainee) : ''}}</span>
                                                    </td>
                                                    <td class="mat-cell">
                                                        <span>{{item['numberOfJobTrainee'] != null? item['numberOfJobTrainee']: 0 | number}}</span>
                                                    </td>
                                                </tr>
                                                <mat-divider class="mat-table-divider"></mat-divider>
                                            </ng-container>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="title-row">
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>Total</b>
                                                </td>
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>{{traineeByInstitution | number}}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div fxFlex="50" fxLayout="column">
                                        <b class="section-label" style="display: block !important;">Non-DHA:
                                            Coordinators/Trainers </b>
                                        <table class="mat-table pr-10" style="background-color: white !important;">
                                            <tr class="title-row">
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>By institution</b>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngFor="let item of trainerTraineeForm.value.institutionTrainer; let i=index">
                                                <tr class="mat-row" *ngIf="item['institutionTrainerType'] != null">
                                                    <td class="mat-cell">
                                                        <span>{{ item['institutionTrainerType'] != null? getOptionName(item['institutionTrainerType'], byInstitution) : ''}}</span>
                                                    </td>
                                                    <td class="mat-cell">
                                                        <span>{{item['numberOfInstitutionTrainer'] != null? item['numberOfInstitutionTrainer']: 0 | number}}</span>
                                                    </td>
                                                </tr>
                                                <mat-divider class="mat-table-divider"></mat-divider>
                                            </ng-container>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="title-row">
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>By Job Classification</b>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngFor="let item of trainerTraineeForm.value.jobTrainer; let i=index">
                                                <tr class="mat-row" *ngIf="item['jobTrainerType'] != null">
                                                    <td class="mat-cell">
                                                        <span>{{ item['jobTrainerType'] != null ? getOptionName(item['jobTrainerType'], byJobClassification) : ''}}</span>
                                                    </td>
                                                    <td class="mat-cell">
                                                        <span>{{item['numberOfJobTrainer'] != null ? item['numberOfJobTrainer']: 0 | number}}</span>
                                                    </td>
                                                </tr>
                                                <mat-divider class="mat-table-divider"></mat-divider>
                                            </ng-container>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <mat-divider class="mat-table-divider"></mat-divider>
                                            <tr class="title-row  pt-10 pb-10">
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>Total</b>
                                                </td>
                                                <td class="mat-cell pt-10 pb-10">
                                                    <b>{{trainerByInstitution | number}}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <table class="mat-table pr-10 pt-10 pb-10 white-bg">
                                    <tr class="mat-row">
                                        <td class="mat-cell" style="width: 96%; font-size: larger;">
                                            <b>Total # of attendees</b>
                                        </td>
                                        <td class="mat-cell" style="font-size: larger;">
                                            <b>{{totalAttendees | number}}</b>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <mat-divider class="mat-table-divider" style="padding-bottom: 40px;"></mat-divider>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                                <div fxFlex="50" fxLayout="column">
                                    <table class="mat-table pr-10" style="background-color: whitesmoke !important;">
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Venue City </span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{getOptionName(eventRequestForm.get('cityId').value, testLookups)}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Number of unique sessions needed to complete</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('noUniqueSessions').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Number of actual training days </span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('noActualTrainingDays').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Total number of hotel days (excluding training days)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('totalHotelDays').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                    </table>
                                </div>
                                <div fxFlex="50" fxLayout="column">
                                    <table class="mat-table pr-10" style="background-color: whitesmoke !important;">
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span># of Hotel days to budget </span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('noHotelDaysBudget').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Estimated total number of Travel days (one way)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('estimatedOneWayTravelDays').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Total number of training days inc. travel</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{eventRequestForm.get('totalTrainingDays').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Number of air travellers (estimated)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{trainerTraineeForm.get('numberOfAirTravelers').value | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                    </table>
                                </div>
                            </div>

                            <h4 class="section-label" style="display: block !important;">Per Diem</h4>
                            <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
                                class="white-bg" style="padding: 10px;margin-left: -15px; width: 102%">
                                <div fxFlex="50" fxLayout="column" class="white-bg">
                                    <table class="mat-table pr-10 white-bg">
                                        <tr class="title-row">
                                            <td class="mat-cell pt-10 pb-10">
                                                <b>Trainee</b>
                                            </td>
                                        </tr>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Per Diem Rate (trainee) - Flat Rate (includes hotel)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{PER_DIEM_RATE_FLAT_TRAINEE | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Per Diem Rate (trainee) - Hotel (extra)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{PER_DIEM_RATE_HOTEL_TRAINEE | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="title-row">
                                            <td class="mat-cell pt-10 pb-10">
                                                <b>Non-DHA: Coordinators/Trainers</b>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Per Diem Rate (trainer) - Per diem</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{PER_DIEM_RATE_FLAT_TRAINER | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Per Diem Rate (trainer) - Hotel</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{PER_DIEM_RATE_HOTEL_TRAINER | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="title-row">
                                            <td class="mat-cell pt-10 pb-10">
                                                <b>Transportation</b>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>average air travel cost</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{AVG_AIR_TRAVEL_COST | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>average ground transporation cost (incoming and outgoing)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{AVG_GROUND_TRAVEL_COST | number}}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div fxFlex="50" fxLayout="column" class="white-bg">
                                    <table class="mat-table white-bg pr-10">
                                        <tr class="title-row">
                                            <td class="mat-cell pt-10 pb-10">
                                                <b>Other</b>
                                            </td>
                                        </tr>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Other cost per trainer (phone credit, etc)</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{OTHER_COST_PER_TRAINER | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>meeting material printing cost/per person</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{MEETING_MATERIAL_PRINTING | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="title-row">
                                            <td class="mat-cell pt-10 pb-10">
                                                <b>Venue related cost</b>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Refreshment/per person/per day</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{REFRESMENT_PER_PERSON_PER_DAY | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Venue cost/per day</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{VENUE_COST_PER_DAY | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <span>Stationery cost/person</span>
                                            </td>
                                            <td class="mat-cell">
                                                <span>{{STATIONARY_COST_PER_PERSON | number}}</span>
                                            </td>
                                        </tr>
                                        <mat-divider class="mat-table-divider"></mat-divider>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </mat-step>
    </mat-horizontal-stepper>
    <div style="display: flex; flex-direction: row-reverse; padding: 10px;">
        <button (click)="SaveForm()" class="primary-color button-margin-left Primary white-color" mat-raised-button><mat-icon> save</mat-icon> Save</button>
    </div>
</mat-card>







































<!-- <mat-card class="form-container-card no-padding" *ngIf="!loading">

        <div class="header primary-color">
            <h4 class="title-text" *ngIf="!editMode">EVENT REQUEST FORM</h4>
            <h4 class="title-text" *ngIf="editMode">EVENT REQUEST EDIT FORM</h4>
        </div>

        <div>

            <form #form="ngForm" [formGroup]="eventRequestForm" class="form-body">
                <div fxLayout="column" fxLayoutAlign="center stretch">
                    <div fxLayout="column">
                        <h4 class="section-label">Basic Information</h4>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Event Name" type="text" name="eventName" formControlName="eventName"
                                    required> -->

<!-- <mat-error *ngIf="checkError('eventName', 'required')">Event title is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="eventType" placeholder="Event Type" required [disabled]="editMode">
                                    <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.id">
                                        {{eventType.data.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('eventType', 'required')">Event Type is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <textarea matInput formControlName="eventSummary" cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="2" placeholder="Type your summary here..." required></textarea>
                                <input matInput type="textarea" name="eventSummary">
                            </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <textarea matInput formControlName="eventDescription" cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="2" placeholder="Type your description here..." required></textarea>
                                <input matInput type="textarea" name="eventDescription">
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <mat-label>Start Date</mat-label>
                                <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                    placeholder="dd/mm/yyyy" required [min]="minStartDate" (dateChange)="startDateChanged($event)">
                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startPicker></mat-datepicker> -->
<!-- <mat-error *ngIf="checkError('startDate', 'required')">Start date is required
                                </mat-error> -->
<!-- </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <mat-label>End Date</mat-label>
                                <input formControlName="endDate" matInput [matDatepicker]="endPicker"
                                    placeholder="dd/mm/yyyy" required [min]="minEndDate" [disabled]=disableEndDate>
                                <mat-datepicker-toggle matSuffix [for]="endPicker" [disabled]=disableEndDate></mat-datepicker-toggle>
                                <mat-datepicker #endPicker [disabled]=disableEndDate></mat-datepicker> -->
<!-- <mat-error *ngIf="checkError('endDate', 'required')">Start date is required
                                </mat-error> -->
<!-- </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="hostInstitution" placeholder="Host Institution" required [disabled]="editMode">
                                    <mat-option *ngFor="let hostInstitution of hostInstitutions" [value]="hostInstitution.id">
                                        {{hostInstitution.data.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('hostInstitution', 'required')">Host Institution is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                            <div fxFlex="35"></div>
                        </div>
                    </div>
                    <div fxLayout="column">
                        <h4 class="section-label">Project Information</h4>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="project" placeholder="Project" required [disabled]="editMode">
                                    <mat-option *ngFor="let project of projects" [value]="project.id">
                                        {{project.data.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('project', 'required')">Project is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="workPlanActivity" placeholder="Work Plan Activity" required [disabled]="editMode">
                                    <mat-option *ngFor="let activity of workPlanActivities" [value]="activity.id">
                                        {{activity.data.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('workPlanActivity', 'required')">Event Type is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>

                        </div>

                    </div>
                    <div fxLayout="column">
                        <h4 class="section-label">POC Information</h4>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="eventRequester" placeholder="Requester" required [disabled]="editMode">
                                    <mat-option *ngFor="let requester of testLookups" [value]="requester.id">
                                        {{requester.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('eventRequester', 'required')">Requester is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="eventApprover" placeholder="Approver" required [disabled]="editMode">
                                    <mat-option *ngFor="let approver of testLookups" [value]="approver.id">
                                        {{approver.name}}
                                    </mat-option>
                                </mat-select> -->
<!-- <mat-error *ngIf="checkError('eventApprover', 'required')">Approver is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>

                        </div>

                    </div> -->
<!-- <div fxLayout="column">
                        <h4 class="section-label">Financial Details</h4>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Max number of Trainers" type="number" min=0 name="maximumNoTrainers"
                                    formControlName="maximumNoTrainers" required> -->
<!-- <mat-error *ngIf="checkError('maximumNoTrainers', 'required')">Maximum number of trainers is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Max number of Trainees" type="number" min=0 name="maximumNoTrainees"
                                    formControlName="maximumNoTrainees" required> -->
<!-- <mat-error *ngIf="checkError('maximumNoTrainees', 'required')">Maximum number of trainees is
                                    required
                                </mat-error> -->
<!-- </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around" style="margin-top: 20px;">
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Miscellaneous Cost" type="number" min=0 name="miscellaneousCost"
                                    formControlName="miscellaneousCost" required> -->
<!-- <mat-error *ngIf="checkError('miscellaneousCost', 'required')">Miscellaneous Cost required
                                </mat-error> -->
<!-- </mat-form-field>

                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Per diem" type="number" min=0 name="perDiem"
                                    formControlName="perDiem" disabled>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="35">
                                <input matInput placeholder="Transportation Allowance" type="number" min=0
                                    name="transportationAllowance" formControlName="transportationAllowance" disabled>

                            </mat-form-field>
                            
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="cityId" placeholder="City" required [disabled]="editMode">
                                    <mat-option *ngFor="let city of testLookups" [value]="city.id">
                                        {{city.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-around" *ngIf="editMode">
                            <mat-form-field fxFlex="35">
                                <mat-select formControlName="venueId" placeholder="Venue" required>
                                    <mat-option *ngFor="let venue of testLookups" [value]="venue.id">
                                        {{venue.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div fxFlex='35'></div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <mat-form-field fxFlex="25">
                                <mat-slide-toggle formControlName="breakfastProvided" [disabled]="editMode">
                                    Breakfast Provided</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <mat-slide-toggle formControlName="lunchProvided" [disabled]="editMode">Lunch
                                    Provided</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <mat-slide-toggle formControlName="dinnerProvided" [disabled]="editMode">Dinner
                                    Provided</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <mat-slide-toggle formControlName="refreshmentProvided" [disabled]="editMode">Refreshment Provided</mat-slide-toggle>
                                <textarea matInput hidden></textarea>
                            </mat-form-field>

                        </div>

                    </div>

                    <div formArrayName="poc">
                        <h3>Poc</h3> <button (click)="addPoc()">Add POC</button>

                        <div *ngFor="let p of poc.controls; let i=index">
                          <label>
                            Alias:
                            <input type="text" [formControlName]="i">
                          </label>
                        </div>
                    </div>

                    <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" style="margin-top: 10px;">
                        <button *ngIf="!editMode" class="mat-raised-button Default" (click)="saveEventRequest()" [disabled]="eventRequestForm.invalid">Submit</button>
                        <button *ngIf="editMode" class="mat-raised-button Default" (click)="updateEventRequest()" [disabled]="eventRequestForm.invalid">Update</button>
                    </div>
                </div>


            </form>
        </div>
    </mat-card> -->