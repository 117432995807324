<div class="relative">
    <div class="form-bg">
        <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
            <div style="margin-top: 7%">
                <mat-card class="login-session">
                    <div style="text-align: center">
                        <img style="height: 160px; width: 160px" alt="dEventsLogo" src="assets/logo/app_logo.png">
                        <h4 style="margin-top: 0px">dEvents</h4>
                        <h4 style="margin-top: 0px">Event Management</h4>
<br/>
                        <br/>
                        <br/>
                        <p>Press the button below to sign-in using your JSI Email</p>
                        <div class="google-btn" (click)="login()" style="text-align: initial; margin-left: 50px">
                            <div class="google-icon-wrapper">
                                <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                            </div>
                            <p class="btn-text"><b>Sign in with Google</b></p>
                        </div>                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>