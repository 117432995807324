export const SUPPORTIVE_SUPERVISION_CODE = 'SS';
export const LODGING = 'LDG';
export const MIE = 'MIE';
export const JSI_EMAIL = 'JEML';
export const TELEGRAM = 'TLGM';
export const PERSONAL_EMAIL = 'PEML';
export const JOB_TITLE = 'JobTitle';
export const CHARGE_CODE = 'ChargeCode';
export const ACCOMMODATION_TYPE = 'AccommodationType';
export const PURPOSE = 'Purpose';
export const REQUEST_TYPE = 'RequestType';
export const PRODUCT = 'Product';
export const SERVICE = 'Service';
export const MATERIAL = 'Material';
export const BASE_LOCATION = 'BL';
export const VISIT_SITE = 'VS';
export const REGION = 'RG';
export const SITE = 'ST';
export const SUPERVISOR = 'Supervisor';
export const FINANCE = 'Finance';
export const MEALTYPE = 'MealType';
export const EVENT_APPROVE = 'Approve';
export const EVENT_REMARK = 'Remark';
export const EVENT_REASON = 'Reason';

export const EVENT_STATUSES = {
    REQUESTED: 'Requested',
    APPROVED: 'Request-Approved',
    REJECTED: 'Request-Rejected',
}

export const EVENT_WORKFLOW_BUTTONS = [
    {
        name: 'Approve',
        styleClass: 'Primary'
    }, {
        name: 'Reject',
        styleClass: 'Warn'
    }
]
