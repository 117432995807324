import { Injectable } from '@angular/core';
import {LookupModel, ResponseModel} from '../models/Common';
import {UserResponseModel, Location} from '../models/User';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountService} from './account.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  public locationURL = environment.baseUrl + '/Location';
  public lookupURL = environment.baseUrl + '/Lookup';
  public lookupsApiUrl = environment.testUrl + '/api';

  constructor(private http: HttpClient, private accountService: AccountService) {
  }

  getLocationsWithType(locationTypeCode) {
    return this.http.get<ResponseModel<Location[]>>(this.locationURL + '/' + locationTypeCode, httpOptions);
  }
  getLookups(lookupType) {
    return this.http.get<ResponseModel<LookupModel[]>>(this.lookupURL + '/' + lookupType, httpOptions);
  }
  createLookup(lookupType, lookupModel) {
    return this.http.post<ResponseModel<LookupModel[]>>(this.lookupURL + '/' + lookupType, lookupModel, httpOptions);
  }
  getLookupTypes() {
    return this.http.get<ResponseModel<LookupModel[]>>(this.lookupURL + '/lookupTypes', httpOptions);
  }
  getVenue(cityId) {
    return this.http.get<ResponseModel<any>>(this.lookupURL + '/' + cityId, httpOptions);
  }
  getProjects() {
    return this.http.get<any>(this.lookupsApiUrl + '/project', httpOptions);
  }
  getEventTypes() {
    return this.http.get<any>(this.lookupsApiUrl + '/event-type', httpOptions);
  }
  getInstitutions() {
    return this.http.get<any>(this.lookupsApiUrl + '/institution', httpOptions);
  }
  getWorkPlanActivities() {
    return this.http.get<any>(this.lookupsApiUrl + '/wp-activity', httpOptions);
  }
  getCity() {
    return this.http.get<any>(this.lookupsApiUrl + '/city', httpOptions);
  }
  getPocType() {
    return this.http.get<any>(this.lookupsApiUrl + '/poc-type', httpOptions);
  }
}
