<mat-sidenav-container [hasBackdrop]="false">

    <div class="relative">
        <mat-sidenav [opened]="showComment" mode="push" position="end">
            <div class="comment-card" *ngFor="let comment of comments">
                <div style="display: inline-flex">
                    <div style="margin: 12px" class="circle accent-color">
                        <span class="initials">{{getUserInitial(comment.commenterName)}}</span>
                    </div>
                    <div style="margin-top: 12px">
                        <label><b>{{comment.commenterName}}</b></label>
                        <br/>
                        <label>{{formatDateTime(comment.date)}}</label>
                    </div>
                </div>
                <div style="margin: 12px; margin-top: 3px">
                    <span style="font-style: italic">Reasons for {{comment.action}}ing: &nbsp;</span>{{comment.comment}}
                </div>
                <mat-divider style="margin-bottom: 18px"></mat-divider>
            </div>
            <div style="width: 250px; padding: 30px" *ngIf="comments.length === 0">No comments for this request.</div>
        </mat-sidenav>
        <div fxLayout="column" fxFill fxLayoutAlign="start stretch" class="pt-2" style="padding-left: 150px; padding-right: 150px;">
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
                <mat-card class="form-container-card no-padding" *ngIf="!loading">
    
                    <div class="header primary-color">
                        <h4 class="title-text">EXPENSE FORM</h4>
                        <button class="print-button" style="right: 40px;" (click)="showComment = !showComment"
                        >
                            <mat-icon [matBadge]="comments.length" matBadgeColor="accent">comment</mat-icon>
                        </button>
                    </div>
    
                    <div>
                        <mat-tab-group>
                        <mat-tab label="Expense Report">

                            <form #form="ngForm" [formGroup]="expenseForm" class="form-body">
                            <div fxLayout="column" fxLayoutAlign="center space-between">
                                <div fxLayout="column">
                                    <h4 class="section-label">Travel Information</h4>

                                    <table class="mat-table" style="background-color: whitesmoke !important;" id="header-table">
                                        <tr class="mat-row">
                                            <td class="mat-cell" style="width:200px">
                                                <b>Name of Traveler</b>
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">
                                                {{requesterFullName}}
                                            </td>
            
                                            <td class="mat-cell" style="width:200px"> <b>Dates of Travel</b>
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">{{getTravelDateRange()}}
                                            </td>
                                        </tr>
            
                                        <tr class="mat-row">
                                            <td class="mat-cell" style="width:200px">
                                                <b>Base of Traveler</b>
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">
                                                {{requesterBaseLocation}}
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">
                                                <b>Budget Activity Number</b>
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">
                                                {{getAllBudgetActivityNumbers()}}
                                            </td>

                                        </tr>
                                        <tr class="mat-row">
                                            <td class="mat-cell" style="width:200px">
                                                <b>Position of Traveler</b>
                                            </td>
            
                                            <td class="mat-cell" style="width:200px">
                                                {{requesterJobTitle}}
                                            </td>
                                                        
                                            <td class="mat-cell" style="width:200px">
                                                <b>Signature of Traveler</b>
                                            </td>
                                            
                                            <td class="mat-cell">
                                                <div>
                                                    <label class="signature-text">{{requesterFullName}}</label>
                                                </div>
                                            </td>
                                        </tr>
            
                                    <tr class="mat-row">
                                        <td class="mat-cell" style="width:200px">
                                            <b>Purpose of Travel</b>
                                        </td>
                                        <td class="mat-cell">
                                            {{advanceRequest.description}}
                                        </td>
                                        
                                    </tr>
            
                                </table>

                                </div>

                                <div fxLayout="column">
                                        <h4 class="section-label">Meals and Incidental Expenses (M & IE)</h4>
                                        <table id="mieTable" mat-table formArrayName="mieRequestAccommodations" [dataSource]="mieDataSource"
                                               class="mat-elevation-z2">
    
                                            <ng-container matColumnDef="startDate">
                                                <th mat-header-cell *matHeaderCellDef>Arrival Date</th>
                                                <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                    
                                                    <mat-form-field>
                                                        <mat-label>Arrival Date</mat-label>
                                                        <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                                            placeholder="dd/mm/yyyy" required [min]="minDate">
                                                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #startPicker></mat-datepicker>
                                                        <mat-error *ngIf="checkMIEError(index, 'startDate', 'required')">Start date is required
                                                        </mat-error>
                                                    </mat-form-field>

                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="endDate">
                                                <th mat-header-cell *matHeaderCellDef>Departure Date</th>
                                                <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                    
                                                    <mat-form-field>
                                                        <mat-label>Departure Date</mat-label>
                                                        <input formControlName="endDate" matInput [matDatepicker]="endPicker"
                                                            placeholder="dd/mm/yyyy" required [min]="minDate">
                                                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #endPicker></mat-datepicker>
                                                        <mat-error *ngIf="checkMIEError(index, 'endDate', 'required')">End Date is required
                                                        </mat-error>
                                                    </mat-form-field>

                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>

                                            <ng-container matColumnDef="city">
                                                <th mat-header-cell *matHeaderCellDef> City</th>
                                                <td mat-cell *matCellDef="let element; let index = index"
                                                    [formGroupName]="index">
                                                    <mat-form-field class="medium-width-input">
                                                        <input type="text"
                                                               formControlName="location" placeholder="Select" required
                                                               matInput
                                                               [matAutocomplete]="auto">
                                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                            <mat-option
                                                                    *ngFor="let site of mieObservableLodgingLocations | async"
                                                                    [value]="site">
                                                                {{site.name}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="checkMIEError(index, 'location', 'required')">City is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>
                                            <ng-container matColumnDef="type" style="width: 150px; margin-right: 0px; margin-left: 0px;">
                                                <th mat-header-cell *matHeaderCellDef> Type</th>
                                                <td mat-cell *matCellDef="let element; let index = index">
                                                    <span>{{getLocationTypeForMIEExpense(index)}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>
                                            <ng-container matColumnDef="MIEPerDay">
                                                <th mat-header-cell *matHeaderCellDef> M & IE Per Day</th>
                                                <td mat-cell *matCellDef="let element; let index = index">
                                                    <span>{{getMIEAllowanceForElement(index)}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="numberOfDays">
                                                <th mat-header-cell *matHeaderCellDef> No. of Days</th>
                                                <td mat-cell *matCellDef="let element; let index = index"
                                                    [formGroupName]="index">
                                                    <mat-form-field class="medium-width-input">
                                                        <input formControlName="numberOfDays" placeholder="No. of Days" matInput
                                                               type="number" min="0" required name="numberOfDays" required (change)="mieNumberOfDaysChanged()">
                                                        <mat-error *ngIf="checkMIEError(index, 'numberOfDays', 'required')">Number of days is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef><b>Sub Total M & IE</b></td>
                                            </ng-container>
                                            <ng-container matColumnDef="amount">
                                                <th mat-header-cell *matHeaderCellDef> Amount</th>
                                                <td mat-cell *matCellDef="let element; let index = index">
                                                    <span>{{calculateMIEAmount(index)}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <b>{{calculateSubTotalMIEExpense()}}  &nbsp; Birr</b>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="actions">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element; let index = index">

                                                    <button *ngIf="!mieInputDisabled(index)" style="float: right;" mat-icon-button
                                                            class="ml-xs overflow-visible"
                                                            (click)="removeRow('MIE', index)"><mat-icon style="color: red; cursor: pointer;">remove_circle
                                                    </mat-icon></button>
    
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <button style="float: right;" mat-icon-button class="ml-xs overflow-visible" (click)="addNewRequestAccommodations('MIE')">
                                                    <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="MIEExpenseDisplayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: MIEExpenseDisplayedColumns;"></tr>
                                            <tr mat-footer-row *matFooterRowDef="MIEExpenseDisplayedColumns"></tr>
    
                                        </table>
                                </div>

                                <div fxLayout="column">
                                    <h4 class="section-label">Meals Provided to Participants:</h4>
                                    <table formArrayName="requestMeal" style="background-color: white;" class="mat-elevation-z2">
   
                                        <tr class="mat-row">
                                            <td class="mat-cell"><b>Meal</b></td>
                                            <td class="mat-cell"><b>Meal Type</b></td>
                                            <td class="mat-cell"><b>No. of Days</b></td>
                                            <td class="mat-cell"><b>Amount</b></td>
                                            <td class="mat-cell"><b>Sub Total</b></td>
                                        </tr>

                                        <tr class="mat-row" [formGroupName]="0">
                                            <td class="mat-cell">
                                                <b>Breakfast</b>
                                            </td>

                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <mat-select placeholder = "Meal Type" 
                                                        formControlName="requestMealType" name = "requestMealType">
                                                        <mat-option *ngFor = "let mealType of mealTypeEndsWith('Breakfast')" 
                                                            [value] = "mealType">
                                                            {{mealType.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="checkMealsError(0, 'requestMealType', 'required')">Meal Type is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfDays" placeholder="No. of Days" matInput type="number"
                                                           min="0" required name="numberOfDays" required>
                                                    <mat-error *ngIf="checkMealsError(0, 'numberOfDays', 'required')">No. of Days is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <span>{{ getMealsProvidedAmount(0) }}</span>
                                            </td>
                                            
                                            <td class="mat-cell">
                                                {{ calculateMealsProvided(0) }}
                                            </td>
                                        </tr>

                                        <tr class="mat-row" [formGroupName]="1">
                                            <td class="mat-cell">
                                                <b>Lunch</b>
                                            </td>

                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <mat-select placeholder = "Meal Type" 
                                                        formControlName="requestMealType" name = "requestMealType">
                                                        <mat-option *ngFor = "let mealType of mealTypeEndsWith('Lunch')" 
                                                            [value] = "mealType">
                                                            {{mealType.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="checkMealsError(1, 'requestMealType', 'required')">Meal Type is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfDays" placeholder="No. of Days" matInput type="number"
                                                           min="0" required name="numberOfDays" required>
                                                    <mat-error *ngIf="checkMealsError(1, 'numberOfDays', 'required')">No. of Days is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>

                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <span>{{ getMealsProvidedAmount(1) }}</span>
                                            </td>
                                            
                                            <td class="mat-cell">{{ calculateMealsProvided(1) }}</td>
                                        </tr>

                                        <tr class="mat-row" [formGroupName]="2">
                                            <td class="mat-cell">
                                                <b>Dinner</b>
                                            </td>

                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <mat-select placeholder = "Meal Type" 
                                                        formControlName="requestMealType" name = "requestMealType">
                                                        <mat-option *ngFor = "let mealType of mealTypeEndsWith('Dinner')" 
                                                            [value] = "mealType">
                                                            {{mealType.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="checkMealsError(2, 'requestMealType', 'required')">Meal Type is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfDays" placeholder="No. of Days" matInput type="number"
                                                           min="0" required name="numberOfDays" required>
                                                    <mat-error *ngIf="checkMealsError(2, 'numberOfDays', 'required')">No. of Days is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <span>{{ getMealsProvidedAmount(2) }}</span>
                                            </td>
                                            
                                            <td class="mat-cell">{{ calculateMealsProvided(2) }} Birr</td>
                                        </tr>

                                        <tr class="mat-row">
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"><b>Total Meal Deduction</b></td>
                                            <td class="mat-cell">- {{ calculateTotalMealsProvided() }} Birr</td>
                                        </tr>
                                                  
                                        <tr class="mat-row">
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"></td>
                                            <td class="mat-cell"><b>Total M & IE</b></td>
                                            <td class="mat-cell">{{ calculateTotalMIEExpense() }} Birr</td>
                                        </tr>

                                    </table>
                                </div>

                                <div fxLayout="column" style="border-color: white;" fxLayoutAlign="start space-between">
                                    <h4 class="section-label">Lodging Expenses</h4>
                                    <table id="lodgingTable" mat-table formArrayName="leRequestAccommodations" [dataSource]="leDataSource"
                                           class="mat-elevation-z2">
                                           
                                        <ng-container matColumnDef="startDate">
                                            <th mat-header-cell *matHeaderCellDef>From</th>
                                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                
                                                <mat-form-field>
                                                    <mat-label>From</mat-label>
                                                    <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                                        placeholder="dd/mm/yyyy" required [min]="minDate">
                                                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startPicker></mat-datepicker>
                                                    <mat-error *ngIf="checkLEError(index, 'startDate', 'required')">Start Date is required
                                                    </mat-error>
                                                </mat-form-field>

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="endDate">
                                            <th mat-header-cell *matHeaderCellDef>To</th>
                                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                
                                                <mat-form-field>
                                                    <mat-label>To</mat-label>
                                                    <input formControlName="endDate" matInput [matDatepicker]="endPicker"
                                                        placeholder="dd/mm/yyyy" required [min]="minDate">
                                                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #endPicker></mat-datepicker>
                                                    <mat-error *ngIf="checkLEError(index, 'endDate', 'required')">End Date is required
                                                    </mat-error>
                                                </mat-form-field>

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>

                                        <ng-container matColumnDef="hotelName">
                                            <th mat-header-cell *matHeaderCellDef>Hotel Name</th>
                                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="locationName" placeholder="" matInput
                                                           type="text" required name="locationName" required>
                                                    <mat-error *ngIf="checkLEError(index, 'locationName', 'required')">Hotel Name is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        <ng-container matColumnDef="lodgingPerDay">
                                            <th mat-header-cell *matHeaderCellDef> Cost Per Night</th>
                                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="cost" placeholder="Cost" matInput
                                                           min="0" type="number" required name="cost" required>
                                                    <mat-error *ngIf="checkLEError(index, 'cost', 'required')">Cost Per night is required
                                                    </mat-error>
                                                </mat-form-field>
                                            <td mat-footer-cell *matFooterCellDef><b>Total Lodging Expenses</b></td>
                                        </ng-container>
                                        <ng-container matColumnDef="numberOfNights">
                                            <th mat-header-cell *matHeaderCellDef> No. Nights</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfNights" placeholder="No. of Nights" matInput
                                                           type="number" required name="numberOfNights" required (change)="leNumberOfDaysChanged()">
                                                    <mat-error *ngIf="checkLEError(index, 'numberOfNights', 'required')">Number of nights is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="amount">
                                            <th mat-header-cell *matHeaderCellDef> Amount</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{calculateLodgingAmount(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="attachments">
                                            <th mat-header-cell *matHeaderCellDef>Attachments</th>
                                            <td mat-cell *matCellDef="let element; let index = index">

                                                <mat-card class="file-input-container">
                                                    <button fxLayout="row" fxLayoutAlign="center center" #file mat-flat-button color="secondary">
                                                        <mat-icon>attach_file</mat-icon>
                                                        {{ fileLabelOf(index, 'LE') }}
                                                        <input type="file" class="file-input" 
                                                            (change)="lodgingAttachmentFileChanged(index, $event.target.files)">
                                                    </button>
                                                </mat-card>
                                                
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <b>{{calculateTotalLodgingExpense()}} &nbsp; Birr</b>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="removeRow('lodging', index)"><mat-icon style="color: red; cursor: pointer;"
                                                >remove_circle
                                                </mat-icon></button>

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="addNewRequestAccommodations('LDG')">
                                                    <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="lodgingExpenseDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: lodgingExpenseDisplayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="lodgingExpenseDisplayedColumns"></tr>

                                    </table>
                                </div>

                                <div fxLayout="column">
                                        <h4 class="section-label">Other Expenses</h4>
                                        <table id="oeTable" mat-table formArrayName="requestExpenses" [dataSource]="oeDataSource"
                                               class="mat-elevation-z2">
                                            
                                               <ng-container matColumnDef="startDate">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index">
                                                    
                                                    <mat-form-field>
                                                        <mat-label>Date</mat-label>
                                                        <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                                            placeholder="dd/mm/yyyy" required [min]="minDate">
                                                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #startPicker></mat-datepicker>
                                                        <mat-error *ngIf="checkLEError(index, 'startDate', 'required')">Start Date is required
                                                        </mat-error>
                                                    </mat-form-field>

                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>

                                            <ng-container matColumnDef="purpose">
                                                <th mat-header-cell *matHeaderCellDef> Description</th>
                                                <td mat-cell *matCellDef="let element; let index = index"
                                                    [formGroupName]="index">
                                                    <mat-select formControlName="purposeId" placeholder="Purpose"
                                                              >
                                                        <mat-option *ngFor="let purpose of otherExpensePurposeList" [value]="purpose.id">
                                                            {{purpose.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="checkREError(index, 'purposeId', 'required')">Expense purpose is
                                                        required
                                                    </mat-error>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef><b>Total Other Expenses </b></td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="otherAmount">
                                                <th mat-header-cell *matHeaderCellDef>Amount</th>
                                                <td mat-cell *matCellDef="let element; let index = index"
                                                    [formGroupName]="index">
                                                    <mat-form-field class="medium-width-input">
                                                        <input formControlName="cost" placeholder="Amount" min="0" matInput
                                                               type="number" required name="cost">
                                                        <mat-error *ngIf="checkREError(index, 'cost', 'required')">Amount is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <b>{{calculateTotalOtherExpense()}} &nbsp; Birr</b>
    
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="accountCode">
                                                <th mat-header-cell *matHeaderCellDef>Account Code</th>
                                                <td mat-cell *matCellDef="let element; let index = index">
                                                    ----
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="attachments">
                                                <th mat-header-cell *matHeaderCellDef>Attachments</th>
                                                <td mat-cell *matCellDef="let element; let index = index">

                                                    <mat-card class="file-input-container">
                                                        <button fxLayout="row" fxLayoutAlign="center center" #file mat-flat-button color="secondary">
                                                            <mat-icon>attach_file</mat-icon>
                                                            {{ fileLabelOf(index, 'OE') }}
                                                            <input type="file" class="file-input" 
                                                                (change)="otherExpenseAttachmentFileChanged(index, $event.target.files)">
                                                        </button>
                                                    </mat-card>
                                                    
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="actions">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element; let index = index">
                                                    <button style="float: right;" mat-icon-button
                                                            class="ml-xs overflow-visible"
                                                            (click)="removeRow('otherExpenses', index)">
                                                        <mat-icon style="color: red; cursor: pointer;"
                                                        >remove_circle
                                                        </mat-icon>
                                                    </button>
    
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef><button style="float: right;" mat-icon-button
                                                                                              class="ml-xs overflow-visible"
    
                                                                                              (click)="addNewRequestExpense()">
                                                    <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                                </button></td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="otherExpenseDisplayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: otherExpenseDisplayedColumns;"></tr>
                                            <tr mat-footer-row *matFooterRowDef="otherExpenseDisplayedColumns"></tr>
    
                                        </table>
                                </div>
                                
                                <div fxLayout="column">
                                    <h4 class="section-label">Total Expense Calculation:</h4>
                                    <table style="background-color: white;" class="mat-table" class="mat-elevation-z2">
    
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <b>M & IE:</b>
                                            </td>
    
                                            <td class="mat-cell" style="padding-right: 10px;">
                                                <b>{{calculateTotalMIEExpense()}} Birr</b>
                                            </td>
                                            
                                            <td class="mat-cell" style="padding-left: 10px">
                                                <b>Total Expenses:</b>
                                            </td>
    
                                            <td class="mat-cell">
                                                <b>{{calculateTotalExpense()}} Birr</b>
                                            </td>
                                        </tr>
                                        
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <b>Lodging:</b>
                                            </td>
    
                                            <td class="mat-cell">
                                                <b>{{calculateTotalLodgingExpense()}} Birr</b>
                                            </td>

                                            <td class="mat-cell" style="padding-left: 10px">
                                                <b>Advance V#:</b>
                                            </td>
    
                                            <td class="mat-cell">
                                                <span>{{ previouslyRequestedAdvance }} Birr</span>
                                            </td>
                                        </tr>
                                        
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <b>Other Expense:</b>
                                            </td>
    
                                            <td class="mat-cell">
                                                <b>{{calculateTotalOtherExpense()}} Birr</b>
                                            </td>

                                            <td rowspan="2" class="mat-cell" style="padding-left: 10px">
                                                <b>Amount Owed (Returned):</b>
                                            </td>
    
                                            <td rowspan="2" class="mat-cell">
                                                <b>{{ calculateAmountOwed() }} Birr</b>
                                            </td>
                                        </tr>
                                        
                                        <tr class="mat-row">
                                            <td class="mat-cell">
                                                <b>Total Expense:</b>
                                            </td>
    
                                            <td class="mat-cell">
                                                <b>{{calculateTotalExpense()}} Birr</b>
                                            </td>
                                        </tr>
                                        
                                    </table>
                                </div>
    
                                <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" style="margin-top: 10px;">
                                    <button type="button" *ngFor="let button of actionButtons"
                                            (click)="advanceFormAction(button)"
                                            [disabled]="expenseForm.invalid && checkValidationRequired(button)"
                                            [ngClass]="(button.options && button.options.style) ? button.options.style : 'Default'"
                                            class="button-margin-left" mat-raised-button>{{button.name}}</button>
                                </div>
                            </div>
                        </form>
                        
                        </mat-tab>
                        <mat-tab label="Trip Report">
                            
                            Trip Report 

                        </mat-tab>
                      </mat-tab-group>

                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    </mat-sidenav-container>