<div style="background-color: whitesmoke !important; padding: 15px;">
    <div class="inset-container" fxLayout="column">
        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
            <div fxFlex="50" fxLayout="column">
                <b class="section-label" style="display: block !important;">Total Non-DHA:
                    Coordinators/Trainers cost </b>
                <table style="background-color: white !important;">
                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Per diem - Trainer</span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTrainerCost.perDiemFlat | number}}</span>
                        </td>

                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Hotel for Trainer</span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTrainerCost.perDiemHotel | number}}</span>
                        </td>
                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>

                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Transport </span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTrainerCost.transport | number}}</span>
                        </td>
                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <tr class="title-row">
                        <td class="mat-cell total-label">
                            <b>Total</b>
                        </td>
                        <td class="mat-cell total-label">
                            <b>{{totalTrainerCost.total | number}}</b>
                        </td>
                    </tr>
                </table>
            </div>
            <div fxFlex="50" fxLayout="column">
                <b class="section-label" style="display: block !important;">Total Trainee
                    Cost</b>
                <table class="mat-table pr-10" style="background-color: white !important;">
                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Per diem - Trainee</span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTraineeCost.perDiemFlat | number}}</span>
                        </td>
                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Hotel for Trainee</span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTraineeCost.perDiemHotel | number}}</span>
                        </td>
                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <tr class="mat-row">
                        <td class="mat-cell">
                            <span>Transport </span>
                        </td>
                        <td class="mat-cell">
                            <span>{{totalTraineeCost.transport | number}}</span>
                        </td>
                    </tr>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <tr class="title-row">
                        <td class="mat-cell total-label">
                            <b>Total</b>
                        </td>
                        <td class="mat-cell total-label">
                            <b>{{totalTraineeCost.total | number}}</b>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- <div fxFlex fxLayout="column">
            <b class="section-label" style="display: block !important;">Venue/Other</b>
            <table class="pr-10" style="background-color: white !important;">
                <tr class="mat-row">
                    <td class="mat-cell" style="width: 95%;">
                        <span>Venue</span>
                    </td>
                    <td class="mat-cell">
                        <span>{{totalOtherCost.venue | number}}</span>
                    </td>

                </tr>
                <mat-divider class="mat-table-divider"></mat-divider>
                <tr class="mat-row">
                    <td class="mat-cell">
                        <span>Stationary</span>
                    </td>
                    <td class="mat-cell">
                        <span>{{totalOtherCost.stationary | number}}</span>
                    </td>
                </tr>
                <mat-divider class="mat-table-divider"></mat-divider>

                <tr class="mat-row">
                    <td class="mat-cell">
                        <span>Refreshment</span>
                    </td>
                    <td class="mat-cell">
                        <span>{{totalOtherCost.refreshment | number}}</span>
                    </td>
                </tr>
                <mat-divider class="mat-table-divider"></mat-divider>
                <mat-divider class="mat-table-divider"></mat-divider>
                <tr class="title-row">
                    <td class="mat-cell total-label" style="width: 86%;">
                        <span>Total</span>
                    </td>
                    <td class="mat-cell inline">
                        <b>{{totalOtherCost.total | number}}</b>
                    </td>
                </tr>
            </table>
        </div>
        <table class="mat-table final-total"
            style="background-color: white !important; padding-bottom: 10px; padding-top: 10px;">
            <tr class="mat-row">
                <td class="mat-cell total-label final-total-labels">
                    <b>Total Cost</b>
                </td>
                <td class="mat-cell total-label final-total-labels reverse-flex">
                    <b>{{totalOtherCost.total + totalTraineeCost.total + totalTrainerCost.total | number}}</b>
                </td>
            </tr>
        </table> -->
    </div>
</div>
