import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-mat-spinner-overlay',
  templateUrl: './mat-spinner-overlay.component.html',
  styleUrls: ['./mat-spinner-overlay.component.css']
})
export class MatSpinnerOverlayComponent implements OnInit {
  @Input() value = 100;
  @Input() diameter = 80;
  @Input() mode  = 'indeterminate';
  @Input() strokeWidth = 6;
  @Input() overlay = true;
  @Input() color = 'accent';
  loading: boolean;
  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      // console.log(v);
      this.loading = v;
    });

  }
  ngOnInit(): void {
  }

}
