<mat-card class="item-card" (click)="advanceRequestItemClicked()">
    <div style="margin-top: 11px">
        <i mat-card-avatar class="medium-avatar" [ngClass]="advanceRequest.status.options.style">
            {{getStatusCode()}}
        </i>
    </div>
    <div style="margin-left: 15px">
        <label style="display: block"><b>Purpose of Travel:</b>&nbsp; {{advanceRequest.description}}</label>
        <label style="display: block"><b>Requested By:</b>&nbsp; {{getRequesterName(advanceRequest.requester)}}</label>
        <label style="display: block"><b>Request Type:</b>&nbsp;{{advanceRequest.requestType.name}}</label>
        <label style="display: block"><b>Status:</b>&nbsp;{{advanceRequest.status.name}}</label>
        <label style="display: block"><b>{{getDateRange()}}</b></label>
    </div>
    <div class="cursor-pointer top-right" *ngIf="advanceRequest.status.isEditable">
        <mat-icon (click)="navigateToAdvanceRequestEdit($event)">edit</mat-icon>
    </div>
    <div class="bottom-right">
        <label><b>{{getTimeAgo()}}</b></label>
    </div>
</mat-card>