import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import {BlankComponent} from './blank-component/blank.component';
import {BudgetSummaryComponent} from './budget-summary/budget-summary.component';
import {RouterModule} from '@angular/router';
import {AppRoutes} from '../app.routing';
import {FormsModule} from '@angular/forms';
import { CommentBoxDialogComponent } from './comment-box-dialog/comment-box-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';

// Material Modules
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBadgeModule} from '@angular/material/badge';
import { DemoMaterialModule } from './demo.module';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    BlankComponent,
    CommentBoxDialogComponent,
    BudgetSummaryComponent
  ],
  imports: [
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      FormsModule,
      CommonModule,
      MatSidenavModule,
      MatInputModule,
      MatIconModule,
      MatListModule,
      MatMenuModule,
      MatToolbarModule,
      MatTabsModule,
      MatDialogModule,
      MatCheckboxModule,
      MatProgressBarModule,
      MatBadgeModule,
      MatSelectModule,
      MatCardModule,
      DemoMaterialModule,
      FlexLayoutModule,
    RouterModule.forRoot(AppRoutes, {scrollPositionRestoration: 'enabled'}),
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    MatFormFieldModule,
    BudgetSummaryComponent
   ],
  providers: [ MenuItems, HorizontalMenuItems ]
})
export class SharedModule { }
