import { Component, OnInit } from '@angular/core';
import {EventService} from '../../services/event.service';
import {ActivatedRoute, Router} from '@angular/router';
import { EVENT_APPROVE, EVENT_REASON, EVENT_REMARK, EVENT_STATUSES, EVENT_WORKFLOW_BUTTONS } from '../../models/Constants';
import {CommentBoxDialogComponent} from '../../shared/comment-box-dialog/comment-box-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AlertService} from '../../services/alert.service';
import {OtherCost, TotalCost} from '../../models/Common';
import {
  PER_DIEM_RATE_FLAT_TRAINEE,
  PER_DIEM_RATE_HOTEL_TRAINEE,
  PER_DIEM_RATE_FLAT_TRAINER,
  PER_DIEM_RATE_HOTEL_TRAINER,
  AVG_AIR_TRAVEL_COST,
  AVG_GROUND_TRAVEL_COST,
  OTHER_COST_PER_TRAINER,
  MEETING_MATERIAL_PRINTING,
  REFRESMENT_PER_PERSON_PER_DAY,
  VENUE_COST_PER_DAY,
  STATIONARY_COST_PER_PERSON
} from 'app/constants/constants';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css', '../../advance-request/advance-request-view/advance-request-view.component.css']
})
export class EventViewComponent implements OnInit {
  public selectedEventId;
  public selectedEvent;
  public eventStatuses = EVENT_STATUSES;
  public eventStatus;
  public eventButtons = EVENT_WORKFLOW_BUTTONS;
  public totalTrainerCost = new TotalCost;
  public totalTraineeCost = new TotalCost;
  public totalOtherCost = new OtherCost;

  // constants
  public PER_DIEM_RATE_FLAT_TRAINEE = PER_DIEM_RATE_FLAT_TRAINEE
  public PER_DIEM_RATE_HOTEL_TRAINEE = PER_DIEM_RATE_HOTEL_TRAINEE
  public PER_DIEM_RATE_FLAT_TRAINER = PER_DIEM_RATE_FLAT_TRAINER
  public PER_DIEM_RATE_HOTEL_TRAINER = PER_DIEM_RATE_HOTEL_TRAINER
  public AVG_AIR_TRAVEL_COST = AVG_AIR_TRAVEL_COST
  public AVG_GROUND_TRAVEL_COST = AVG_GROUND_TRAVEL_COST
  public OTHER_COST_PER_TRAINER = OTHER_COST_PER_TRAINER
  public MEETING_MATERIAL_PRINTING = MEETING_MATERIAL_PRINTING
  public REFRESMENT_PER_PERSON_PER_DAY = REFRESMENT_PER_PERSON_PER_DAY
  public VENUE_COST_PER_DAY = VENUE_COST_PER_DAY
  public STATIONARY_COST_PER_PERSON = STATIONARY_COST_PER_PERSON

  constructor(private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.selectedEventId = this.route.snapshot.params['id'];

    this.eventService.getEventForId(this.selectedEventId).subscribe(
        res => {
          this.selectedEvent = res.data[0];
          this.eventStatus = this.selectedEvent.eventStatus;
        }
    );
  }
  formatDate(date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const dateTime = new Date(date);
    return months[dateTime.getMonth()] + ' ' +
        this.appendLeadingZeroes(dateTime.getDate());
  }
  appendLeadingZeroes(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n
  }
  eventApproved() {

  }
  eventEdit() {
    this.router.navigateByUrl('/events/edit/' + this.selectedEventId);
  }

  addVenues() {
    this.router.navigateByUrl('/events/edit/venue/' + this.selectedEventId);
  }

  eventRequestAction(button) {
    const eventAction = button.name;
    const eventActionClass = button.styleClass;
    const eventTitle = (eventAction === EVENT_APPROVE) ? EVENT_REMARK : EVENT_REASON;

    const dialogRef = this.dialog.open(CommentBoxDialogComponent, {
      data: {title: eventTitle + ' to ' + eventAction + ' the Event Request', actionName: eventAction, actionClass: eventActionClass}
    });

    dialogRef.componentInstance.onActionButtonClick.subscribe((comment) => {
      this.saveEventAction(eventAction, comment);
      dialogRef.close();
    });
  }

  saveEventAction(name, comment) {
    const eventActionParam = {
      actionName: name,
      reason: comment
    };
    console.log(eventActionParam);

    // this.eventService.createEventWorkflow(eventActionParam).subscribe(resp => {
    //   if (resp.result === 0) {
    //     this.alertService.success(resp.message);
    //   } else {
    //     this.alertService.error(resp.message)
    //   }
    // }, err => {
    //   this.alertService.error('Error processing your request. Please try again later.')
    // });
  }
}
