<div class="relative">
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
        <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
            <mat-card class="card-background" *ngIf="userProfile" style="box-shadow: unset !important">
                <div fxLayout="row" style="height: 300px;">
                    <div fxFlex="40">
                        <i mat-card-avatar class="accent-color">
                            {{getUserInitials()}}
                        </i>
                    </div>
                    <div fxFlex="55" fxLayout="column" fxLayoutAlign="space-around start">
                        <div>
                            <label class="lg-font">
                                {{getUserFullName(userProfile)}}
                            </label>
                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">location_on</mat-icon>
                            <div class="padding-5">
                                <label>
                                    {{getUserLocation()}}
                                </label>
                            </div>

                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">building</mat-icon>
                            <div class="padding-5">
                                <label>
                                    {{userProfile.jobTitle.name}}
                                </label>
                            </div>
                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">phone</mat-icon>
                            <div class="padding-5">

                                <label>
                                    {{user.phoneNumber}}
                                </label>
                            </div>
                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">phone</mat-icon>
                            <div class="padding-5">

                                <label>
                                    {{getUserAccount(constants.TELEGRAM)}}
                                </label>
                            </div>
                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">email</mat-icon>
                            <div class="padding-5">

                                <label>
                                    {{getUserAccount(constants.PERSONAL_EMAIL)}}
                                </label>
                            </div>
                        </div>
                        <div class="display-flex">
                            <mat-icon aria-hidden="false">email</mat-icon>
                            <div class="padding-5">

                                <label>
                                    {{getUserAccount(constants.JSI_EMAIL)}}
                                </label>
                            </div>
                        </div>
                        <div class="display-flex" *ngFor="let userRelationship of userRelationShipMap">
                            <mat-icon aria-hidden="false">supervisor_account</mat-icon>
                            <div class="padding-5">

                                <label>
                                    <b>{{userRelationship.title}}</b>: &nbsp;{{userRelationship.name}}
                                </label>
                            </div>
                        </div>

                    </div>
                    <div fxFlex="5" fxLayoutAlign="end start" class="cursor-pointer">
                        <mat-icon (click)="editProfile()">edit</mat-icon>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>