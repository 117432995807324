import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/account.service';
import {PasswordResetModel} from '../../models/User';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  public password: String;
  public userID: number;
  public token: string;
  public username: string;
  public setPasswordForm: FormGroup;
  public hide = true;

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private accountService: AccountService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token;
    this.username = this.route.snapshot.queryParams.username;
    this.userID = this.route.snapshot.params['userId'];
    this.setPasswordForm = this.fb.group ({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

  setPassword() {
    if (!this.showMismatchError) {
      const passwordResetModel = new PasswordResetModel();
      passwordResetModel.id = this.userID;
      passwordResetModel.token = this.token;
      passwordResetModel.password = this.setPasswordForm.controls.password.value;
      passwordResetModel.confirmPassword = this.setPasswordForm.controls.confirmPassword.value;
// todo: start spinner
      this.accountService.resetPassword(passwordResetModel).subscribe(
          resp => {
           const snackbarRef = this._snackBar.open('Successfully reset password.', 'Login', {
              duration: 100000,
            });
            snackbarRef.onAction().subscribe(() => {
              this.router.navigateByUrl('/login')
            })
          },
          err => {
            this._snackBar.open('Unable to reset password. Please try later.', null, {
              duration: 100000,
              panelClass: ['snackbar-error']
            });
          },
          () => {
          }
      );
    } else {
    }
  }

  get showMismatchError() {
    const notMatched: boolean = this.setPasswordForm.controls.confirmPassword.value !==
        this.setPasswordForm.controls.password.value;

    if (!notMatched) {
      this.setPasswordForm.controls.confirmPassword.setErrors(null);
    } else {
      this.setPasswordForm.controls.confirmPassword.setErrors({
        notMatched: true
      });
    }
    return notMatched;
  }

}
