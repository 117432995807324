import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AdvanceRequestService} from '../../services/advance-request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ActionButton,
    AdvanceRequest,
    RequestAccommodationsModel,
    RequestExpensesModel,
    RequestObjectiveModel
} from '../../models/AdvanceRequest';
import {MatTableDataSource} from '@angular/material/table';
// import * as jspdf from 'jspdf';
// import jsreport from 'jsreport-browser-client-dist/jsreport';
import * as Constants from '../../models/Constants';


// import html2canvas from 'html2canvas';
import {UserResponseModel} from '../../models/User';
import {AlertService} from '../../services/alert.service';
import {CommentBoxDialogComponent} from '../../shared/comment-box-dialog/comment-box-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-advance-request-view',
    templateUrl: './expense-view.component.html',
    styleUrls: ['./expense-view.component.css', '../../advance-request/advance-request-form/advance-form.component.css']
})
export class ExpenseViewComponent implements OnInit {

    public advanceRequestId: number;
    public advanceRequest: AdvanceRequest;
    public selectedTab = 0;
    public mieRequestAccommodationsModels: RequestAccommodationsModel[];
    public lodgingRequestAccommodationsModels: RequestAccommodationsModel[];
    public buttonNames: ActionButton[];
    public comments = [];
    public allComments = [];
    public poDataSource: MatTableDataSource<RequestObjectiveModel>;
    public leDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public mieDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public oeDataSource: MatTableDataSource<RequestExpensesModel>;
    public displayedColumns: string[] = ['site', 'product', 'type', 'summary'];
    public otherExpenseDisplayedColumns: string[] = ['purpose', 'otherAmount'];
    public lodgingExpenseDisplayedColumns: string[] = ['city', 'type', 'lodgingPerDay', 'numberOfNights', 'amount'];
    public MIEExpenseDisplayedColumns: string[] = ['city', 'type', 'MIEPerDay', 'numberOfDays', 'amount'];
    public showComment = false;

    constructor(private advanceRequestService: AdvanceRequestService, private route: ActivatedRoute,
                private alertService: AlertService, private router: Router, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.advanceRequestId = this.route.snapshot.params['advanceRequestId'];

        this.getAdvanceRequest();
        this.getAdvanceRequestWorkflow();
        this.getAdvanceRequestCommentList()

    }

    navigateToCreateExpense() {
        this.router.navigateByUrl(`/expense/create/${ this.advanceRequestId }`);
    }
    
    getAdvanceRequestWorkflow() {
        this.advanceRequestService.getAdvanceRequestWorkflow(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.buttonNames = resp.model;

                }
            }
        )
    }
    getAdvanceRequestCommentList() {
        this.advanceRequestService.getAdvanceRequestCommentList(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.comments = resp.model.filter(comm => comm.comment != null);
                    this.allComments = resp.model;
                }
            }
        )
    }

    getAdvanceRequest() {
        this.advanceRequestService.getAdvanceRequest(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.advanceRequest = resp.model;
                    this.mieRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.MIE) : [];
                    this.lodgingRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.LODGING) : [];
                    this.poDataSource = new MatTableDataSource<RequestObjectiveModel>(this.advanceRequest.requestObjectives);
                    this.leDataSource = new MatTableDataSource<RequestAccommodationsModel>(this.lodgingRequestAccommodationsModels);
                    this.mieDataSource = new MatTableDataSource<RequestAccommodationsModel>(this.mieRequestAccommodationsModels);
                    this.oeDataSource = new MatTableDataSource<RequestExpensesModel>(this.advanceRequest.requestExpenses);
                }
            }
        )
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US')
    }

    getUserInitial(fullName) {
        const names = fullName.split('\\s+');
        let initials = '';
        names.forEach(function (name) {
            initials += name[0].toUpperCase();
        });
        return initials;
    }
    appendLeadingZeroes(n) {
        if (n <= 9) {
            return '0' + n;
        }
        return n
    }
    formatDateTime(date) {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const dateTime = new Date(date);
        return (new Date(date)).toLocaleTimeString() + ' ' + months[dateTime.getMonth()] + ' ' +
            this.appendLeadingZeroes(dateTime.getDate());
    }
    get supervisorFullName() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userRelationships && this.advanceRequest.requester.userRelationships.length > 0) {
            return this.getUserFullName(this.advanceRequest.requester.userRelationships.find(ur => ur.relationShipType === Constants.SUPERVISOR).parentUser);
        }
        return '';
    }

    get supervisorJobTitle() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userRelationships && this.advanceRequest.requester.userRelationships.length > 0) {
            return this.advanceRequest.requester.userRelationships.find(ur => ur.relationShipType === Constants.SUPERVISOR).parentUser.userProfile[0].jobTitle.name;
        }
        return '';
    }
    get financeFullName() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userRelationships && this.advanceRequest.requester.userRelationships.length > 0) {
            return this.getUserFullName(this.advanceRequest.requester.userRelationships.find(ur => ur.relationShipType === Constants.FINANCE).parentUser);
        }
        return '';
    }

    get financeJobTitle() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userRelationships && this.advanceRequest.requester.userRelationships.length > 0) {
            return this.advanceRequest.requester.userRelationships.find(ur => ur.relationShipType === Constants.FINANCE).parentUser.userProfile[0].jobTitle.name;
        }
        return '';
    }
    get checkedDate() {
        if (this.allComments.find(cmt => cmt.toStatus === 'Checked')) {
            return this.formatDate(this.allComments.find(cmt => cmt.toStatus === 'Checked').date);
        }
        return '';
    }
    get approvedDate() {
        if (this.allComments.find(cmt => cmt.toStatus === 'Approved')) {
            return this.formatDate(this.allComments.find(cmt => cmt.toStatus === 'Approved').date)
        }
        return '';
    }
    get requesterBaseLocation() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userLocations
            && this.advanceRequest.requester.userLocations.length > 0) {
            return this.advanceRequest.requester.userLocations[0].location.name;
        }
        return '';
    }

    get requesterJobTitle() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userProfile) {
            return this.advanceRequest.requester.userProfile[0].jobTitle.name;
        }
        return '';
    }

    get requesterFullName() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userProfile) {
            return this.getUserFullName(this.advanceRequest.requester);
        }
        return '';
    }

    getUserFullName(user: UserResponseModel) {
        const userProfile = user.userProfile[0];
        return (userProfile.firstName ? userProfile.firstName : '') + ' ' +
            (userProfile.middleName ? userProfile.middleName : '') + ' ' +
                (userProfile.lastName ? userProfile.lastName : '');
    }

    getTravelDateRange() {
        return this.formatDate(this.advanceRequest.startDate) + ' - ' + this.formatDate(this.advanceRequest.endDate);
    }

    calculateLodgingAmount(lodging: RequestAccommodationsModel) {
        return lodging.numberOfDays * lodging.location.locationAllowance.locationAllowanceType.lodgingAllowance;
    }

    calculateMIEAmount(mie) {
        return mie.numberOfDays * mie.location.locationAllowance.locationAllowanceType.mieAllowance;
    }

    calculateTotalOtherExpense() {
        let totalAmount = 0;

        if (this.advanceRequest.requestExpenses) {
            for (let i = 0; i < this.advanceRequest.requestExpenses.length; i++) {
                totalAmount += this.advanceRequest.requestExpenses[i].cost;
            }
        }
        return totalAmount;
    }

    calculateTotalMIEExpense() {
        let totalAmount = 0;

        if (this.mieRequestAccommodationsModels) {
            for (let i = 0; i < this.mieRequestAccommodationsModels.length; i++) {
                totalAmount += this.mieRequestAccommodationsModels[i].numberOfDays *
                    this.mieRequestAccommodationsModels[i].location.locationAllowance.locationAllowanceType.mieAllowance;
            }
        }
        return totalAmount * .75;
    }

    calculateTotalLodgingExpense() {
        let totalAmount = 0;

        if (this.lodgingRequestAccommodationsModels) {
            for (let i = 0; i < this.lodgingRequestAccommodationsModels.length; i++) {
                totalAmount += this.lodgingRequestAccommodationsModels[i].numberOfDays *
                    this.lodgingRequestAccommodationsModels[i].location.locationAllowance.locationAllowanceType.lodgingAllowance;
            }
        }
        return totalAmount;
    }

    calculateTotalExpense() {
        return this.calculateTotalLodgingExpense() + this.calculateTotalMIEExpense() + this.calculateTotalOtherExpense();
    }

    advanceRequestAction(button) {
        const actionName = button.name;
        if (this.checkCommentRequired(button)) {
            const dialogRef = this.dialog.open(CommentBoxDialogComponent, {
                data: {title: 'Reasons for ' + button.name + 'ing the Advance Request', actionName: button.name}
            });

            dialogRef.componentInstance.onActionButtonClick.subscribe((comment) => {
                this.performAction(actionName, comment);
                dialogRef.close();
            });
        } else {
            this.performAction(actionName, null);
        }

    }

    private performAction(actionName, comment) {
        // TODO: handle user comment
        this.advanceRequestService.createAdvanceRequest(this.advanceRequest, actionName,
            this.advanceRequestId ? this.advanceRequestId : '', comment).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.alertService.success(resp.message);
                    this.getAdvanceRequestWorkflow();
                    this.getAdvanceRequest();
                    this.getAdvanceRequestCommentList();
                } else {
                    this.alertService.error(resp.message)
                }
            }, err => {
                this.alertService.error('Error processing your request. Please try again later.')
            }
        )
    }

    getLocationTypeForLodgingExpense(index) {

        let locationType = '-';
        if (this.lodgingRequestAccommodationsModels && this.lodgingRequestAccommodationsModels[index] &&
            this.lodgingRequestAccommodationsModels[index].location) {
            const site = this.lodgingRequestAccommodationsModels[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }

    getLodgingAllowanceForElement(index) {
        let lodgingAllowance = 0;
        if (this.lodgingRequestAccommodationsModels && this.lodgingRequestAccommodationsModels[index] &&
            this.lodgingRequestAccommodationsModels[index].location) {

            const site = this.lodgingRequestAccommodationsModels[index].location;
            lodgingAllowance = site ? site.locationAllowance ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : 0 : 0 : 0;
        }
        return lodgingAllowance;
    }

    getMIEAllowanceForElement(index) {
        let mieAllowance = 0;

        if (this.mieRequestAccommodationsModels && this.mieRequestAccommodationsModels[index] && this.mieRequestAccommodationsModels[index].location) {

            const site = this.mieRequestAccommodationsModels[index].location;
            mieAllowance = site ? site.locationAllowance ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : 0 : 0 : 0;
        }
        return mieAllowance;
    }

    getLocationTypeForMIEExpense(index) {

        let locationType = '-';
        if (this.mieRequestAccommodationsModels && this.mieRequestAccommodationsModels[index] && this.mieRequestAccommodationsModels[index].location) {
            const site = this.mieRequestAccommodationsModels[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }
    getSitesToBeVisited() {
        let sites = '';
        const _this = this;
        this.advanceRequest.requestObjectives.forEach(function (ro, idx) {
            sites += ro.location.name + (idx === _this.advanceRequest.requestObjectives.length - 1 ? '' : ', ');
        });
        return sites;
    }
    getAllBudgetActivityNumbers() {
        let ban = '';
        const _this = this;
        this.advanceRequest.requestObjectives.forEach(function (ro, idx) {
            ban += ro.product.activityCode + (idx === _this.advanceRequest.requestObjectives.length - 1 ? '' : ', ');
        });
        return ban;
    }
    checkCommentRequired(button) {
        if (button.options && button.options.Tags) {
            return button.options.Tags.indexOf('RequiresComment') > -1;
        }
        return false
    }
    checkSupervisorSigned() {
        const status = this.advanceRequest.status
        if (status.options && status.options.Tags) {
            return status.options.Tags.indexOf('supervisorSigned') > -1;
        }
        return false
    }
    checkFinanceSigned() {
        const status = this.advanceRequest.status
        if (status.options && status.options.Tags) {
            return status.options.Tags.indexOf('financeSigned') > -1;
        }
        return false
    }

}
