// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  baseUrl : 'https://api.cpm.sandboxaddis.com/Api',
  testUrl : 'https://deventsmiddleware.k8s.sandboxaddis.com',
  domain: 'api.cpm.sandboxaddis.com',
  version: '1.0.1 Dev',
  firebase: {
    apiKey: 'AIzaSyCAbztwO4HJrNHsfpKnuRryZqENMutwEmc',
    authDomain: 'devents-294606.firebaseapp.com',
    databaseURL: 'https://devents-294606.firebaseio.com',
    projectId: 'devents-294606',
    storageBucket: 'devents-294606.appspot.com',
    messagingSenderId: '588900919375',
    appId: '1:588900919375:web:0ba42cb39a5bbec8c9623a',
    measurementId: 'G-7NC30EN10G',
  }
};
