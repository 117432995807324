<mat-sidenav-container [hasBackdrop]="false">

<div class="relative">
    <mat-sidenav [opened]="showComment" mode="push" position="end">
        <div class="comment-card" *ngFor="let comment of comments">
            <div style="display: inline-flex">
                <div style="margin: 12px" class="circle accent-color">
                    <span class="initials">{{getUserInitial(comment.commenterName)}}</span>
                </div>
                <div style="margin-top: 12px">
                    <label><b>{{comment.commenterName}}</b></label>
                    <br/>
                    <label>{{formatDateTime(comment.date)}}</label>
                </div>
            </div>
            <div style="margin: 12px; margin-top: 3px">
                <span style="font-style: italic">Reasons for {{comment.action}}ing: &nbsp;</span>{{comment.comment}}
            </div>
            <mat-divider style="margin-bottom: 18px"></mat-divider>
        </div>
        <div style="width: 250px; padding: 30px" *ngIf="comments.length === 0">No comments for this request.</div>
    </mat-sidenav>
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
        <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
            <mat-card class="form-container-card no-padding" *ngIf="!loading">

                <div class="header primary-color">
                    <h4 class="title-text">ADVANCE REQUEST FORM</h4>
                    <button class="print-button" style="right: 40px;" (click)="showComment = !showComment"
                    >
                        <mat-icon [matBadge]="comments.length" matBadgeColor="accent">comment</mat-icon>
                    </button>
                </div>

                <div>

                    <form #form="ngForm" [formGroup]="advanceRequestForm" class="form-body">
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <div fxLayout="column">
                                <h4 class="section-label">General Information</h4>
                                <div fxLayout="row" fxLayoutAlign="space-around">
                                    <mat-form-field fxFlex="25">
                                        <mat-select formControlName="chargeCodeId" placeholder="Charge Code" required
                                                    [disabled]="true">
                                            <mat-option *ngFor="let chargeType of chargeTypes" [value]="chargeType.id">
                                                {{chargeType.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="checkError('chargeCodeId', 'required')">Charge Code is
                                            required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="25">
                                        <mat-select formControlName="requestTypeId" placeholder="Request Type" required>
                                            <mat-option *ngFor="let requestType of requestTypes"
                                                        [value]="requestType.id">
                                                {{requestType.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="checkError('requestTypeId', 'required')">Request Type is
                                            required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="25">
                                        <mat-label>Start Date</mat-label>
                                        <input formControlName="startDate" matInput [matDatepicker]="startPicker"
                                               placeholder="dd/mm/yyyy" required [min]="minDate">
                                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #startPicker></mat-datepicker>
                                        <mat-error *ngIf="checkError('startDate', 'required')">Start date is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around">
                                    <mat-form-field fxFlex="60">
                                    <textarea matInput
                                              cdkAutosizeMinRows="5"
                                              cdkAutosizeMaxRows="5"
                                              placeholder="Purpose of Travel" formControlName="description"></textarea>
                                        <input matInput placeholder="Other Information" type="textarea"
                                               name="description">
                                    </mat-form-field>
                                    <div fxFlex="25"></div>
                                </div>
                            </div>
                            <div fxLayout="column">
                                <h4 class="section-label">Scope of Travel</h4>
                                <table formArrayName="requestObjectives" mat-table [dataSource]="poDataSource"
                                       class="mat-elevation-z2">

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                                    <ng-container matColumnDef="site">
                                        <th mat-header-cell *matHeaderCellDef> Site</th>
                                        <td mat-cell *matCellDef="let element; let index = index"
                                            [formGroupName]="index">
                                            <mat-form-field>
                                                <mat-select formControlName="locationId" placeholder="Select" required>
                                                    <mat-option *ngFor="let site of sites"
                                                                [value]="site.id">
                                                        {{site.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="checkROError(index, 'locationId', 'required')">Site is required
                                                </mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef></td>
                                    </ng-container>
                                    <ng-container matColumnDef="product">
                                        <th mat-header-cell *matHeaderCellDef> Product</th>
                                        <td mat-cell *matCellDef="let element; let index = index"
                                            [formGroupName]="index">
                                            <mat-form-field>
                                                <mat-select formControlName="productId" placeholder="Select" required>
                                                    <mat-option *ngFor="let product of products"
                                                                [value]="product.id">
                                                        {{product.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="checkROError(index, 'productId', 'required')">Product is required
                                                </mat-error>
                                            </mat-form-field>
                                        </td>
                                        a
                                        <td mat-footer-cell *matFooterCellDef></td>
                                    </ng-container>
                                    <ng-container matColumnDef="type">
                                        <th mat-header-cell *matHeaderCellDef> Service</th>
                                        <td mat-cell *matCellDef="let element; let index = index"
                                            [formGroupName]="index">
                                            <mat-form-field>
                                                <mat-select formControlName="serviceTypeId" placeholder="Select"
                                                            required>
                                                    <mat-option *ngFor="let serviceType of serviceTypes"
                                                                [value]="serviceType.id">
                                                        {{serviceType.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="checkROError(index, 'serviceTypeId', 'required')">Service Type is required
                                                </mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef></td>
                                    </ng-container>
                                    <ng-container matColumnDef="summary">
                                        <th mat-header-cell *matHeaderCellDef> Summary</th>
                                        <td mat-cell *matCellDef="let element; let index = index"
                                            [formGroupName]="index">
                                            <mat-form-field>
                                                 <textarea matInput formControlName="description"
                                                           cdkAutosizeMinRows="2"
                                                           cdkAutosizeMaxRows="2"
                                                           placeholder="Type your summary here..."></textarea>
                                                <input matInput type="textarea" name="description">
                                                <mat-error *ngIf="checkROError(index, 'description', 'required')">Summary is required
                                                </mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef>

                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let index = index">
                                            <button style="float: right;" mat-icon-button class="ml-xs overflow-visible"
                                                    (click)="removeRow('objectives', index)"><mat-icon style="color: red; cursor: pointer;"
                                            >remove_circle
                                            </mat-icon></button>

                                        </td>
                                        <td mat-footer-cell *matFooterCellDef><button style="float: right;" mat-icon-button class="ml-xs overflow-visible"
                                                                                      (click)="addNewProjectObjective()">
                                            <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                        </button></td>

                                    </ng-container>

                                </table>
                            </div>
                            <div fxLayout="column">
                                    <h4 class="section-label">Lodging Advances</h4>
                                    <table mat-table formArrayName="leRequestAccommodations" [dataSource]="leDataSource"
                                           class="mat-elevation-z2">

                                        <ng-container matColumnDef="city">
                                            <th mat-header-cell *matHeaderCellDef> City</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input type="text"
                                                           formControlName="location" placeholder="Select" required
                                                           matInput
                                                           [matAutocomplete]="auto">
                                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                        <mat-option
                                                                *ngFor="let site of leObservableLodgingLocations | async"
                                                                [value]="site">
                                                            {{site.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error *ngIf="checkLEError(index, 'location', 'required')">City is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef> Type</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{getLocationTypeForLodgingExpense(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        <ng-container matColumnDef="lodgingPerDay">
                                            <th mat-header-cell *matHeaderCellDef> Lodging Per Day</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{getLodgingAllowanceForElement(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef><b>Total Lodging Advance(A)</b></td>
                                        </ng-container>
                                        <ng-container matColumnDef="numberOfNights">
                                            <th mat-header-cell *matHeaderCellDef> No. Nights</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfDays" placeholder="0" matInput
                                                           type="number" required name="numberOfDays" required>
                                                    <mat-error *ngIf="checkLEError(index, 'numberOfDays', 'required')">Number of nights is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="amount">
                                            <th mat-header-cell *matHeaderCellDef> Amount</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{calculateLodgingAmount(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <b>{{calculateTotalLodgingExpense()}} &nbsp; Birr</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="removeRow('lodging', index)"><mat-icon style="color: red; cursor: pointer;"
                                                >remove_circle
                                                </mat-icon></button>

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="addNewRequestAccommodations('LDG')">
                                                    <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="lodgingExpenseDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: lodgingExpenseDisplayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="lodgingExpenseDisplayedColumns"></tr>

                                    </table>
                            </div>
                            <div fxLayout="column">
                                    <h4 class="section-label">M & IE Advances</h4>
                                    <table mat-table formArrayName="mieRequestAccommodations" [dataSource]="mieDataSource"
                                           class="mat-elevation-z2">

                                        <ng-container matColumnDef="city">
                                            <th mat-header-cell *matHeaderCellDef> City</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input type="text"
                                                           formControlName="location" placeholder="Select" required
                                                           matInput
                                                           [matAutocomplete]="auto">
                                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                        <mat-option
                                                                *ngFor="let site of mieObservableLodgingLocations | async"
                                                                [value]="site">
                                                            {{site.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error *ngIf="checkMIEError(index, 'location', 'required')">City is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef> Type</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{getLocationTypeForMIEExpense(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                        <ng-container matColumnDef="MIEPerDay">
                                            <th mat-header-cell *matHeaderCellDef> M & IE Per Day</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{getMIEAllowanceForElement(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef><b>Total M & IE Advance (B) 75%</b></td>
                                        </ng-container>
                                        <ng-container matColumnDef="numberOfDays">
                                            <th mat-header-cell *matHeaderCellDef> No. Days</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="numberOfDays" placeholder="0" matInput
                                                           type="number" required name="numberOfDays" required>
                                                    <mat-error *ngIf="checkMIEError(index, 'numberOfDays', 'required')">Number of days is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="amount">
                                            <th mat-header-cell *matHeaderCellDef> Amount</th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <span>{{calculateMIEAmount(index)}}</span>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <b>{{calculateTotalMIEExpense()}}  &nbsp; Birr</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element; let index = index">

                                                <!-- <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="removeRow('MIE', index)"><mat-icon style="color: red; cursor: pointer;">remove_circle
                                                </mat-icon></button> -->

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <!-- <button style="float: right;" mat-icon-button class="ml-xs overflow-visible" (click)="addNewRequestAccommodations('MIE')">
                                                <mat-icon>add_circle</mat-icon>
                                                </button> -->
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="MIEExpenseDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: MIEExpenseDisplayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="MIEExpenseDisplayedColumns"></tr>

                                    </table>
                            </div>
                            <div fxLayout="column">
                                    <h4 class="section-label">Other Advances</h4>
                                    <table mat-table formArrayName="requestExpenses" [dataSource]="oeDataSource"
                                           class="mat-elevation-z2">

                                        <ng-container matColumnDef="purpose">
                                            <th mat-header-cell *matHeaderCellDef> Purpose</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-select formControlName="purposeId" placeholder="Purpose"
                                                          >
                                                    <mat-option *ngFor="let purpose of otherExpensePurposeList" [value]="purpose.id">
                                                        {{purpose.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="checkREError(index, 'purposeId', 'required')">Expense purpose is
                                                    required
                                                </mat-error>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef><b>Total Other Advance (C) </b></td>
                                        </ng-container>
                                        <ng-container matColumnDef="otherAmount">
                                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                                            <td mat-cell *matCellDef="let element; let index = index"
                                                [formGroupName]="index">
                                                <mat-form-field class="medium-width-input">
                                                    <input formControlName="cost" placeholder="0" matInput
                                                           type="number" required name="cost">
                                                    <mat-error *ngIf="checkREError(index, 'cost', 'required')">Amount is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef>
                                                <b>{{calculateTotalOtherExpense()}} &nbsp; Birr</b>

                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <button style="float: right;" mat-icon-button
                                                        class="ml-xs overflow-visible"
                                                        (click)="removeRow('otherExpenses', index)">
                                                    <mat-icon style="color: red; cursor: pointer;"
                                                    >remove_circle
                                                    </mat-icon>
                                                </button>

                                            </td>
                                            <td mat-footer-cell *matFooterCellDef><button style="float: right;" mat-icon-button
                                                                                          class="ml-xs overflow-visible"

                                                                                          (click)="addNewRequestExpense()">
                                                <mat-icon style="color: #b14900;">add_circle</mat-icon>
                                            </button></td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="otherExpenseDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: otherExpenseDisplayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="otherExpenseDisplayedColumns"></tr>

                                    </table>
                            </div>
                            <div fxLayout="column">
                                <h4 class="section-label">Grand Total</h4>
                                <table class="mat-table" class="mat-elevation-z2">

                                    <tr class="mat-row">
                                        <td class="mat-cell">
                                            <b>Total  Advance  = A +  (B X 75%) + C</b>
                                        </td>

                                        <td class="mat-cell">
                                            <b>{{calculateTotalExpense()}}</b>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" style="margin-top: 10px;">
                                <button *ngFor="let button of actionButtons"
                                        (click)="advanceFormAction(button)"
                                        [disabled]="advanceRequestForm.invalid && checkValidationRequired(button)"
                                        [ngClass]="(button.options && button.options.style) ? button.options.style : 'Default'"
                                        class="button-margin-left" mat-raised-button>{{button.name}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card>
        </div>
    </div>
</div>
</mat-sidenav-container>