import {Component, NgZone, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserResponseModel, User} from '../../models/User';
import {
    ActionButton,
    AdvanceRequest,
    RequestAccommodationsModel,
    RequestExpensesModel
} from '../../models/AdvanceRequest';
import {LookupModel} from '../../models/Common';
import {Location} from '../../models/User';
import {LookupService} from '../../services/lookup.service';
import * as Constants from '../../models/Constants';
import {MatTableDataSource} from '@angular/material/table';
import {AdvanceRequestService} from '../../services/advance-request.service';
import {AlertService} from '../../services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {LoaderService} from '../../services/loader.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-expense-form',
    templateUrl: './expense-form.component.html',
    styleUrls: [
        '../../advance-request/advance-request-form/advance-form.component.css',
        './expense-form.component.css'
    ]
  })
  export class ExpenseFormComponent implements OnInit {
    public chargeTypes: LookupModel[];
    public accommodationTypes: LookupModel[];
    public LDGAccommocation: LookupModel;
    public MIEAccommocation: LookupModel;
    public requestTypes: LookupModel[];
    public serviceTypes: LookupModel[];
    public products: LookupModel[];
    public actionButtons: ActionButton[];
    public advanceRequest: AdvanceRequest;
    public sites: Location[];
    public mealTypes: LookupModel[];
    public lodgingLocations: Location[];
    public leObservableLodgingLocations: Observable<Location[]>;
    public mieObservableLodgingLocations: Observable<Location[]>;
    public otherExpensePurposeList: LookupModel[];
    public expenseForm: FormGroup;
    public leDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public mieDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public oeDataSource: MatTableDataSource<RequestExpensesModel>;
    public advanceRequestId: number;
    public otherExpenseDisplayedColumns: string[] = ['startDate', 'purpose', 'accountCode', 'otherAmount', 'attachments', 'actions'];
    public lodgingExpenseDisplayedColumns: string[] = ['startDate', 'endDate', 'hotelName',  'numberOfNights','lodgingPerDay', 'amount', 'attachments', 'actions'];
    public MIEExpenseDisplayedColumns: string[] = ['startDate', 'endDate', 'city', 'type', 'MIEPerDay', 'numberOfDays', 'amount', 'actions'];
    public mieRequestAccommodationsModels: RequestAccommodationsModel[];
    public lodgingRequestAccommodationsModels: RequestAccommodationsModel[];
    // public minDate = new Date();
    public showComment = false;
    public comments = [];

    // files name place holders when attachment is selected
    public leSelectedfileLabels = {};
    public oeSelectedfileLabels = {};
    
    public expenseId;
    public previouslyRequestedAdvance = 0;

    constructor(private _ngZone: NgZone, private fb: FormBuilder, private lookupService: LookupService,
                private advanceRequestService: AdvanceRequestService, private alertService: AlertService,
                private router: Router, private route: ActivatedRoute, private loadingService: LoaderService) {
    }

    ngOnInit(): void {
        this.getLookups();
        this.expenseId = this.route.snapshot.params['expenseId'];
        this.advanceRequestId = this.route.snapshot.params['advanceRequestId']; 
        this.oeDataSource = new MatTableDataSource<RequestExpensesModel>([]);
        this.mieDataSource = new MatTableDataSource<RequestAccommodationsModel>([]);
        this.leDataSource = new MatTableDataSource<RequestAccommodationsModel>([]);

        this.getAdvanceRequest();
        this.getAdvanceRequestCommentList()
        this.getExpenseWorkflow();
    }

    get minDate() {
        return this.advanceRequest.startDate;
    }

    get loading() {
        return this.loadingService.loading;
    }
    getUserInitial(fullName) {
        const names = fullName.split('\\s+');
        let initials = '';
        names.forEach(function (name) {
            initials += name[0].toUpperCase();
        });
        return initials;
    }
    appendLeadingZeroes(n) {
        if (n <= 9) {
            return '0' + n;
        }
        return n
    }
    formatDateTime(date) {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const dateTime = new Date(date);
        return (new Date(date)).toLocaleTimeString() + ' ' + months[dateTime.getMonth()] + ' ' +
            this.appendLeadingZeroes(dateTime.getDate());
    }
    getAdvanceRequestCommentList() {
        this.advanceRequestService.getAdvanceRequestCommentList(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.comments = resp.model.filter(comm => comm.comment != null);
                }
            }
        )
    }
    getExpenseWorkflow() {
        this.advanceRequestService.getExpenseWorkflow(this.expenseId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.actionButtons = resp.model;
                }
            }
        )
    }

    getAdvanceRequest() {
        this.advanceRequestService.getAdvanceRequest(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.advanceRequest = resp.model;
                    this.mieRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.MIE) : [];
                    this.lodgingRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.LODGING) : [];

                    this.initializeForm();
                    // console.log(this.advanceRequest);
                }
            }
        )
    }

    initializeForm() {
        if (this.advanceRequestId) {
            this.expenseForm = this.fb.group({
                requestTypeId: [this.advanceRequest.requestTypeId, Validators.required],
                startDate: [this.advanceRequest.startDate, Validators.required],
                endDate: [this.advanceRequest.endDate],
                requestExpenses: this.fb.array([]),
                // requestAccommodations: this.fb.array([]),
                mieRequestAccommodations: this.fb.array([]),
                leRequestAccommodations: this.fb.array([]),
                requestMeal: this.fb.array([])
            });
            this.advanceRequest.requestExpenses.forEach(reqExp => {
                this.addExistingRequestExpense(reqExp);
            });

            // meals provided to participants
            this.addRequestMeals();

            // populate existing le models
            this.populateExistingLEModels();

            // populate existing mie models
            this.populateExistingMIEModels();

            // calculate total advance request
            this.previouslyRequestedAdvance += this.calculateTotalAdvance();
        }

    }

    // filter meal type: breakfast/lunch/dinner
    mealTypeEndsWith(type: String) {
        return this.mealTypes.filter(mealType => mealType.name.toLowerCase().endsWith(type.toLowerCase()));
    }

    addRequestMeals() {
        const requestMeals = this.expenseForm.get('requestMeal') as FormArray;
        requestMeals.push(
            this.fb.group({
                requestMealType: [''],
                requestMealTypeId: ['', Validators.required],
                numberOfDays: [0, Validators.required]
        }));
        requestMeals.push(this.fb.group({
            requestMealType: [''],
            requestMealTypeId: ['', Validators.required],
            numberOfDays: [0, Validators.required]
        }));
        requestMeals.push(this.fb.group({
            requestMealType: [''],
            requestMealTypeId: ['', Validators.required],
            numberOfDays: [0, Validators.required]
        }));

        requestMeals.at(0).get('requestMealType').valueChanges.subscribe(mealType => {
            requestMeals.at(0).get('requestMealTypeId').setValue(mealType.id);
        });
        requestMeals.at(1).get('requestMealType').valueChanges.subscribe(mealType => {
            requestMeals.at(1).get('requestMealTypeId').setValue(mealType.id);
        });
        requestMeals.at(2).get('requestMealType').valueChanges.subscribe(mealType => {
            requestMeals.at(2).get('requestMealTypeId').setValue(mealType.id);
        });
    }

    populateExistingMIEModels() {
        // pick the first mie expense, basically the base location
        const mieExpense: RequestAccommodationsModel = {
            ... this.mieRequestAccommodationsModels[0] 
        };

        // calculate previously given advance for MIE expenses before touching the models
        this.previouslyRequestedAdvance += this.calculateTotalMIEExpenseForAdvance(this.mieRequestAccommodationsModels);

        // account for the .75 trip days
        const totalMIE = this.mieRequestAccommodationsModels.length;
        const firstModel = this.mieRequestAccommodationsModels[0];
        const lastModel = this.mieRequestAccommodationsModels[totalMIE - 1];
        if (totalMIE == 1) {
            firstModel.numberOfDays = firstModel.numberOfDays - 2;
        } else {
            firstModel.numberOfDays = firstModel.numberOfDays - 1;
            lastModel.numberOfDays = lastModel.numberOfDays - 1;
        }

        // add new expenses for the first and the last days (trip days)
        mieExpense.numberOfDays = 0.75;
        mieExpense.location = this.requesterBaseLocationInfo;
        mieExpense.locationId = this.requesterBaseLocationInfo.id;
        this.mieRequestAccommodationsModels.splice(0, 0, { ...mieExpense }); // one at the top
        this.mieRequestAccommodationsModels.push({ ...mieExpense }); // one at the bottom
        
        this.mieRequestAccommodationsModels.forEach((reqAcc, index) => {
            if (index == 0) {
                reqAcc.startDate = this.advanceRequest.startDate;
                reqAcc.endDate = this.advanceRequest.startDate;
            } else {
                const previousIndex = index - 1;
                const previousAcc = this.mieRequestAccommodationsModels[previousIndex];
                reqAcc.startDate = new Date(dayjs(previousAcc.endDate).add(1, 'day').format());

                if(index == this.mieRequestAccommodationsModels.length - 1) {
                    reqAcc.endDate = reqAcc.startDate;
                } else {
                    reqAcc.endDate = new Date(dayjs(reqAcc.startDate).add(reqAcc.numberOfDays - 1, 'day').format());
                }
            }

            this.addExistingRequestAccommodation(reqAcc, Constants.MIE);
        });
    }

    populateExistingLEModels() {
        this.lodgingRequestAccommodationsModels.forEach((reqAcc, index) => {
            if (index == 0) {
                reqAcc.startDate = new Date(this.advanceRequest.startDate);
                reqAcc.endDate = new Date(dayjs(reqAcc.startDate).add(reqAcc.numberOfDays - 1, 'day').format());
            } else {
                const previousIndex = index - 1;
                const previousAcc = this.lodgingRequestAccommodationsModels[previousIndex];
                reqAcc.startDate = new Date(dayjs(previousAcc.endDate).add(1, 'day').format());
                reqAcc.endDate = new Date(dayjs(reqAcc.startDate).add(reqAcc.numberOfDays - 1, 'day').format());
            }

            this.addExistingRequestAccommodation(reqAcc, Constants.LODGING);
        });
    }

    mieNumberOfDaysChanged() {
        for(let i in this.mieRequestAccommodations.controls) {
            const index = parseInt(i);

            const reqAcc = this.mieRequestAccommodations.at(index);
            const startDate = reqAcc.get('startDate');
            const endDate = reqAcc.get('endDate');

            if (index == 0) {
                startDate.setValue(this.advanceRequest.startDate, { emitEvent: false });
                endDate.setValue(this.advanceRequest.startDate, { emitEvent: false });
            } else {
                const previousIndex = index - 1;
                const previousAcc = this.mieRequestAccommodations.at(previousIndex).value;
                
                const newStartDate = new Date(dayjs(previousAcc.endDate).add(1, 'day').format());
                startDate.setValue(newStartDate, { emitEvent: false });

                if (index == this.mieRequestAccommodations.length - 1) { // startdate == enddate for the last one
                    endDate.setValue(newStartDate, { emitEvent: false });
                    // update end date for trip
                    this.expenseForm.get('endDate').setValue(newStartDate);
                } else {
                    const numberOfDays = reqAcc.get('numberOfDays').value;
                    const newEndDate = new Date(dayjs(startDate.value).add(numberOfDays - 1, 'day').format())
                    endDate.setValue(newEndDate, { emitEvent: false });
                }
            }
        }
    }

    leNumberOfDaysChanged() {

        this.leRequestAccommodations.controls.forEach((_, index) => {

            const reqAcc = this.leRequestAccommodations.at(index);
            const startDate = reqAcc.get('startDate');
            const endDate = reqAcc.get('endDate');

            if (index == 0) {
                startDate.setValue(this.advanceRequest.startDate, { emitEvent: false, onlySelf: true });

                const numberOfNights = reqAcc.get('numberOfNights').value;
                const newEndDate = new Date(dayjs(startDate.value).add(numberOfNights - 1,'day').format());

                endDate.setValue(newEndDate, { emitEvent: false });
            } else {
                const previousIndex = index - 1;
                const previousAcc = this.leRequestAccommodations.at(previousIndex).value;

                const numberOfNights = reqAcc.get('numberOfNights').value;
                const newStartDate = new Date(dayjs(previousAcc.endDate).add(1, 'day').format());
                const newEndDate = new Date(dayjs(newStartDate).add(numberOfNights - 1, 'day').format());

                startDate.setValue(newStartDate, { emitEvent: false });
                endDate.setValue(newEndDate, { emitEvent: false });
            }
                
        });
        
    }

    mealsProvidedChanged(index) {
        const meal = this.mealsProvidedFormArray.at(index).get('city').value;
        if (meal) {
            this.mealsProvidedFormArray.at(index).get('amount').setValue(meal.amount);
        }
    }

    displayFn(location): string {
        return location && location.name ? location.name : '';
    }

    private _filter(name: string): Location[] {
        const filterValue = name.toLowerCase();

        return this.lodgingLocations.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
    }

    get requestExpenses() {
        return this.expenseForm.get('requestExpenses') as FormArray;
    }

    get leRequestAccommodations() {
        return this.expenseForm.get('leRequestAccommodations') as FormArray;
    }

    get mieRequestAccommodations() {
        return this.expenseForm.get('mieRequestAccommodations') as FormArray;
    }

    get mealsProvidedFormArray() {
        return this.expenseForm.get('requestMeal') as FormArray;
    }

    addNewRequestExpense() {
        this.oeDataSource.data.push(new RequestExpensesModel());
        this.oeDataSource.data = this.oeDataSource.data.slice();
        this.requestExpenses.push(
            this.fb.group({
                purposeId: [''],
                startDate: [''],
                cost: [0],
                attachment: [null]
            })
        )
    }

    addExistingRequestExpense(requestExpense) {
        this.oeDataSource.data.push(requestExpense);
        this.oeDataSource.data = this.oeDataSource.data.slice();
        this.requestExpenses.push(
            this.fb.group({
                id: [requestExpense.id],
                rowGuid: [requestExpense.rowGuid],
                createdDate: [requestExpense.createdDate],
                modifiedDate: [requestExpense.modifiedDate],
                modifiedBy: [requestExpense.modifiedBy],
                purposeId: [requestExpense.purposeId],
                startDate: [''],
                cost: [requestExpense.cost],
                attachment: [null]
            })
        )
    }

    addNewRequestAccommodations(accommodationType, indexAt = null) {
        if (accommodationType === Constants.LODGING) {

            /* Add Request Accomodation for LODING */

            this.leDataSource.data.push(new RequestAccommodationsModel());
            this.leDataSource.data = this.leDataSource.data.slice();
            this.leRequestAccommodations.push(
                this.fb.group({
                    location: ['', Validators.required],
                    locationId: ['', Validators.required],
                    numberOfNights: [1, Validators.required],
                    accommodationTypeId: [this.LDGAccommocation ? this.LDGAccommocation.id : ''],
                    startDate: [null, Validators.required],
                    endDate: [null, Validators.required],
                    locationName: ['', Validators.required],
                    cost: [0, Validators.required],
                    attachment: [null]
                })
            );
            this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.leObservableLodgingLocations = this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );

            
            /* Also add accomodation for MIE */

            if (this.mieDataSource.data.length == 0) {
                this.addNewRequestAccommodations(Constants.MIE);
                this.addNewRequestAccommodations(Constants.MIE);
                this.addNewRequestAccommodations(Constants.MIE);

                const baseLocation = this.requesterBaseLocationInfo;
                this.mieRequestAccommodations.at(0).patchValue({
                    location: baseLocation,
                    locationId: baseLocation.id,
                    numberOfDays: 0.75,
                    startDate: null,
                    endDate: null,
                    accommodationTypeId: [this.MIEAccommocation ? this.MIEAccommocation.id : '']
                });

                this.mieRequestAccommodations.at(2).patchValue({
                    location: baseLocation,
                    locationId: baseLocation.id,
                    numberOfDays: 0.75,
                    startDate: null,
                    endDate: null,
                    accommodationTypeId: [this.MIEAccommocation ? this.MIEAccommocation.id : '']
                });

            } else {
                this.addNewRequestAccommodations(Constants.MIE, this.mieDataSource.data.length - 1);
            }


        } else if (accommodationType === Constants.MIE) {

            // if no index is provided, set index to the MIE item
            indexAt = indexAt === null ? this.mieDataSource.data.length - 1 : indexAt;

            this.mieDataSource.data.splice(indexAt, 0, new RequestAccommodationsModel());
            this.mieDataSource.data = this.mieDataSource.data.slice();
            this.mieRequestAccommodations.insert(
                indexAt,
                this.fb.group({
                    location: ['', Validators.required],
                    locationId: ['', Validators.required],
                    numberOfDays: [1, Validators.required],
                    startDate: [null, Validators.required],
                    endDate: [null, Validators.required],
                    accommodationTypeId: [this.MIEAccommocation ? this.MIEAccommocation.id : '']
                })
            );
            this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
            
        }

    }

    // disable first and last row of m&ie expenses
    mieInputDisabled(index) {
        return index === 0 || index === this.mieRequestAccommodations.value.length - 1;
    }

    get requesterBaseLocationInfo() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userLocations
            && this.advanceRequest.requester.userLocations.length > 0) {
            return this.advanceRequest.requester.userLocations[0].location;
        }
    }

    get requesterBaseLocation() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userLocations
            && this.advanceRequest.requester.userLocations.length > 0) {
            return this.advanceRequest.requester.userLocations[0].location.name;
        }
        return '';
    }

    get requesterJobTitle() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userProfile) {
            return this.advanceRequest.requester.userProfile[0].jobTitle.name;
        }
        return '';
    }

    get requesterFullName() {
        if (this.advanceRequest && this.advanceRequest.requester && this.advanceRequest.requester.userProfile) {
            return this.getUserFullName(this.advanceRequest.requester);
        }
        return '';
    }
    
    getAllBudgetActivityNumbers() {
        let ban = '';
        const _this = this;
        this.advanceRequest.requestObjectives.forEach(function (ro, idx) {
            ban += ro.product.activityCode + (idx === _this.advanceRequest.requestObjectives.length - 1 ? '' : ', ');
        });
        return ban;
    }

    getUserFullName(user: UserResponseModel) {
        const userProfile = user.userProfile[0];
        return (userProfile.firstName ? userProfile.firstName : '') + ' ' +
            (userProfile.middleName ? userProfile.middleName : '') + ' ' +
                (userProfile.lastName ? userProfile.lastName : '');
    }

    getTravelDateRange() {
        return this.formatDate(this.expenseForm.value.startDate) + ' - ' + this.formatDate(this.expenseForm.value.endDate);
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US')
    }

    addExistingRequestAccommodation(accommodationExpense, accommodationType) {
        if (accommodationType === Constants.LODGING) {
            this.leDataSource.data.push(accommodationExpense);
            this.leDataSource.data = this.leDataSource.data.slice();
            this.leRequestAccommodations.push(
                this.fb.group({
                    id: [accommodationExpense.id],
                    rowGuid: [accommodationExpense.rowGuid],
                    createdDate: [accommodationExpense.createdDate],
                    modifiedDate: [accommodationExpense.modifiedDate],
                    modifiedBy: [accommodationExpense.modifiedBy],
                    location: [accommodationExpense.location, Validators.required],
                    locationId: [accommodationExpense.locationId, Validators.required],
                    numberOfNights: [accommodationExpense.numberOfDays, Validators.required],
                    locationName: ['', Validators.required],
                    cost: [0, Validators.required],
                    accommodationTypeId: [accommodationExpense.accommodationTypeId],
                    startDate: [accommodationExpense.startDate, Validators.required],
                    endDate: [accommodationExpense.endDate, Validators.required],
                    attachment: [null]
                })
            );
            this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.leObservableLodgingLocations = this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
        } else if (accommodationType === Constants.MIE) {
            this.mieDataSource.data.push(accommodationExpense);
            this.mieDataSource.data = this.mieDataSource.data.slice();
            this.mieRequestAccommodations.push(
                this.fb.group({
                    id: [accommodationExpense.id],
                    rowGuid: [accommodationExpense.rowGuid],
                    createdDate: [accommodationExpense.createdDate],
                    modifiedDate: [accommodationExpense.modifiedDate],
                    modifiedBy: [accommodationExpense.modifiedBy],
                    location: [accommodationExpense.location, Validators.required],
                    locationId: [accommodationExpense.locationId, Validators.required],
                    numberOfDays: [accommodationExpense.numberOfDays, Validators.required],
                    accommodationTypeId: [accommodationExpense.accommodationTypeId],
                    startDate: [accommodationExpense.startDate, Validators.required],
                    endDate: [accommodationExpense.endDate, Validators.required]
                })
            );
            this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
        }



    }

    getLookups() {
        this.lookupService.getLookups(Constants.CHARGE_CODE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.chargeTypes = resp.model;
                    if (this.expenseForm && ! this.advanceRequestId) {
                        this.expenseForm.get('chargeCodeId').setValue(this.chargeTypes[0].id)
                    }
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.ACCOMMODATION_TYPE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.accommodationTypes = resp.model;
                    this.MIEAccommocation = this.accommodationTypes.find(at => at.code === Constants.MIE);
                    this.LDGAccommocation = this.accommodationTypes.find(at => at.code === Constants.LODGING);
                    if (this.expenseForm && !this.advanceRequestId) {
                        this.mieRequestAccommodations.controls[0].get('accommodationTypeId').setValue(this.MIEAccommocation.id)
                        this.leRequestAccommodations.controls[0].get('accommodationTypeId').setValue(this.LDGAccommocation.id)
                    }
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.PURPOSE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.otherExpensePurposeList = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.PRODUCT).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.products = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.SERVICE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.serviceTypes = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLocationsWithType(Constants.VISIT_SITE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.sites = resp.model;
                }
            },
            err => {
            }
        );
        this.lookupService.getLookups(Constants.MEALTYPE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.mealTypes = resp.model;
                }
            },
            err => {
            }
        );
        this.lookupService.getLocationsWithType(Constants.SITE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.lodgingLocations = resp.model;
                    if (this.expenseForm) {
                        this.leRequestAccommodations.at(0).get('location').valueChanges.subscribe(selectedValue => {
                            this.leRequestAccommodations.at(0).get('locationId').setValue(selectedValue.id);
                        });
                        this.leObservableLodgingLocations = this.leRequestAccommodations.at(0).get('location').valueChanges
                            .pipe(
                                startWith<string | Location>(''),
                                map(value => typeof value === 'string' ? value : (value as Location).name),
                                map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                            );
                        this.mieRequestAccommodations.at(0).get('location').valueChanges.subscribe(selectedValue => {
                            this.mieRequestAccommodations.at(0).get('locationId').setValue(selectedValue.id);
                        });
                        this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(0).get('location').valueChanges
                            .pipe(
                                startWith<string | Location>(''),
                                map(value => typeof value === 'string' ? value : (value as Location).name),
                                map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                            );
                    }
                    
                    // add locationAllowance to the base location of the requester
                    const requesterLocation = this.advanceRequest.requester.userLocations[0];
                    const location = this._filter(requesterLocation.location.name)[0];
                    requesterLocation.location.locationAllowance = location.locationAllowance;
                }
            },
            err => {
            },
            () => {
            }
        )
    }

    getLocationTypeForLodgingExpense(index) {

        let locationType = '-';
        const lodgingExpense = this.leRequestAccommodations.value;
        if (lodgingExpense && lodgingExpense[index] && lodgingExpense[index].location) {
            const site = lodgingExpense[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType ?
                site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }

    getLodgingAllowanceForElement(index) {
        let lodgingAllowance = '';

        const lodgingExpense = this.leRequestAccommodations.value;
        if (lodgingExpense && lodgingExpense[index] && lodgingExpense[index].location) {
            const site = lodgingExpense[index].location;
            lodgingAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : '' : '';
        }
        return lodgingAllowance;
    }

    getMIEAllowanceForElement(index) {
        let mieAllowance = '';

        const mieExpenses = this.mieRequestAccommodations.value;
        if (mieExpenses && mieExpenses[index] && mieExpenses[index].location) {
            const site = mieExpenses[index].location;
            mieAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.mieAllowance : '' : '';
        }
        return mieAllowance;
    }

    getLocationTypeForMIEExpense(index) {

        let locationType = '-';
        const mieExpense = this.mieRequestAccommodations.value;
        if (mieExpense && mieExpense[index] && mieExpense[index].location) {
            const site = mieExpense[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType ?
                site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }

    public checkError = (controlName: string, errorName: string) => {
        return this.expenseForm.controls[controlName].hasError(errorName);
    };

    public checkREError = (index: number, controlName: string, errorName: string) => {
        return (this.requestExpenses.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };

    public checkLEError = (index: number, controlName: string, errorName: string) => {
        return (this.leRequestAccommodations.controls && this.leRequestAccommodations.controls[index])
            ? (this.leRequestAccommodations.controls[index] as FormGroup).controls[controlName].hasError(errorName)
            : true;
    };

    public checkMIEError = (index: number, controlName: string, errorName: string) => {
        return (this.mieRequestAccommodations.controls && this.mieRequestAccommodations.controls[index])
            ? (this.mieRequestAccommodations.controls[index] as FormGroup).controls[controlName].hasError(errorName)
            : true;
    };

    public checkMealsError = (index: number, controlName: string, errorName: string) => {
        return (this.mealsProvidedFormArray.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };

    get totalLodgingDays() {
        let totalLodgingDays = 0;
        
        this.leRequestAccommodations.value.forEach(function (lodgingExpense) {
            if (lodgingExpense.numberOfDays) {
                totalLodgingDays += lodgingExpense.numberOfDays;
            }
        });
        return totalLodgingDays;
    }
    advanceFormAction(button) {
        const buttonName = button.name;
        const endDate = new Date(this.expenseForm.get('startDate').value);
        endDate.setDate(endDate.getDate() + this.totalLodgingDays);
        this.expenseForm.get('endDate').setValue(endDate);

        this.expenseForm.value.requestLodgings = this.expenseForm.value.leRequestAccommodations.map(le => {
            le.arrivalDate = le.startDate;
            le.departureDate = le.endDate;
            delete le.startDate;
            delete le.endDate;
            return le;
        });
        this.expenseForm.value.requestAccommodations = this.expenseForm.value.mieRequestAccommodations;
        this.expenseForm.value.requestMeals = this.expenseForm.value.requestMeal;

        // this.mieRequestAccommodations.value.concat(this.leRequestAccommodations.value);
        delete this.expenseForm.value.mieRequestAccommodations;
        delete this.expenseForm.value.leRequestAccommodations;
        delete this.expenseForm.value.requestMeal;
        
        // link new expense to an advance requests
        const advanceRequestToBeSaved = this.expenseForm.value;
        advanceRequestToBeSaved.parentRequestId = this.advanceRequestId;

        if (this.expenseId) {
            advanceRequestToBeSaved.id = this.expenseId;
            advanceRequestToBeSaved.createdDate = this.advanceRequest.createdDate;
            advanceRequestToBeSaved.modifiedDate = this.advanceRequest.modifiedDate;
            advanceRequestToBeSaved.createdBy = this.advanceRequest.createdBy;
            advanceRequestToBeSaved.modifiedBy = this.advanceRequest.modifiedBy;
            advanceRequestToBeSaved.isActive = this.advanceRequest.isActive;
            advanceRequestToBeSaved.rowGuid = this.advanceRequest.rowGuid;
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.mieRequestAccommodations) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.mieRequestAccommodations[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.requestLodging) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.requestLodging[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.requestExpenses) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.requestExpenses[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
        }

        // console.log('############3', advanceRequestToBeSaved);

        this.advanceRequestService.createExpense(advanceRequestToBeSaved, buttonName,
            this.expenseId ? this.expenseId : '', null).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.alertService.success(resp.message);
                    if (this.checkValidationRequired(button)) {
                        this.router.navigateByUrl('/expense/view/' + resp.model + '/forAdvance/' + this.advanceRequestId);
                    }
                } else {
                    this.alertService.error(resp.message)
                }
            }, err => {
                this.alertService.error('Error creating Expense. Please try again later.')
            }
        )
    }

    removeRow(rowType, rowIndex) {
        switch (rowType) {
            case 'MIE':
                this.mieDataSource.data.splice(rowIndex, 1);
                this.mieDataSource.data = this.mieDataSource.data.slice();
                this.mieRequestAccommodations.removeAt(rowIndex);

                break;
            case 'otherExpenses':
                this.oeDataSource.data.splice(rowIndex, 1);
                this.oeDataSource.data = this.oeDataSource.data.slice();
                this.requestExpenses.removeAt(rowIndex);

                break;
            case 'lodging':
                this.leDataSource.data.splice(rowIndex, 1);
                this.leDataSource.data = this.leDataSource.data.slice();
                this.leRequestAccommodations.removeAt(rowIndex);
                
                break;
        }
    }

    checkValidationRequired(button) {
        if (button.options && button.options.Tags) {
            return button.options.Tags.indexOf('RequiresValidation') > -1;
        }
        return false
    }

    calculateLodgingAmount(index) {
        const lodgingExpenses = this.leRequestAccommodations.value;
        let numberOfNights = lodgingExpenses[index].numberOfNights;
        let cost = lodgingExpenses[index].cost;

        return numberOfNights * cost;
    }

    calculateMIEAmount(index) {
        const mieExpenses = this.mieRequestAccommodations.value;
        let mieAllowance = 0;
        let numberOfDays = 0;
        if (mieExpenses && mieExpenses[index] && mieExpenses[index].location) {
            const site = mieExpenses[index].location;
            mieAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.mieAllowance : '' : '';
            numberOfDays = mieExpenses[index].numberOfDays;
        }
        return mieAllowance * numberOfDays;
    }
    
    getMealsProvidedAmount(index) {
        return this.mealsProvidedFormArray.at(index).get('requestMealType').value.amount || 0;
    }

    lodgingAttachmentFileChanged(index, files: FileList) {
        if (files.length === 0) return;
        const fileToUpload = files.item(0);
        this.leSelectedfileLabels[index] = fileToUpload.name;

        const fileReader: FileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const encodedFile = event.target.result;

            this.leRequestAccommodations.at(index).patchValue({
                attachment: encodedFile
            });
        };

        // start reading
        fileReader.readAsDataURL(fileToUpload);
    }

    // type: OE, LE
    fileLabelOf(index, type) {
        let fileLabels;
        if (type == 'LE') { 
            fileLabels = this.leSelectedfileLabels;
        } else {
            fileLabels = this.oeSelectedfileLabels;
        }

        // truncate long file names
        const fileLabel = fileLabels[index]
            ? fileLabels[index].substring(0,10)
            : 'Empty';

        const len = fileLabels[index] ? fileLabels[index].length : 0;
        return fileLabel + (len > 10 ? '...' : '');
    }

    otherExpenseAttachmentFileChanged(index, files: FileList) {
        if (files.length === 0) return;
        const fileToUpload = files.item(0);
        this.oeSelectedfileLabels[index] = fileToUpload.name;

        const fileReader: FileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const encodedFile = event.target.result;

            this.requestExpenses.at(index).patchValue({
                attachment: encodedFile
            });
        };

        // start reading
        fileReader.readAsDataURL(fileToUpload);
    }

    calculateMealsProvided(index) {
        const mealsExp = this.mealsProvidedFormArray.value;
        if (mealsExp && mealsExp[index] && mealsExp[index].requestMealType) {
            return mealsExp[index].requestMealType.amount * mealsExp[index].numberOfDays;
        }
        return 0;
    }

    calculateTotalMealsProvided() {
        const mealExpenses = this.mealsProvidedFormArray.value;
        let sum = 0;
        mealExpenses.forEach(expense => {
            if (expense.requestMealType) {
                sum += expense.requestMealType.amount * expense.numberOfDays;
            }
        });
        return sum;
    }

    calculateTotalOtherExpense() {
        let totalAmount = 0;

        if (this.requestExpenses) {
            for (let i = 0; i < this.requestExpenses.value.length; i++) {
                totalAmount += this.requestExpenses.value[i].cost;
            }
        }
        return totalAmount;
    }

    calculateSubTotalMIEExpense() {
        let totalAmount = 0;

        if (this.mieRequestAccommodations) {
            for (let i = 0; i < this.mieRequestAccommodations.value.length; i++) {
                if (this.mieRequestAccommodations.value[i].location && this.mieRequestAccommodations.value[i].location.locationAllowance) {
                    totalAmount += this.mieRequestAccommodations.value[i].numberOfDays *
                        this.mieRequestAccommodations.value[i].location.locationAllowance.locationAllowanceType.mieAllowance;
                }
            }
        }
        return totalAmount * 1;
    }

    calculateTotalMIEExpenseForAdvance(mieRequestAccommodationModels: RequestAccommodationsModel[]) {
        let totalAmount = 0;

        // skip the first and last one
        if (mieRequestAccommodationModels) {
            for (let i = 0; i < mieRequestAccommodationModels.length; i++) {
                if (mieRequestAccommodationModels[i].location && mieRequestAccommodationModels[i].location.locationAllowance) {
                    totalAmount += mieRequestAccommodationModels[i].numberOfDays *
                        mieRequestAccommodationModels[i].location.locationAllowance.locationAllowanceType.mieAllowance;
                }
            }
        }
        return totalAmount * .75;
    }

    calculateTotalMIEExpense() {
        return this.calculateSubTotalMIEExpense() - this.calculateTotalMealsProvided();
    }

    calculateTotalLodgingExpense() {
        let totalAmount = 0;

        if (this.leRequestAccommodations) {
            for (let i = 0; i < this.leRequestAccommodations.value.length; i++) {
                if (this.leRequestAccommodations.value[i].location && this.leRequestAccommodations.value[i].location.locationAllowance) {
                    totalAmount += this.leRequestAccommodations.value[i].numberOfNights *
                        this.leRequestAccommodations.value[i].cost;
                }
            }
        }
        return totalAmount;
    }

    // for the expense form
    calculateTotalExpense() {
        return (this.calculateTotalLodgingExpense() + this.calculateTotalMIEExpense() + this.calculateTotalOtherExpense());
    }

    // the previous total advance request, calculated only once at the beginning
    calculateTotalAdvance() {
        // TotalMIEExpenseForAdvance is already calculated when populating MIE table
        let sum = this.calculateTotalLodgingExpense() + this.calculateTotalOtherExpense();

        // calculate the lodging expense of the advance request
        const lodgingExpense = this.advanceRequest.requestAccommodations;
        this.advanceRequest.requestAccommodations.forEach((reqAcc, index) => {

            // add only Lodging expenses
            if (reqAcc.accommodationType && reqAcc.accommodationType.code
                && reqAcc.accommodationType.code == Constants.LODGING) {

                let lodgingAllowance = 0;
                if (lodgingExpense && lodgingExpense[index] && lodgingExpense[index].location) {
                    const site = lodgingExpense[index].location;
                    lodgingAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : 0 : 0;
                }

                sum += lodgingAllowance * reqAcc.numberOfDays;
            }

        });

        return sum;
    }

    calculateAmountOwed() {
        return this.calculateTotalExpense() - this.previouslyRequestedAdvance;
    }
}
