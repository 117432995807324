<mat-card class="form-container-card no-padding" *ngIf="selectedEvent">
    <div class="header hide-on-print" style="text-align: right">
        <button class="print-button" [useExistingCss]="true" printSectionId="advanceRequestView" ngxPrint>
            <mat-icon>print</mat-icon>
        </button>

        <span *ngIf="eventStatus === eventStatuses.APPROVED">
            <button *ngFor="let button of eventButtons" (click)="eventRequestAction(button)"
                [ngClass]="button.styleClass ? button.styleClass : 'Default'" class="primary-color button-margin-left"
                mat-raised-button>{{button.name}}</button>
        </span>
        <button *ngIf="eventStatus === eventStatuses.REQUESTED" (click)="eventEdit()"
            class="button-margin-left Primary black-color" mat-stroked-button>Edit</button>
        <button *ngIf="eventStatus === eventStatuses.REQUESTED" (click)="addVenues()"
            class="button-margin-left Primary black-color" mat-stroked-button>Add Venue</button>

    </div>

    <div fxLayout="column" style="padding:20px">
        <div fxLayout="row">
            <span class="fa-stack fa-3x" style="margin: 10px">
                <i class="fa fa-calendar-o fa-stack-2x"></i>
                <strong
                    class="fa-stack-1x calendar-text">{{(selectedEvent.startDate ? formatDate(selectedEvent.startDate) : '-') + ' - '}}</strong>
                <strong
                    class="fa-stack-1x calendar-text" style="line-height: 130px;">{{(selectedEvent.endDate ? formatDate(selectedEvent.endDate) : '-')}}</strong>
            </span>
            <div fxLayout="column">
                <label
                    style="font-size: 20px"><b>{{ selectedEvent.eventName ? selectedEvent.eventName : '-'}}</b></label>
                <label><b>Event Type:</b>{{selectedEvent.eventType ? selectedEvent.eventType : '-'}}</label>
                <label><b>Venue:</b> {{selectedEvent.venue ? selectedEvent.venue : '-'}}</label>
                <label><b>Event Owner:</b> {{selectedEvent.eventRequester ? selectedEvent.eventRequester : '-'}}</label>
                <label><b>Host Agency:</b>
                    {{selectedEvent.hostInstitution ? selectedEvent.hostInstitution : '-'}}</label>
                        <label>
                            <b>
                                Event Summary: 
                            </b>
                            {{selectedEvent.eventSummary ? selectedEvent.eventSummary : '-'}}
                        </label>
            </div>
        </div>

    </div>
    <mat-tab-group>
        <mat-tab label="Summary">
            <div style="background-color: whitesmoke !important; padding: 15px;">               

                <h4 class="section-label">Event Detail</h4>

                <table class="mat-table detail-container" id="detail-table" style="background-color: whitesmoke !important;">
                    <tr class="mat-row">
                        <td class="mat-cell" style="width:150px">
                            <b>Event Type</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.eventType ? selectedEvent.eventType : '-'}}
                        </td>

                        <td class="mat-cell" style="width:150px"> <b>Project</b>
                        </td>

                        <td class="mat-cell" style="width:200px">{{selectedEvent.project ? selectedEvent.project : '-'}}
                        </td>

                    </tr>
                    <tr class="mat-row">

                        <td class="mat-cell" style="width:150px">
                            <b>Approver</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.eventApprover ? selectedEvent.eventApprover : '-'}}
                        </td>
                        <td class="mat-cell" style="width:150px"> <b>Activity</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.workPlanActivity ? selectedEvent.workPlanActivity : '-'}}
                        </td>
                    </tr>
                    <tr class="mat-row">
                        <td class="mat-cell" style="width:150px">
                            <b>City</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.city ? selectedEvent.city : '-'}}
                        </td>

                        <td class="mat-cell" style="width:150px"> <b>Venue</b>
                        </td>

                        <td class="mat-cell" style="width:200px">{{selectedEvent.venue ? selectedEvent.venue : '-'}}
                        </td>
                    </tr>


                </table>

                <h4 class="section-label">Financial Information</h4>

                <table class="mat-table detail-container" id="financial-info" style="background-color: whitesmoke !important;">
                    <tr class="mat-row">
                        <td class="mat-cell" style="width:150px">
                            <b>Refreshment Provided</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.refreshmentProvided ? selectedEvent.refreshmentProvided : '-'}}
                        </td>

                        <td class="mat-cell" style="width:150px"> <b>M&IE</b>
                        </td>

                        <td class="mat-cell" style="width:200px">150/200
                        </td>

                    </tr>
                    <tr class="mat-row">

                        <td class="mat-cell" style="width:150px">
                            <b>Breakfast Provided</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.breakfastProvided ? selectedEvent.breakfastProvided : '-'}}
                        </td>
                        <td class="mat-cell" style="width:150px"> <b>Lodging</b>
                        </td>

                        <td class="mat-cell" style="width:200px">200/250
                        </td>
                    </tr>
                    <tr class="mat-row">


                        <td class="mat-cell" style="width:150px"> <b>Lunch Provided</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.lunchProvided ? selectedEvent.lunchProvided : '-'}}
                        </td>
                        <td class="mat-cell" style="width:150px">
                            <b>Transportation</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            185
                        </td>
                    </tr>
                    <tr class="mat-row">


                        <td class="mat-cell" style="width:150px"> <b>Dinner Provided</b>
                        </td>

                        <td class="mat-cell" style="width:200px">
                            {{selectedEvent.dinnerProvided ? selectedEvent.dinnerProvided : '-'}}
                        </td>

                    </tr>


                </table>

                <h4 class="section-label">Event Description</h4>
                <p class="detail-container" style="color: grey">
                    {{selectedEvent.eventDescription ? selectedEvent.eventDescription : '-'}}
                </p>

            </div>
            <div style="background-color: whitesmoke !important; padding: 15px;" fxLayout="column">
                <div fxLayout="column">
                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                        <div fxFlex="50" fxLayout="column">
                            <b class="section-label" style="display: block !important;">Trainees</b>
                            <table class="pr-10" style="background-color: whitesmoke !important;">
                                <tr class="title-row">
                                    <td class="pt-10 pb-10">
                                        <b>By institution</b>
                                    </td>
                                </tr>
                                <!-- <ng-container
                                    *ngFor="let item of trainerTraineeForm.value.institutionTrainee; let i=index">
                                    <tr class="mat-row" *ngIf="item['institutionTraineeType'] != null">
                                        <td class="mat-cell">
                                            <span>{{ item['institutionTraineeType'] != null ? getOptionName(item['institutionTraineeType'], byInstitutionTrainee) : ''}}</span>
                                        </td>
                                        <td class="mat-cell">
                                            <span>{{item['numberOfInstitutionTrainee'] != null ? item['numberOfInstitutionTrainee']: 0 | number}}</span>
                                        </td>
                                    </tr>
                                    <mat-divider class="mat-table-divider"></mat-divider>
                                </ng-container> -->
                                <tr class="title-row">
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>By Job Classification</b>
                                    </td>
                                </tr>
                                <!-- <ng-container
                                    *ngFor="let item of trainerTraineeForm.value.jobTrainee; let i=index">
                                    <tr class="mat-row" *ngIf="item['jobTraineeType'] != null">
                                        <td class="mat-cell">
                                            <span>{{ item['jobTraineeType'] != null? getOptionName(item['jobTraineeType'], byJobClassificationTrainee) : ''}}</span>
                                        </td>
                                        <td class="mat-cell">
                                            <span>{{item['numberOfJobTrainee'] != null? item['numberOfJobTrainee']: 0 | number}}</span>
                                        </td>
                                    </tr>
                                    <mat-divider class="mat-table-divider"></mat-divider>
                                </ng-container> -->
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="title-row">
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>Total</b>
                                    </td>
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>{{0 | number}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div fxFlex="50" fxLayout="column">
                            <b class="section-label" style="display: block !important;">Non-DHA:
                                Coordinators/Trainers </b>
                            <table class="mat-table pr-10" style="background-color: whitesmoke !important;">
                                <tr class="title-row">
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>By institution</b>
                                    </td>
                                </tr>
                                <!-- <ng-container
                                    *ngFor="let item of trainerTraineeForm.value.institutionTrainer; let i=index">
                                    <tr class="mat-row" *ngIf="item['institutionTrainerType'] != null">
                                        <td class="mat-cell">
                                            <span>{{ item['institutionTrainerType'] != null? getOptionName(item['institutionTrainerType'], byInstitution) : ''}}</span>
                                        </td>
                                        <td class="mat-cell">
                                            <span>{{item['numberOfInstitutionTrainer'] != null? item['numberOfInstitutionTrainer']: 0 | number}}</span>
                                        </td>
                                    </tr>
                                    <mat-divider class="mat-table-divider"></mat-divider>
                                </ng-container> -->
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="title-row">
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>By Job Classification</b>
                                    </td>
                                </tr>
                                <!-- <ng-container
                                    *ngFor="let item of trainerTraineeForm.value.jobTrainer; let i=index">
                                    <tr class="mat-row" *ngIf="item['jobTrainerType'] != null">
                                        <td class="mat-cell">
                                            <span>{{ item['jobTrainerType'] != null ? getOptionName(item['jobTrainerType'], byJobClassification) : ''}}</span>
                                        </td>
                                        <td class="mat-cell">
                                            <span>{{item['numberOfJobTrainer'] != null ? item['numberOfJobTrainer']: 0 | number}}</span>
                                        </td>
                                    </tr>
                                    <mat-divider class="mat-table-divider"></mat-divider>
                                </ng-container> -->
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="title-row  pt-10 pb-10">
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>Total</b>
                                    </td>
                                    <td class="mat-cell pt-10 pb-10">
                                        <b>{{10 | number}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <mat-divider class="mat-table-divider"></mat-divider>
                    <table class="mat-table pr-10 pt-10 pb-10" style="background-color: whitesmoke !important;">
                        <tr class="mat-row">
                            <td class="mat-cell" style="width: 96%; font-size: larger;">
                                <b>Total # of attendees</b>
                            </td>
                            <td class="mat-cell" style="font-size: larger;">
                                <b>{{10 | number}}</b>
                            </td>
                        </tr>
                    </table>

                </div>
                <mat-divider class="mat-table-divider"></mat-divider>
                <mat-divider class="mat-table-divider" style="padding-bottom: 40px;"></mat-divider>
                <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                    <div fxFlex="50" fxLayout="column">
                        <table class="mat-table pr-10" style="background-color: whitesmoke !important;">
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Venue City </span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{'Adama'}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Number of unique sessions needed to complete</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Number of actual training days </span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Total number of hotel days (excluding training days)</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                        </table>
                    </div>
                    <div fxFlex="50" fxLayout="column">
                        <table class="mat-table pr-10" style="background-color: whitesmoke !important;">
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span># of Hotel days to budget </span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Estimated total number of Travel days (one way)</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Total number of training days inc. travel</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Number of air travellers (estimated)</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{0 | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Budget">
            <div style="background-color: whitesmoke !important; padding: 15px;">
                <div class="inset-container" fxLayout="column">
                    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column">
                        <div fxFlex="50" fxLayout="column">
                            <b class="section-label" style="display: block !important;">Total Non-DHA:
                                Coordinators/Trainers cost </b>
                            <table style="background-color: white !important;">
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Per diem - Trainer</span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTrainerCost.perDiemFlat | number}}</span>
                                    </td>
            
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Hotel for Trainer</span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTrainerCost.perDiemHotel | number}}</span>
                                    </td>
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
            
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Transport </span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTrainerCost.transport | number}}</span>
                                    </td>
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="title-row">
                                    <td class="mat-cell total-label">
                                        <b>Total</b>
                                    </td>
                                    <td class="mat-cell total-label">
                                        <b>{{totalTrainerCost.total | number}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div fxFlex="50" fxLayout="column">
                            <b class="section-label" style="display: block !important;">Total Trainee
                                Cost</b>
                            <table class="mat-table pr-10" style="background-color: white !important;">
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Per diem - Trainee</span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTraineeCost.perDiemFlat | number}}</span>
                                    </td>
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Hotel for Trainee</span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTraineeCost.perDiemHotel | number}}</span>
                                    </td>
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <span>Transport </span>
                                    </td>
                                    <td class="mat-cell">
                                        <span>{{totalTraineeCost.transport | number}}</span>
                                    </td>
                                </tr>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <mat-divider class="mat-table-divider"></mat-divider>
                                <tr class="title-row">
                                    <td class="mat-cell total-label">
                                        <b>Total</b>
                                    </td>
                                    <td class="mat-cell total-label">
                                        <b>{{totalTraineeCost.total | number}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div fxFlex fxLayout="column">
                        <b class="section-label" style="display: block !important;">Venue/Other</b>
                        <table class="pr-10" style="background-color: white !important;">
                            <tr class="mat-row">
                                <td class="mat-cell" style="width: 95%;">
                                    <span>Venue</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{totalOtherCost.venue | number}}</span>
                                </td>
            
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Stationary</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{totalOtherCost.stationary | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
            
                            <tr class="mat-row">
                                <td class="mat-cell">
                                    <span>Refreshment</span>
                                </td>
                                <td class="mat-cell">
                                    <span>{{totalOtherCost.refreshment | number}}</span>
                                </td>
                            </tr>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <mat-divider class="mat-table-divider"></mat-divider>
                            <tr class="title-row">
                                <td class="mat-cell total-label" style="width: 86%;">
                                    <span>Total</span>
                                </td>
                                <td class="mat-cell inline">
                                    <b>{{totalOtherCost.total | number}}</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table class="mat-table final-total"
                        style="background-color: white !important; padding-bottom: 10px; padding-top: 10px;">
                        <tr class="mat-row">
                            <td class="mat-cell total-label final-total-labels">
                                <b>Total Cost</b>
                            </td>
                            <td class="mat-cell total-label final-total-labels reverse-flex">
                                <b>{{totalOtherCost.total + totalTraineeCost.total + totalTrainerCost.total | number}}</b>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </mat-tab>
        <!-- <mat-tab label="Documents">
            <div style="background-color: whitesmoke; padding: 15px">

                <table class="mat-table" style="background-color: whitesmoke">
                    <thead>
                        <tr class="mat-row">
                            <th style="width: 100px">No.</th>
                            <th style="width: 200px">Attachment</th>
                            <th style="width: 200px">File Name</th>
                            <th style="width: 200px">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="mat-row">
                            <td class="mat-cell" style="width: 100px">1</td>
                            <td class="mat-cell" style="width:200px">Attendance</td>
                            <td style="font-style: italic">
                                attendance.pdf
                            </td>
                            <td class="mat-cell" style="width:200px">
                                <span class="action-buttons">
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-eye text-navy"></i></button>
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-download text-navy"></i></button>
                                </span>
                            </td>
                        </tr>

                        <tr class="mat-row">
                            <td class="mat-cell" style="width:200px">2</td>
                            <td class="mat-cell" style="width:200px">Agenda</td>
                            <td style="font-style: italic">
                                agenda.pdf
                            </td>
                            <td class="mat-cell" style="width:200px">
                                <span class="action-buttons">
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-eye text-navy"></i></button>
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-download text-navy"></i></button>
                                </span>

                            </td>
                        </tr>
                        <tr class="mat-row">
                            <td class="mat-cell" style="width:200px">3</td>
                            <td class="mat-cell" style="width:200px">Proposal</td>
                            <td style="font-style: italic">
                                proposal.pdf
                            </td>
                            <td class="mat-cell" style="width:200px">
                                <span class="action-buttons">
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-eye text-navy"></i></button>
                                    <button class="btn btn-primary btn-outline" type="button" aria-hidden="false"><i
                                            class="fa fa-download text-navy"></i></button>
                                </span>

                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </mat-tab>
        <mat-tab label="Photos">

        </mat-tab> -->
    </mat-tab-group>
</mat-card>
