import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserData} from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private loginURL = environment.baseUrl + '/Account/Login';
  constructor(private http: HttpClient) { }

  login(loginData) {
    return this.http.post<UserData>(this.loginURL, loginData, {});
  }}
