
<mat-sidenav-container [hasBackdrop]="false">
<div class="relative">
    <mat-sidenav [opened]="showComment" mode="push" position="end">
        <div class="comment-card" *ngFor="let comment of comments">
            <div style="display: inline-flex">
                <div style="margin: 12px" class="circle accent-color">
                    <span class="initials">{{getUserInitial(comment.commenterName)}}</span>
                </div>
                <div style="margin-top: 12px">
                    <label><b>{{comment.commenterName}}</b></label>
                    <br/>
                    <label>{{formatDateTime(comment.date)}}</label>
                </div>
            </div>
            <div style="margin: 12px; margin-top: 3px">
                <span style="font-style: italic">Reasons for {{comment.action}}ing: &nbsp;</span>{{comment.comment}}
            </div>
            <mat-divider style="margin-bottom: 18px"></mat-divider>
        </div>
        <div style="width: 250px; padding: 30px" *ngIf="comments.length === 0">No comments for this request.</div>
    </mat-sidenav>
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
        <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90"
             >

            <mat-card class="form-container-card no-padding" *ngIf="advanceRequest">
                <div class="header hide-on-print" style="text-align: right">
                    <button *ngIf="selectedTab === 0" class="print-button"
                            [useExistingCss]="true"
                            printSectionId="advanceRequestView"
                            ngxPrint>
                        <mat-icon>print</mat-icon>
                    </button>
                    <button *ngIf="selectedTab === 1" class="print-button"
                            [useExistingCss]="true"
                            printSectionId="scope-of-work-view"
                            ngxPrint>
                        <mat-icon>print</mat-icon>
                    </button>
                    <button class="print-button" style="right: 40px;" (click)="showComment = !showComment"
                            >
                        <mat-icon [matBadge]="comments.length" matBadgeColor="accent">comment</mat-icon>
                    </button>
                    <button *ngFor="let button of buttonNames"
                            (click)="advanceRequestAction(button)"
                            [ngClass]="(button.options && button.options.style) ? button.options.style : 'Default'"
                            class="primary-color button-margin-left" mat-raised-button>{{button.name}}</button>

                    <button (click)="navigateToCreateExpense()"  
                        *ngIf="this.advanceRequest && this.advanceRequest.status.name == 'Checked'"
                        class="primary-color button-margin-left" mat-raised-button>
                        Expense
                    </button>
                    
                </div>
                <mat-tab-group [selectedIndex]="selectedTab"
                               (selectedIndexChange)="selectedTab = $event">
                    <mat-tab label="Advance Details">

                <div style="padding: 15px" id="advanceRequestView">
                    <table class="mat-table" style="background-color: whitesmoke !important;" id="header-table">
                            <tr class="mat-row">
                                <td class="mat-cell" style="width:200px">
                                    <b>Name of Traveler</b>
                                </td>

                                <td class="mat-cell" style="width:200px">
                                    {{requesterFullName}}
                                </td>

                                <td class="mat-cell" style="width:200px"> <b>Dates of Travel</b>
                                </td>

                                <td class="mat-cell" style="width:200px">{{getTravelDateRange()}}
                                </td>
                            </tr>

                            <tr class="mat-row">
                                <td class="mat-cell" style="width:200px">
                                    <b>Base of Traveler</b>
                                </td>

                                <td class="mat-cell" style="width:200px">
                                    {{requesterBaseLocation}}
                                </td>

                                <td class="mat-cell" style="width:200px"> <b>Advance For DHA</b>
                                </td>

                                <td class="mat-cell" style="width:200px">X
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell" style="width:200px">
                                    <b>Position of Traveler</b>
                                </td>

                                <td class="mat-cell" style="width:200px">
                                    {{requesterJobTitle}}
                                </td>

                                <td class="mat-cell" style="width:200px">
                                    <b>Budget Activity Number</b>
                                </td>

                                <td class="mat-cell" style="width:200px">
                                    {{getAllBudgetActivityNumbers()}}
                                </td>
                            </tr>

                        <tr class="mat-row">
                            <td class="mat-cell" style="width:200px">
                                <b>Purpose of Travel</b>
                            </td>
                            <td colspan="3" class="mat-cell">
                                {{advanceRequest.description}}
                            </td>
                        </tr>

                    </table>
                    <div fxLayout="column" fxLayoutAlign="center stretch"
                         class="advance-request-view">
                        <div fxLayout="column">
                            <h4 class="section-label">Lodging Advances</h4>
                            <table mat-table [dataSource]="leDataSource" class="mat-elevation-z2">

                                <ng-container matColumnDef="city">
                                    <th mat-header-cell *matHeaderCellDef> City</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.location? element.location.name : ''}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef> Type</th>
                                    <td mat-cell *matCellDef="let element; let index = index">
                                        <span>{{getLocationTypeForLodgingExpense(index)}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="lodgingPerDay">
                                    <th mat-header-cell *matHeaderCellDef> Lodging Per Day</th>
                                    <td mat-cell *matCellDef="let element; let index = index">
                                        <span>{{getLodgingAllowanceForElement(index)}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef><b>Total Lodging Advance(A)</b></td>
                                </ng-container>

                                <ng-container matColumnDef="numberOfNights">
                                    <th mat-header-cell *matHeaderCellDef> No. Nights</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.numberOfDays}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef> Amount</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{calculateLodgingAmount(element)}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef><b>{{calculateTotalLodgingExpense()}}</b></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="lodgingExpenseDisplayedColumns"></tr>
                                <tr mat-footer-row *matFooterRowDef="lodgingExpenseDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: lodgingExpenseDisplayedColumns;"></tr>

                            </table>

                        </div>
                        <div fxLayout="column">
                            <h4 class="section-label">M & IE Advances</h4>
                            <table mat-table [dataSource]="mieDataSource" class="mat-elevation-z2">

                                <ng-container matColumnDef="city">
                                    <th mat-header-cell *matHeaderCellDef> City</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.location? element.location.name : ''}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>

                                </ng-container>
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef> Type</th>
                                    <td mat-cell *matCellDef="let element; let index = index">
                                        <span>{{getLocationTypeForMIEExpense(index)}}</span>
                                    <td mat-footer-cell *matFooterCellDef></td>

                                </ng-container>
                                <ng-container matColumnDef="MIEPerDay">
                                    <th mat-header-cell *matHeaderCellDef> M & IE Per Day</th>
                                    <td mat-cell *matCellDef="let element; let index = index">
                                        <span>{{getMIEAllowanceForElement(index)}}</span>
                                    <td mat-footer-cell *matFooterCellDef><b>Total M & IE Advance (B) 75%</b></td>

                                </ng-container>

                                <ng-container matColumnDef="numberOfDays">
                                    <th mat-header-cell *matHeaderCellDef> No. Days</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.numberOfDays}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>

                                </ng-container>

                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef> Amount</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{calculateMIEAmount(element)}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef><b>{{calculateTotalMIEExpense()}}</b></td>

                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="MIEExpenseDisplayedColumns"></tr>
                                <tr mat-footer-row *matFooterRowDef="MIEExpenseDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: MIEExpenseDisplayedColumns;"></tr>

                            </table>

                        </div>

                        <div fxLayout="column">
                                <h4 class="section-label">Other Expense</h4>
                                <table mat-table [dataSource]="oeDataSource" class="mat-elevation-z2">

                                    <ng-container matColumnDef="purpose">
                                        <th mat-header-cell *matHeaderCellDef> Purpose</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.purpose.name}}</span>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef><b>Total Other Advance (C) </b></td>
                                    </ng-container>

                                    <ng-container matColumnDef="otherAmount">
                                        <th mat-header-cell *matHeaderCellDef> Amount</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.cost}}</span>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef><b>{{calculateTotalOtherExpense()}}</b></td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="otherExpenseDisplayedColumns"></tr>
                                    <tr mat-footer-row *matFooterRowDef="otherExpenseDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: otherExpenseDisplayedColumns;"></tr>

                                </table>
                        </div>
                        <div fxLayout="column">
                                <h4 class="section-label">Grand Total</h4>
                                <table class="mat-table" class="mat-elevation-z2">

                                    <tr class="mat-row">
                                        <td class="mat-cell">
                                            <b>Total  Advance  = A +  (B X 75%) + C</b>
                                        </td>

                                        <td class="mat-cell">
                                            <b>{{calculateTotalExpense()}}</b>
                                        </td>
                                    </tr>
                                </table>
                        </div>
                        <div fxLayout="column" style="padding-bottom: 30px;">
                            <h4 class="section-label"></h4>

                            <div fxLayout="row" style="padding-top: 30px;">
                                <div fxLayout="column" fxFlex="30">
                                    <h3>Traveler</h3>
                                    <label><b>Name: </b>&nbsp; {{requesterFullName}}</label>
                                    <div style="display: inherit">
                                        <label style="width: 18%;"><b>Signature: </b></label>
                                        <label class="signature-text">{{requesterFullName}}</label>
                                    </div>
                                    <label><b>Title: </b>&nbsp; {{requesterJobTitle}}</label>
                                    <label><b>Date: </b>&nbsp; {{formatDate(advanceRequest.createdDate)}}</label>
                                </div>
                                <div fxLayout="column" fxFlex="30">
                                    <div *ngIf="checkSupervisorSigned()">
                                        <h3>Supervisor</h3>
                                        <label><b>Name: </b>&nbsp; {{supervisorFullName}}</label>
                                        <div style="display: inherit">
                                            <label style="width: 18%;"><b>Signature: </b></label>
                                            <label class="signature-text">{{supervisorFullName}}</label>
                                        </div>
                                        <label><b>Title: </b>&nbsp; {{supervisorJobTitle}}</label>
                                        <br/>
                                        <label><b>Date: </b>&nbsp;{{approvedDate}} </label>
                                    </div>

                                </div>
                                <div fxLayout="column" fxFlex="30">
                                    <div *ngIf="checkFinanceSigned()">
                                        <h3>Finance</h3>
                                        <label><b>Name: </b>&nbsp; {{financeFullName}}</label>
                                        <div style="display: inherit">
                                            <label style="width: 18%;"><b>Signature: </b></label>
                                            <label class="signature-text">{{financeFullName}}</label>
                                        </div>
                                        <label><b>Title: </b>&nbsp; {{financeJobTitle}}</label>
                                        <br/>
                                        <label><b>Date: </b>&nbsp;{{checkedDate}} </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </mat-tab>
                    <mat-tab label="Scope of Work" class="request-detail-tabs">
                        <div style="padding: 15px" id="scope-of-work-view">
                            <div>
                                <h2 style="text-align: center">John Snow Inc</h2>
                                <h2 style="text-align: center">Digital Health Activity (DHA)</h2>
<br/>
                                <h4> Scope of work of the field trip, workshop or training</h4>
                                <br/>
                                <div style="font-size: 14px;">
                                    <label>(Please list the scope of work of the field trip workshop or training and attach it with the advance request) </label>
                                    <br/>
                                    <label style="width: 220px; display: inline-block"><b>Name:</b> &nbsp;</label><label> {{requesterFullName}}</label><br/>
                                    <label style="width: 220px; display: inline-block"><b>Title:</b> &nbsp;</label><label> {{requesterJobTitle}}</label><br/>
                                    <label style="width: 220px; display: inline-block"><b>Dates of Travel and/or workshop:</b> &nbsp;</label><label> {{getTravelDateRange()}}</label><br/>
                                    <label style="width: 220px; display: inline-block"><b>Site(s) to be visited:</b> &nbsp; </label><label>{{getSitesToBeVisited()}}</label><br/>
                                    <label style="width: 220px; display: inline-block"><b>Assigned Driver:</b> &nbsp; </label><br/>
                                </div>


                            </div>
                            <div fxLayout="column">
                                <h4 class="section-label">Project Objectives</h4>
                                <table mat-table [dataSource]="poDataSource" class="mat-elevation-z2">

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    <ng-container matColumnDef="site">
                                        <th mat-header-cell *matHeaderCellDef> Site</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.location? element.location.name : ''}}</span>
                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="product">
                                        <th mat-header-cell *matHeaderCellDef> Product</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.product.name}}</span>
                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="type">
                                        <th mat-header-cell *matHeaderCellDef> Type</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.service.name}}</span>
                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="summary">
                                        <th mat-header-cell *matHeaderCellDef> Summary</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span>{{element.description}}</span>
                                        </td>

                                    </ng-container>

                                </table>
                            </div>
                            <div fxLayout="column" style="padding-bottom: 30px;">
                                <h4 class="section-label"></h4>

                                <div fxLayout="row" style="padding-top: 30px;">
                                    <div fxLayout="column" fxFlex="30">
                                        <h3>Traveler</h3>
                                        <label><b>Name: </b>&nbsp; {{requesterFullName}}</label>
                                        <div style="display: inherit">
                                            <label style="width: 18%;"><b>Signature: </b></label>
                                            <label class="signature-text">{{requesterFullName}}</label>
                                        </div>
                                        <label><b>Title: </b>&nbsp; {{requesterJobTitle}}</label>
                                        <label><b>Date: </b>&nbsp; {{formatDate(advanceRequest.createdDate)}}</label>
                                    </div>
                                    <div fxLayout="column" fxFlex="30">
                                        <div *ngIf="checkSupervisorSigned()">
                                            <h3>Supervisor</h3>
                                            <label><b>Name: </b>&nbsp; {{supervisorFullName}}</label>
                                            <div style="display: inherit">
                                                <label style="width: 18%;"><b>Signature: </b></label>
                                                <label class="signature-text">{{supervisorFullName}}</label>
                                            </div>
                                            <label><b>Title: </b>&nbsp; {{supervisorJobTitle}}</label>
                                            <br/>
                                            <label><b>Date: </b>&nbsp;{{approvedDate}} </label>
                                        </div>

                                    </div>
                                    <div fxLayout="column" fxFlex="30">
                                        <div *ngIf="checkFinanceSigned()">
                                            <h3>Finance</h3>
                                            <label><b>Name: </b>&nbsp; {{financeFullName}}</label>
                                            <div style="display: inherit">
                                                <label style="width: 18%;"><b>Signature: </b></label>
                                                <label class="signature-text">{{financeFullName}}</label>
                                            </div>
                                            <label><b>Title: </b>&nbsp; {{financeJobTitle}}</label>
                                            <br/>
                                            <label><b>Date: </b>&nbsp;{{checkedDate}} </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>
    </div>
</div>
</mat-sidenav-container>
