<div class="relative">
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2" style="padding: 0 !important;">
        <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
            <mat-card class="form-container-card no-padding">

                <div class="header primary-color">
                    <h4 class="title-text">ADVANCE REQUEST LIST</h4>
                </div>

                <mat-tab-group>
                    <mat-tab label="My requests">
                        <div style="height: 28em; overflow: auto;" *ngIf="!loading && advanceRequestListCreatedByUser && advanceRequestListCreatedByUser.length > 0">
                            <div *ngFor="let advanceRequest of advanceRequestListCreatedByUser" style="text-align: center">
                                <app-advance-request-list-item style="text-align: initial" [advanceRequest]="advanceRequest"></app-advance-request-list-item>
                            </div>
                        </div>
                        <div style="padding-top: 15%; text-align: center; height: 28em; overflow: auto;" *ngIf="!loading && advanceRequestListCreatedByUser && advanceRequestListCreatedByUser.length === 0">
                            <label class="nothing-to-display">There are no advance requests to display at the moment.</label>
                            <label class="nothing-to-display">Click on the (+) button to create an advance request.</label>
                        </div>
                    </mat-tab>
                    <mat-tab label="Requests for Review">
                        <div style="height: 28em; overflow: auto;"
                             *ngIf="!loading && advanceRequestListToBeReviewedByUser && advanceRequestListToBeReviewedByUser.length > 0">
                            <div *ngFor="let advanceRequest of advanceRequestListToBeReviewedByUser" style="text-align: center">
                                <app-advance-request-list-item style="text-align: initial" [advanceRequest]="advanceRequest"></app-advance-request-list-item>
                            </div>
                        </div>
                        <div style="padding-top: 15%; text-align: center; height: 28em; overflow: auto;"
                             *ngIf="!loading && advanceRequestListToBeReviewedByUser && advanceRequestListToBeReviewedByUser.length === 0">
                            <label class="nothing-to-display">There are no advance requests to review at the moment.</label>
                        </div>
                    </mat-tab>
                </mat-tab-group>

            </mat-card>
        </div>

        <button mat-fab color="accent" style="position: fixed; right: 30px; bottom: 30px" (click)="createAdvanceRequest()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>