import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {LoginComponent} from './session/login/login.component';
import {SetPasswordComponent} from './session/set-password/set-password.component';
import {ProfileEditComponent} from './session/profile-edit/profile-edit.component';
import {ProfileViewComponent} from './session/profile-view/profile-view.component';
import {AdvanceFormComponent} from './advance-request/advance-request-form/advance-form.component';
import {LoggedInGuardService} from './services/logged-in-guard.service';
import {BlankComponent} from './shared/blank-component/blank.component';
import {AuthGuardService} from './services/auth-guard.service';
import {ForgotPasswordComponent} from './session/forgot-password/forgot-password.component';
import {AdvanceRequestViewComponent} from './advance-request/advance-request-view/advance-request-view.component';
import {LookupConfigurationComponent} from './settings/lookup-configuration/lookup-configuration.component';
import {AdvanceRequestListComponent} from './advance-request/advance-request-list/advance-request-list.component';
import {ChangePasswordComponent} from './settings/change-password/change-password.component';
import { ExpenseFormComponent } from './expense/expense-form/expense-form.component';
import {ExpenseViewComponent} from './expense/expense-view/expense-view.component';
import {EventViewComponent} from './event/event-view/event-view.component';
import {EventRequestComponent} from './event/event-request/event-request.component';
import {EventsComponent} from './event/events/events.component';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

export const AppRoutes: Routes = [{
    path: '', component: BlankComponent, canActivate: [LoggedInGuardService],
    children: [
        {
            path: '',
            redirectTo: 'login',
            pathMatch: 'full',
        }, {
            path: '',
            component: LoginComponent
        }, {
            path: 'login',
            component: LoginComponent
        }, {
            path: 'reset/:userId',
            component: SetPasswordComponent
        }, {
            path: 'forgotPassword',
            component: ForgotPasswordComponent
        }]
}, {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [{
        path: 'events',
        component: EventsComponent,
        children: [
            {
                path: 'request',
                component: EventRequestComponent
            }, 
            {
                path: 'edit/:id',
                component: EventRequestComponent
            }, 
            {
                path: 'edit/:venue/:id',
                component: EventRequestComponent
            }, 
            {
                path: 'view/:id',
                component: EventViewComponent
            }
        ]
    }, {
        path: 'settings/profile/edit',
        component: ProfileEditComponent
    }, {
        path: 'profile/view',
        component: ProfileViewComponent
    }, {
        path: 'expense/view/:expenseId/forAdvance/:advanceRequestId',
        component: ExpenseViewComponent
    }, {
        path: 'expense/view/:expenseId',
        component: ExpenseViewComponent
    }, {
        path: 'expense/create/:advanceRequestId',
        component: ExpenseFormComponent
    }, {
        path: 'expense/edit/:expenseId',
        component: ExpenseFormComponent
    }, {
        path: 'advanceRequest/create',
        component: AdvanceFormComponent
    }, {
        path: 'advanceRequest/edit/:id',
        component: AdvanceFormComponent
    }, {
        path: 'advanceRequest/list',
        component: AdvanceRequestListComponent
    }, {
        path: 'advanceRequest/view/:id',
        component: AdvanceRequestViewComponent
    }, {
        path: 'settings/lookupConfig',
        component: LookupConfigurationComponent
    }, {
        path: 'settings/changePassword',
        component: ChangePasswordComponent
    }]
}, {
    path: 'main',
    component: AdminLayoutComponent
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: 'authentication',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
    }, {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    }]
}, {
    path: '**',
    redirectTo: 'session/404'
}];
