import {Component, OnInit, OnDestroy, ViewChild, HostListener, Input, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../shared/menu-items/horizontal-menu-items';
import {Observable, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../models/User';
import {AccountService} from '../../services/account.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {EventEmitter} from 'events';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  @ViewChild('sidemenu', {static: true}) sidemenu;
  user$: Observable<firebase.default.User> = this.authService.user$;
  @Output() logoutClick: EventEmitter = new EventEmitter();

  url: string;
  dark: boolean;
  hideMenuForFirstTimeLogin: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  horizontal: boolean = false;
  sidebarBg: boolean = true;
  layoutDir = 'ltr';

  menuLayout           : any = 'top-menu';
  selectedSidebarImage : any = 'bg-1';
  selectedSidebarColor : any = 'sidebar-primary';
  selectedHeaderColor  : any = 'header-primary';
  collapsedClass       : any = 'side-panel-opened';
  sideNavOpened = false;
  public config: PerfectScrollbarConfigInterface = {};
  public user: User;
  constructor(private router: Router, public menuItems: MenuItems, public horizontalMenuItems: HorizontalMenuItems,
              public translate: TranslateService, private accountService: AccountService, private authService: AuthService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit(): void {

  }

  
  menuToggleFunc()
  {
    this.sideNavOpened = !this.sideNavOpened;
  }

  changeMenuLayout(value)
  {
    // console.log(value)
    if(value)
    {
      this.menuLayout = 'top-menu';
    }
    else
    {
      this.menuLayout = 'vertical-menu';
      this.menuToggleFunc();
    }
  }
  
  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }

  logout() {
    this.authService
        .logout()
        .pipe(take(1))
        .subscribe((response) => {
          this.router.navigate([`/login`]);
        });
  }
  // signOut() {
  //   this.accountService.removeData();
  //   this.router.navigateByUrl('/login');
  // }

  viewProfile() {
    this.router.navigateByUrl('profile/view')
  }

  getUserInitials() {
    const user = this.accountService.getUserData();
    return user.displayName.split(' ')[0][0] + user.displayName.split(' ')[1][0];
  }

  isActive(menuitem) {
    return window.location.href.indexOf(menuitem.state) > -1
  }
}
