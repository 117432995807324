import { Component, OnInit ,ViewEncapsulation } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AccountService} from '../../services/account.service';
import {LoginModel, UserInfo} from '../../models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../services/alert.service';
import {AuthService} from '../../services/auth.service';
import {catchError, take} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';

@Component({
   selector: 'ms-login-session',
   templateUrl: './login-component.html',
   styleUrls: ['./login-component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    public loginModel: LoginModel = new LoginModel();
    public loginForm: FormGroup;
    public returnUrl: string;
    user$: Observable<firebase.default.User> = this.authService.user$;

    constructor(
    private router: Router, private authenticationService: AuthenticationService, private accountService: AccountService,
    private route: ActivatedRoute, private fb: FormBuilder, private alertService: AlertService, private authService: AuthService

) { }


    login() {
        this.authService
            .loginViaGoogle()
            .pipe(
                take(1),
                catchError((error) => {
                    this.alertService.error('Unable to sign in at this time, please try again later.');

                    return EMPTY;
                }),
            )
            .subscribe(
                (response) => {

                    localStorage.setItem('user', JSON.stringify(response.user));
                    this.router.navigateByUrl('/events')
                }
            );
    }


    // public login() {
    //
    //     // this.spinner.show();
    //
    //     this.loginModel.rememberMe = true;
    //     this.loginModel.userInfo = new UserInfo();
    //     this.loginModel.userInfo.browser = 'Chrome';
    //     this.loginModel.userInfo.browserVersion = '81.0.4044.138';
    //     this.loginModel.userInfo.deviceType = 'Desktop';
    //     this.loginModel.userInfo.os = 'Windows';
    //     this.loginModel.userInfo.osVersion = 'windows-10';
    //     this.loginModel.username = this.loginForm.value.username;
    //     this.loginModel.password = this.loginForm.value.password;
    //     this.authenticationService.login(this.loginModel).subscribe(
    //         resp => {
    //             if (resp) {
    //                 this.accountService.storeUserData(resp);
    //                 if (resp.user.isFirstLogin) {
    //                     this.router.navigate(['/events']);
    //                 } else {
    //
    //                     this.router.navigate([this.returnUrl]);
    //                 }
    //             } else {
    //                 this.alertService.error('Incorrect username or password.')
    //             }
    //
    //         },
    //         error => {
    //             this.alertService.error('Unable to sign in at this time, please try again later.')
    //         }
    //     );
    // }
    //
    // forgotPassword() {
    //   this.router.navigateByUrl('forgotPassword');
    // }

    ngOnInit(): void {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/events';
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }
    public checkError = (controlName: string, errorName: string) => {
        return this.loginForm.controls[controlName].hasError(errorName) && this.loginForm.controls[controlName].dirty;
    };
}



