import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  timeAgoCalc(date) {
    let plural: string = '';
    const ago: string = ' ago';
    let timeSpanDesc: string;
    const dateNow = new Date();

    function makePlural(diff) {
      plural = diff > 1 ? 's' : '';
    }

    function getNumOfDaysThisMonth() {
      const month = dateNow.getMonth() + 1;
      const year = dateNow.getFullYear();
      return new Date(year, month, 0).getDate();
    }

    function isLeapYear(year) {
      return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    //Convert from UTC to Local
    let historyDate = new Date(date);
    var offset = historyDate.getTimezoneOffset() / 60;
    var hours = historyDate.getHours();
    historyDate.setHours(hours - offset);

    let elapsedTime = dateNow.getTime() - historyDate.getTime();
    let elapsedInSecs = elapsedTime / 1000;
    let elapsedInMins = elapsedInSecs / 60;
    let elapsedInHours = elapsedInMins / 60;
    let elapsedInDays = elapsedInHours / 24;

    let elapsedInMonths = elapsedInDays / 30;
    const day = isLeapYear(2011);
    let elapsedInYears = elapsedInDays / 365;

    if (elapsedInSecs < 60) {
      timeSpanDesc = 'seconds' + ago;
    } else if (elapsedInMins < 60) {
      makePlural(Math.round(elapsedInMins));
      timeSpanDesc = Math.round(elapsedInMins) + ' min' + plural + ago;
    } else if (elapsedInHours < 24) {
      makePlural(Math.round(elapsedInHours));
      timeSpanDesc = Math.round(elapsedInHours) + ' hour' + plural + ago;
    } else if (elapsedInDays < getNumOfDaysThisMonth()) {
      makePlural(Math.round(elapsedInDays));
      timeSpanDesc = Math.round(elapsedInDays) + ' day' + plural + ago;
    } else if (elapsedInMonths < 12) {
      makePlural(Math.round(elapsedInMonths));
      timeSpanDesc = Math.round(elapsedInMonths) + ' month' + plural + ago;
    } else if (elapsedInYears > 0) {
      makePlural(Math.round(elapsedInYears));
      timeSpanDesc = Math.round(elapsedInYears) + ' year' + plural + ago;
    }
    return timeSpanDesc;
  }

  getConvertedDateFormat(date) {
    // Remove the GMT section
    const historyDate = new Date(date);
    const offset = historyDate.getTimezoneOffset() / 60;
    const hours = historyDate.getHours();
    historyDate.setHours(hours - offset);
    return historyDate.toString().split(' ').slice(1, 5).join(' ');
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US")
  }
}
