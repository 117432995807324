import {Component, Input, OnInit} from '@angular/core';
import {AdvanceRequest} from '../../models/AdvanceRequest';
import {UtilsService} from '../../services/utils.service';
import {Router} from '@angular/router';
import {UserResponseModel} from '../../models/User';

@Component({
  selector: 'app-advance-request-list-item',
  templateUrl: './advance-request-list-item.component.html',
  styleUrls: ['./advance-request-list-item.component.css']
})
export class AdvanceRequestListItemComponent implements OnInit {

  @Input() advanceRequest: AdvanceRequest;
  constructor(private utilService: UtilsService, private router: Router) { }

  ngOnInit(): void {
  }

  getRequesterName(user: UserResponseModel) {
      const userProfile = user.userProfile[0];
      return (userProfile.firstName ? userProfile.firstName : '') + ' ' +
          (userProfile.middleName ? userProfile.middleName : '');
  }

  getTimeAgo() {
    return this.utilService.timeAgoCalc(this.advanceRequest.modifiedDate);
  }

  getDateRange() {
    const startDate = this.utilService.formatDate(this.advanceRequest.startDate);
    const endDate = this.utilService.formatDate(this.advanceRequest.endDate);
    return startDate + ' - ' + endDate;
  }

  getStatusCode() {
    return this.advanceRequest.status.options.code;
  }

  advanceRequestItemClicked() {
    if (this.advanceRequest.status.isEditable) {
      this.router.navigateByUrl('advanceRequest/edit/' + this.advanceRequest.id);
    } else {
      this.router.navigateByUrl('advanceRequest/view/' + this.advanceRequest.id);
    }
  }

  navigateToAdvanceRequestEdit(event) {
    event.stopPropagation();
    this.router.navigateByUrl('advanceRequest/edit/' + this.advanceRequest.id);
  }
}
