<div style="width: 100%; height: 100%;">
    <div *ngIf="mobileQuery.matches" color="primary" style=" background-color: whitesmoke;" (click)="sidenav.toggle()">
        <button mat-icon-button><mat-icon>menu</mat-icon> Event Menu</button>
      </div>
      <mat-drawer-container [hasBackdrop]="mobileQuery.matches" style="height: 100%">      
        <mat-drawer #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches" mode="side" position="start">        
            <mat-tab-group>
                <mat-tab label="Event Requests">
                    <div class="event-row" style="padding-left: 10px;" *ngFor="let event of events" (click)="eventRowClicked(event)">
                        <div style="display: inline-flex">
                            <div style="margin: 12px;" class="circle" [ngClass]="event.eventStatus === eventStatus.REQUESTED ? 'accent-color' : 'primary-color'">
                                <span class="initials" style="color: white;">{{event.eventStatus === eventStatus.REQUESTED ? 'R' : 'A'}}</span>
                            </div>
                            <div style="margin-top: 12px; width: 200px;">
                                <label><b>{{event.eventName}}</b></label>
                                <br />
                                <label>{{event.eventType}}</label><br />
    
                            </div>
                        </div>
                        <div style="color: gray;
                        font-size: smaller;
                        margin: 12px;
                        margin-top: 3px;
                        margin-left: 63px;">
                            <label>{{getDateRange(event.startDate,event.endDate)}}</label>
                        </div>
                        <mat-divider style="margin-bottom: 18px"></mat-divider>
                    </div>
                </mat-tab>
                <mat-tab label="Past Events">
                    <!-- <div class="event-row" *ngFor="let event of events">
                        <div style="display: inline-flex">
                            <div style="margin: 12px" class="circle accent-color">
                                <span class="initials">{{PD}}</span>
                            </div>
                            <div style="margin-top: 12px">
                                <label><b>{{event.data.name.iv}}</b></label>
                                <br />
                                <label>Training</label><br />
    
    
                            </div>
                        </div>
                        <div style="margin: 12px; margin-top: 3px">
                            <label>{{getDateRange(event.data.startDate.iv,event.data.endDate.iv)}}</label>
                        </div>
                        <mat-divider style="margin-bottom: 18px"></mat-divider>
                    </div> -->
                </mat-tab>
            </mat-tab-group>
            <div style="width: 250px; padding: 30px" *ngIf="events.length === 0">No events to display.</div>
        </mat-drawer>
        <mat-drawer-content>
            <div fxLayout="row" fxLayoutAlign="center start" class="pt-2" style="width: 100%">
                <div fxLayout="column" fxFlex.xl="80" fxFlex.lg="80" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <button mat-fab style="background-color: #640000 !important; z-index: 999;" 
            style="position: fixed; right: 30px; bottom: 30px; cursor: pointer"
                (click)="createEventRequest()">
                <mat-icon style="color: white;">add</mat-icon>
            </button>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
 