import {LookupModel} from './Common';

export class UserData {
    user:  User;
    token: Token;
}

export class Token {
    access_token: string;
    expires_in:   number;
}

export class User {
    id:                   number;
    userName:             string;
    displayName:             string;
    normalizedUserName:   string;
    email:                string;
    normalizedEmail:      string;
    emailConfirmed:       boolean;
    passwordHash:         string;
    securityStamp:        string;
    concurrencyStamp:     string;
    phoneNumber:          string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled:     boolean;
    lockoutEnd:           string;
    lockoutEnabled:       boolean;
    isFirstLogin:         boolean;
    accessFailedCount:    number;
    isActive:             boolean;
    userRoles:            any[];
    claims:               string;
    userInfos:            any[];
    userLocations:        UserLocation[];
    userProfile:          UserProfile[];
}

export class UserLocation {
    userId:       number;
    locationId:   number;
    location:     Location;
    id:           number;
    isActive:     boolean;
    createdBy:    number;
    createdDate:  Date;
    modifiedBy:   number;
    modifiedDate: Date;
    rowguid:      string;
}

export class Location {
    name:             string;
    locationTypeId:   number;
    parentLocationId: number;
    locationType:     LocationType;
    locationAllowance: LocationAllowance;
    parentLocation:   number;
    userLocations:    any[];
    id:               number;
    isActive:         boolean;
    createdBy:        number;
    createdDate:      Date;
    modifiedBy:       number;
    modifiedDate:     Date;
    rowguid:          string;
}
export interface LocationType {
    parentLocationTypeId: number;
    name:                 string;
    code:                 string;
    id:                   number;
    isActive:             boolean;
    createdBy:            number;
    createdDate:          Date;
    modifiedBy:           number;
    modifiedDate:         Date;
    rowGuid:              string;
}
export class LocationAllowance {
    id: number;
    locationAllowanceType: LocationAllowanceType;
}
export class LocationAllowanceType {
    id: number;
    mieAllowance: number;
    lodgingAllowance: number;
    name: string;
}
export class UserProfile {
    firstName:    string;
    middleName:   string;
    lastName:     string;
    jobTitle:   LookupModel;
    jobTitleID:   number;
    photo:        string;
    userId:       number;
    id:           number;
    isActive:     boolean;
    createdBy:    number;
    createdDate:  Date;
    modifiedBy:   number;
    modifiedDate: Date;
    rowGuid:      string;
}



export class UserInfo {
    browser: string;
    browserVersion: string;
    os: string;
    osVersion: string;
    deviceType: string;
}

export class LoginModel {
    username: string;
    password: string;
    rememberMe: boolean;
    userInfo: UserInfo;
}

export class UserResponseModel {
    id:                   number;
    userName:             string;
    normalizedUserName:   string;
    email:                string;
    normalizedEmail:      string;
    emailConfirmed:       boolean;
    passwordHash:         string;
    securityStamp:        string;
    concurrencyStamp:     string;
    phoneNumber:          string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled:     boolean;
    lockoutEnd:           null;
    lockoutEnabled:       boolean;
    accessFailedCount:    number;
    isActive:             boolean;
    userRoles:            any[];
    claims:               null;
    userInfos:            any[];
    userLocations:        UserLocation[];
    userProfile:          UserProfile[];
    userAccounts:         UserAccountModel[];
    userMemberships:      UserAccountModel[];
    userRelationships:    UserRelationshipsModel[];
    userIdCards:          UserIDCard[];
}

export class UserAccountModel {
    userId:            number;
    accountTypeId?:    number;
    value:              string;
    accountType?:      LookupModel;
    id:                number;
    isActive:          boolean;
    createdBy:         number;
    createdDate:       Date;
    modifiedBy:        number;
    modifiedDate:      Date;
    rowGuid:           string;
    membershipTypeId?: number;
    membershipType?:   LookupModel;
}
export class UserRelationshipsModel {
    userId: number;
    parentUserId: number;
    parentUser: UserResponseModel;
    relationShipType: string;
    id: number;
    isActive: boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    rowGuid: string
}



export class UserIDCard {
    userId:       number;
    idCardTypeId: number;
    document:     string;
    idCardType:   LookupModel;
    id:           number;
    isActive:     boolean;
    createdBy:    number;
    createdDate:  Date;
    modifiedBy:   number;
    modifiedDate: Date;
    rowGuid:      string;
}

export class PasswordResetModel {
    id: number;
    token: string;
    password: string;
    confirmPassword: string;
}

export class ChangePasswordModel {
    username: string;
    currentPassword: string;
    newPassword: string;
}

export class PasswordResetRequestModel {
    email: string;
}