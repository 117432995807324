<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content>
    <mat-form-field style="width: 100%">
        <mat-label>Comment</mat-label>
        <textarea matInput
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="5" [(ngModel)]="data.comment"></textarea>
        </mat-form-field>
</div>
<div mat-dialog-actions style="text-align: right">
    <button mat-button style="margin-right: 10px" (click)="onCancelClicked()">Cancel</button>
    <button mat-raised-button (click)="actionClicked(data.comment)" class="primary-color" [ngClass]="data.actionClass ? data.actionClass : 'warn'" [disabled]="!data.comment">{{data.actionName}}</button>
</div>
