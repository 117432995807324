import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {UserProfile, UserResponseModel} from '../../models/User';
import * as Constants from '../../models/Constants';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent implements OnInit {

  public userProfile: UserProfile;
  public user: UserResponseModel;
  public constants: any = Constants;
  public userRelationShipMap = [];
  constructor(private router: Router, private accountService: AccountService) { }

  ngOnInit(): void {
    this.getUserDetails();
  }
  getUserInitials() {
    return ((this.userProfile.firstName ? this.userProfile.firstName[0] : '') +
        (this.userProfile.lastName ? this.userProfile.lastName[0] : '')).toUpperCase();
  }
  getUserFullName(userProfile) {
    return userProfile.firstName + ' ' + userProfile.middleName + ' ' + userProfile.lastName;
  }
  editProfile() {
    this.router.navigateByUrl('/settings/profile/edit')
  }

  private getUserDetails() {
    this.accountService.getUserDetails().subscribe(
        resp => {
          if (resp) {
            this.user = resp.model;
            this.userProfile = resp.model.userProfile[0];
            this.getUserRelationshipMap();
          }
        },
        error => {
        }
    )
  }

  getUserAccount(accountCode) {
    const userAccount = this.user.userAccounts.find(uA => uA.accountType.code === accountCode);
    return userAccount ? userAccount.value : '';
  }

  getUserLocation() {
    const userLocations = this.user.userLocations;
    return userLocations ? userLocations[0] ? userLocations[0].location.name : '' : '';
  }

  getUserRelationshipMap() {
    const userRelationships = this.user.userRelationships;
    this.userRelationShipMap = [];
    const _this = this;
    userRelationships.forEach(function (userRelationship) {
      const map = {
        name: _this.getUserFullName(userRelationship.parentUser.userProfile[0]),
        title: userRelationship.relationShipType};
      _this.userRelationShipMap.push(map);
      });
    };
}
