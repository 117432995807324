import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationStart, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private snackbar: MatSnackBar, private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.clear();
            }
        });
    }


    success(message) {
        this.snackbar.open(message, null, {
            duration: 100000,
            panelClass: ['snackbar-success']
        });
    }

    error(message) {
        this.snackbar.open(message, null, {
            duration: 100000,
            panelClass: ['snackbar-error']
        });
    }
    info(message) {
        this.snackbar.open(message, null, {
            duration: 100000,
            panelClass: ['snackbar-info']
        });
    }

    clear() {
        this.snackbar.dismiss();
    }
}
