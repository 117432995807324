import { Component, OnInit } from '@angular/core';

import { TotalCost, OtherCost } from 'app/models/Common';

@Component({
  selector: 'app-budget-summary',
  templateUrl: './budget-summary.component.html',
  styleUrls: ['./budget-summary.component.css']
})
export class BudgetSummaryComponent implements OnInit {

  public totalTrainerCost = new TotalCost;
  public totalTraineeCost = new TotalCost;
  public totalOtherCost = new OtherCost;
  constructor() { }

  ngOnInit(): void {
  }

}
