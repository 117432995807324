import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {

  constructor(public account: AccountService, public router: Router) { }

  canActivate(): boolean {
    if (this.account.isAuthenticated()) {
      this.router.navigate(['profile/view']);
      return false;
    }
    return true;
  }
}
