import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class NumberValidator {

  public static isPhoneNumber(control: FormControl): ValidationResult {
    if (control.value) {
      // check if string has a dot
      const hasDot: boolean = control.value.toString().indexOf('.') >= 0 ? true : false;
      // convert string to number
      const number: number = Math.floor(control.value);
      // get result of isInteger()
      const integer: boolean = Number.isInteger(number);

      // remove leading zero
      const value = parseInt(control.value, 10);

      // is 10 digits
      const isTenDigits: boolean = value.toString().length === 9 ? true : false;
      // validate conditions
      const valid: boolean = !hasDot && integer && number > 0 && isTenDigits;
      if (!valid) {
        return { isPhoneNumber: true };
      }
    }
    return null;
  }
}
