import {AuditFields, LookupModel} from './Common';
import {Location, UserResponseModel} from './User';

export class AdvanceRequest extends AuditFields {
    parentRequestId: number;
    id: number;
    chargeCode: LookupModel;
    chargeCodeId: number;
    requestType: LookupModel;
    requestTypeId: number;
    startDate: Date;
    endDate: Date;
    status: AdvanceRequestStatus;
    description: string;
    requester: UserResponseModel;
    supervisor: UserResponseModel;
    requestObjectives: RequestObjectiveModel[];
    requestExpenses: RequestExpensesModel[];
    requestAccommodations: RequestAccommodationsModel[];
}
export class AdvanceRequestStatus {
    id: number;
    name: string;
    options: StatusOptionsModel;
    isEditable: boolean;
}
export class StatusOptionsModel {
  code: string;
  style: string;
  Tags: any;
}

export class RequestObjectiveModel {
    id: number;
    persisted: boolean;
    site: LookupModel;
    product: LookupModel;
    service: LookupModel;
    location: Location;
    locationId: number;
    productId: number;
    serviceTypeId: number;
    description: string;

    constructor() {
        this.persisted = false;
    }
}
export class RequestExpensesModel {
    id: number;
    file: string;
    purpose: LookupModel;
    purposeId: number;
    cost: number;
    quantity: number;
    persisted: boolean;

    constructor() {
        this.persisted = false;
    }
}

export class RequestMealModel {
    id: number;
    requestMealTypeId: number;
    requestMealType: LookupModel;
    numberOfDays: number;
}

export class RequestAccommodationsModel {
    file: string;
    id: number;
    location: Location;
    locationId: number;
    numberOfDays: number;
    accommodationType: LookupModel;
    accommodationTypeId: number;
    persisted: boolean;
    // arrivalDate: Date;
    // departureDate: Date;
    startDate: Date;
    endDate: Date;

    constructor() {
        this.persisted = false;
    }
}

export class RequestLodgingModel {
    id: number;
    startDate: Date;
    endDate: Date;
    locationName: String;
    numberOfNights: number;
    cost: number;
}

export class ActionButton {
    name:    string;
    options: ActionButtonOptionsModel;
}

export class ActionButtonOptionsModel {
    Tags:  string[];
    style: string;
}


