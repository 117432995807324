import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateService} from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DemoMaterialModule } from './shared/demo.module';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import {AccountService} from './services/account.service';
import {AuthenticationService} from './services/authentication.service';
import { AdvanceFormComponent } from './advance-request/advance-request-form/advance-form.component';
import {JwtModule} from '@auth0/angular-jwt';
import {environment} from '../environments/environment';
import { AdvanceRequestViewComponent } from './advance-request/advance-request-view/advance-request-view.component';
import { ExpenseViewComponent } from './expense/expense-view/expense-view.component';
import {NgxPrintDirective, NgxPrintModule} from 'ngx-print';
import {LoaderService} from './services/loader.service';
import {LoaderInterceptor} from './services/loader-interceptor.service';
import {MatSpinnerOverlayComponent} from './shared/mat-spinner-overlay/mat-spinner-overlay.component';
import { LookupConfigurationComponent } from './settings/lookup-configuration/lookup-configuration.component';
import { AdvanceRequestListComponent } from './advance-request/advance-request-list/advance-request-list.component';
import { AdvanceRequestListItemComponent } from './advance-request/advance-request-list-item/advance-request-list-item.component';
import {ChangePasswordComponent} from './settings/change-password/change-password.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBadgeModule} from '@angular/material/badge';
import { ExpenseFormComponent } from './expense/expense-form/expense-form.component';
import { EventViewComponent } from './event/event-view/event-view.component';
import { EventRequestComponent } from './event/event-request/event-request.component';
import { EventsComponent } from './event/events/events.component';
import {AppFirebaseModule} from './app-firebase/app-firebase.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BudgetSummaryComponent } from './shared/budget-summary/budget-summary.component';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('token');
}
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AdvanceFormComponent,
    AdvanceRequestViewComponent,
    MatSpinnerOverlayComponent,
    LookupConfigurationComponent,
    ChangePasswordComponent,
    AdvanceRequestListComponent,
    AdvanceRequestListItemComponent,
    ExpenseFormComponent,
    ExpenseViewComponent,
    EventViewComponent,
    EventRequestComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, {scrollPositionRestoration: 'enabled'}),
    FormsModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatTabsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSelectModule,
    MatCardModule,
    DemoMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config : {
        tokenGetter : tokenGetter,
        whitelistedDomains: [environment.domain] // allows the token to be send in every request
      }
    }),
    TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
          }
      }),
    FlexLayoutModule,
    PerfectScrollbarModule,
      NgxPrintModule,
    AppFirebaseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    TranslateService, AccountService, AuthenticationService,
    {
       provide: PERFECT_SCROLLBAR_CONFIG,
       useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, LoaderService,     { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }

  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
