export enum TrainerClassification {
  Initial = 0,
  ByJobClassification = 1,
  ByInstitution = 2
}

export enum TraineeClassification {
  Initial = 0,
  ByJobClassification = 1,
  ByInstitution = 2
}

export enum Position {
  Finance = 0,
  ProgramOfficer = 1,
  Requester = 2,
  Approver = 3
}

export const PER_DIEM_RATE_FLAT_TRAINEE = 560
export const PER_DIEM_RATE_HOTEL_TRAINEE = 0
export const PER_DIEM_RATE_FLAT_TRAINER = 530
export const PER_DIEM_RATE_HOTEL_TRAINER = 1000
export const AVG_AIR_TRAVEL_COST = 5000
export const AVG_GROUND_TRAVEL_COST = 400
export const OTHER_COST_PER_TRAINER = 100
export const MEETING_MATERIAL_PRINTING = 50
export const REFRESMENT_PER_PERSON_PER_DAY = 150
export const VENUE_COST_PER_DAY = 2000
export const STATIONARY_COST_PER_PERSON = 25