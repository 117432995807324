import { Component, OnInit } from '@angular/core';
import {AdvanceRequest} from '../../models/AdvanceRequest';
import {AdvanceRequestService} from '../../services/advance-request.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-advance-request-list',
  templateUrl: './advance-request-list.component.html',
  styleUrls: ['./advance-request-list.component.css']
})
export class AdvanceRequestListComponent implements OnInit {

  advanceRequestListCreatedByUser: AdvanceRequest[];
  advanceRequestListToBeReviewedByUser: AdvanceRequest[];
  constructor(private advanceRequestService: AdvanceRequestService, private router: Router,
              private loadingService: LoaderService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.getAdvanceRequestList();
  }

  getAdvanceRequestList() {
    this.advanceRequestService.getAdvanceRequestListToBeApprovedByUser().subscribe(
        resp => {
          if (resp.result === 0) {
            this.advanceRequestListToBeReviewedByUser = resp.model;
          } else {
            this.alertService.error('Unable to fetch advance requests. Please try again later.')
          }
        }, err => {
          this.alertService.error('Unable to fetch advance requests. Please try again later.')
        }
    );
    this.advanceRequestService.getAdvanceRequestListCreatedByUser().subscribe(
        resp => {
          if (resp.result === 0) {
            this.advanceRequestListCreatedByUser = resp.model;
          } else {
            this.alertService.error('Unable to fetch advance requests. Please try again later.');
          }
        }, err => {
          this.alertService.error('Unable to fetch advance requests. Please try again later.');
        }
    )
  }

  createAdvanceRequest() {
    this.router.navigateByUrl('advanceRequest/create');
  }


  get loading() {
    return this.loadingService.loading;
  }
}
