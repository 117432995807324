import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/account.service';
import {User, UserAccountModel, UserLocation, UserProfile, UserResponseModel, Location, UserRelationshipsModel} from '../../models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as Constants from '../../models/Constants';
import {LookupModel} from '../../models/Common';
import {LookupService} from '../../services/lookup.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';
import {local} from 'd3-selection';
import {NumberValidator} from '../../validators/number.validator';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
    public user: UserResponseModel;
    public userList: UserResponseModel[];
    public userProfile: UserProfile;
    public userLocation: UserLocation;
    public userRelationShip: UserRelationshipsModel;
    public baseLocations: Location[];
    public jobTitles: LookupModel[];
    public userProfileForm: FormGroup;

    constructor(private accountService: AccountService, private fb: FormBuilder, private lookupService: LookupService,
                private userService: UserService, private router: Router, private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.getUserDetails();
        this.getBaseLocation();
        this.getJobTitles();
        // this.getSupervisorsList();
    }

    private getUserDetails() {
        this.accountService.getUserDetails().subscribe(
            resp => {
                if (resp) {
                    this.user = resp.model;
                    this.userProfile = resp.model.userProfile[0];
                    this.userLocation = resp.model.userLocations[0];
                    this.userRelationShip = resp.model.userRelationships[0];
                    this.initializeForm();
                }
            },
            error => {
            }
        )
    }

    private initializeForm() {
        this.userProfileForm = this.fb.group({
            phoneNumber: [this.user.phoneNumber, [Validators.required, NumberValidator.isPhoneNumber]],
            firstName: [this.userProfile.firstName, [Validators.required]],
            middleName: [this.userProfile.middleName, [Validators.required]],
            lastName: [this.userProfile.lastName, [Validators.required]],
            jeml: [this.getUserAccount(Constants.JSI_EMAIL), [Validators.required]],
            peml: [this.getUserAccount(Constants.PERSONAL_EMAIL), [Validators.required]],
            tlgm: [this.getUserAccount(Constants.TELEGRAM), [Validators.required]],
            userLocationId: [this.getUserLocation(), [Validators.required]],
            jobTitleID: [this.userProfile.jobTitleID, [Validators.required]]
            // supervisorId: [this.getSupervisorId(), [Validators.required]]
        })
    }

    getUserAccount(accountCode) {
        const userAccount = this.user.userAccounts.find(uA => uA.accountType.code === accountCode);
        return userAccount ? userAccount.value : '';
    }

    setUserAccount(accountCode, value) {
        let userAccount = this.user.userAccounts.find(uA => uA.accountType.code === accountCode);
        if (userAccount) {
            userAccount.value = value;
        } else {
            // TODO: create a new useraccount
            userAccount = new UserAccountModel();
            userAccount.value = value;
        }
        return userAccount ? userAccount.value : '';
    }

    public submitUserProfile() {
        try {
            const formValues = this.userProfileForm.controls;
            this.user.phoneNumber = formValues.phoneNumber.value;
            this.userProfile.firstName = formValues.firstName.value;
            this.userProfile.middleName = formValues.middleName.value;
            this.userProfile.lastName = formValues.lastName.value;
            this.userProfile.jobTitleID = formValues.jobTitleID.value;
            this.setUserAccount(Constants.JSI_EMAIL, formValues.jeml.value);
            this.setUserAccount(Constants.PERSONAL_EMAIL, formValues.peml.value);
            this.setUserAccount(Constants.TELEGRAM, formValues.tlgm.value);
            this.userLocation.locationId = formValues.userLocationId.value;
            // this.userRelationShip.parentUserId = formValues.supervisorId.value;
            this.accountService.updateUserDetails(this.user).subscribe(
                resp => {
                    if (resp.result === 0) {
                        this.alertService.success('Successfully updated user profile.');
                        const localUser = this.accountService.getUserData();
                        if (localUser.isFirstLogin) {
                            this.accountService.getUserDetails().subscribe(
                                resp1 => {
                                    if (resp1) {
                                        localStorage.setItem('user', JSON.stringify(resp1.model));
                                    }
                                },
                                error => {
                                }
                            );
                        }
                        this.router.navigateByUrl('/profile/view')
                    } else {
                        this.alertService.error('Unable to update profile. Please try again later.');
                    }
                }, err => {
                    this.alertService.error('Unable to update profile. Please try again later.')
                });
        } catch (e) {
            this.alertService.error('Unable to update profile. Please check your input and try again later.')
            console.log('Profile edit error =>' + e);
        }
    }

    getUserLocation() {
        const userLocations = this.user.userLocations;
        return userLocations ? userLocations[0] ? userLocations[0].locationId : '' : '';
    }

    getSupervisorId() {
        const userRelationships = this.user.userRelationships;
        return userRelationships ? userRelationships[0] ? userRelationships[0].parentUserId : '' : '';
    }

    getBaseLocation() {
        this.lookupService.getLocationsWithType(Constants.BASE_LOCATION).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.baseLocations = resp.model;
                }
            },
            err => {

            },
            () => {

            }
        )
    }

    getJobTitles() {
        this.lookupService.getLookups(Constants.JOB_TITLE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.jobTitles = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {

            }
        )
    }

    getSupervisorsList() {
        this.userService.getSupervisorsList().subscribe(
            resp => {
                if (resp.result === 0) {
                    this.userList = resp.model;
                }
            }
        )
    }

    getUserFullName(user: UserResponseModel) {
        const userProfile = user.userProfile[0];
        return userProfile.firstName + ' ' + userProfile.middleName + ' ' + userProfile.lastName;
    }

    public checkError = (controlName: string, errorName: string) => {
        return this.userProfileForm.controls[controlName].hasError(errorName) && this.userProfileForm.controls[controlName].dirty;
    };
}
