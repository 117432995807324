/**
* Disables the already selected options and enables the unselected ones
* the name of the object property containing the ids 
* @param {string} optionValueLabel
* selected ids of the options selected
* @param {any[]} selectedOptionsIdArray
* the array containing the values for the option (the array of the dropdown)
* @param {any[]} optionArray
*/
export function disableOptions(optionValueLabel: string, selectedOptionsIdArray: any[], optionArray: any[]) {
    optionArray.forEach(element => {
        element.disabled = false;
    });
    const disableArray = [];
    for (let index = 0; index < selectedOptionsIdArray.length; index++) {
      const element = selectedOptionsIdArray[index];
      disableArray.push(element[optionValueLabel]);
    }
    
    for (let index = 0; index < disableArray.length; index++) {
        const element = disableArray[index];
      const value =  optionArray[optionArray.findIndex(x => x.id == element)]
      if (value != null) 
          value.disabled = true;
       
    }
}