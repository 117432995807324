<div class="relative">
    <div class="form-bg">
        <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="40" fxFlex.sm="70" fxFlex.xs="80"
                 style="margin-top: 10%">
                <mat-card class="login-session">
                    <div class="text-center">
                        <h3 class="base-border" style="padding-bottom: 20px">Forgot Password?</h3>
                        <i class="fa fa-lock fa-2x"></i>
                        <p>You can reset your password here.</p>
                        <form #form="ngForm" [formGroup]="passwordResetRequestForm">
                            <div fxLayout="column" fxLayoutAlign="start stretch">
                                <mat-form-field style="width: 100%">
                                    <input matInput placeholder="Email" type="text" name="email" formControlName="email" required>
                                </mat-form-field>
                                <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
                                <mat-error *ngIf="checkError('email', 'email')">Email is invalid</mat-error>
                                <button class="primary-color" mat-raised-button (click)="requestPasswordReset()" [disabled]="passwordResetRequestForm.invalid">Submit</button>
                            </div>
                        </form>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>