import { Injectable } from '@angular/core';
import {ResponseModel} from '../models/Common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountService} from './account.service';
import {environment} from '../../environments/environment';
import {EventRequest} from '../models/EventRequest';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};

@Injectable({
    providedIn: 'root'
})
export class EventService {

    public eventListURL = 'https://run.mocky.io/v3/18748c07-afab-4849-bf9f-b852cc0f389e';
    public eventURL = 'https://run.mocky.io/v3/02258078-fbcb-4ef7-b57a-99d8cf830e51';
    public eventRequestURL = environment.testUrl + '/event-requests';
    public testEventURL = '../../assets/data/event.json';

    constructor(private http: HttpClient, private accountService: AccountService) {
    }

    createEventRequest(eventRequest) {
        return this.http.post<any>(this.eventRequestURL, eventRequest, httpOptions);
      }

    getEventList() {
        return this.http.get<any>(this.eventListURL);
    }

    getEventForId(eventId) {
        return this.http.get<any>(this.eventRequestURL + '/' + eventId, httpOptions );
    }

    getEventForUser(userEmail) {
        return this.http.get<any>(this.eventRequestURL + '?user=' + userEmail, httpOptions );
    }

    getEventTestId(eventId) {
        return this.http.get<any>(this.testEventURL);
    }

    createEventWorkflow(approvalContent) {
        return this.http.post<ResponseModel<any>>(this.eventRequestURL, approvalContent, httpOptions);
    }
}
