import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../models/Common';

@Component({
  selector: 'app-comment-box-dialog',
  templateUrl: './comment-box-dialog.component.html',
  styleUrls: ['./comment-box-dialog.component.css']
})
export class CommentBoxDialogComponent implements OnInit {
    onActionButtonClick = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<CommentBoxDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  actionClicked(comment) {
      this.onActionButtonClick.emit(comment);
  }
    onCancelClicked() {
      this.dialogRef.close();
    }
}
