<div class="relative">
        <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90">
                <mat-card class="card-background no-padding" style="box-shadow: unset !important">

                    <div class="header primary-color">
                        <h4 class="title-text">EDIT PROFILE</h4>
                    </div>

                    <div>

                        <form [formGroup]="userProfileForm" *ngIf="userProfileForm" class="form-body">
                            <div fxLayout="column" fxLayoutAlign="center stretch">
                                <div fxLayout="column">
                                    <h4 class="section-label">Basic Information</h4>
                                <div fxLayout="row" fxLayoutAlign="space-around">
                                    <mat-form-field fxFlex="40">
                                        <input matInput placeholder="First Name" type="text" name="fName" formControlName="firstName" required>
                                        <mat-error *ngIf="checkError('firstName', 'required')">First Name is required </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input matInput placeholder="Middle Name" type="text" name="lName" formControlName="middleName" required>
                                        <mat-error *ngIf="checkError('middleName', 'required')">Middle Name is required </mat-error>
                                    </mat-form-field>
                                </div>
                                    <br/>
                                    <div fxLayout="row" fxLayoutAlign="space-around">
                                    <mat-form-field fxFlex="40">
                                        <input matInput placeholder="Last Name" type="text" name="lName" formControlName="lastName" required>
                                        <mat-error *ngIf="checkError('lastName', 'required')">Last Name is required </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select placeholder="Job Title" formControlName="jobTitleID">
                                            <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.id">
                                                {{jobTitle.name}}
                                            </mat-option>
                                            <mat-error *ngIf="checkError('jobTitleID', 'required')">Job Title is required </mat-error>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                    <br/>
                                </div>
                                <div fxLayout="column">
                                    <h4 class="section-label">Contact Information</h4>
                                <div fxLayout="row" fxLayoutAlign="space-around">
                                <mat-form-field fxFlex="40">
                                    <mat-select placeholder="Base Location" formControlName="userLocationId">
                                        <mat-option *ngFor="let baseLocation of baseLocations" [value]="baseLocation.id">
                                            {{baseLocation.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError('userLocationId', 'required')">Base location is required </mat-error>
                                </mat-form-field>
                                    <div fxFlex="40"></div>
                                </div>
                                    <br/>
                                <div fxLayout="row" fxLayoutAlign="space-around">

                                <mat-form-field fxFlex="40">
                                    <input matInput placeholder="Phone Number" type="text" name="phoneNumber" formControlName="phoneNumber" required>
                                    <mat-hint align="start"><strong>Eg. 0912345678</strong> </mat-hint>
                                    <mat-error *ngIf="checkError('phoneNumber', 'required')">Phone Number is
                                        required
                                    </mat-error>
                                    <mat-error *ngIf="checkError('phoneNumber', 'isPhoneNumber')">Invalid Phone Number.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="40" >
                                    <input matInput placeholder="Phone Number (Telegram)" type="text" name="phoneNumberTelegram" formControlName="tlgm" required>
                                </mat-form-field>
                                </div>
                                    <br/>
                                <div fxLayout="row" fxLayoutAlign="space-around">

                                <mat-form-field fxFlex="40">
                                    <input matInput placeholder="Email (Personal)" type="email" name="emailPersonal"  formControlName="peml" required>
                                </mat-form-field>
                                <mat-form-field fxFlex="40">
                                    <input matInput placeholder="Email (JSI)" type="email" name="emailJSI" formControlName="jeml" required>
                                </mat-form-field>
                                </div>
                                    <br/>
                                </div>
                                <!--<div fxLayout="column">-->
                                    <!--<h4 class="section-label">Supervisor Information</h4>-->
                                    <!--<div fxLayout="row" fxLayoutAlign="space-around">-->
                                        <!--<mat-form-field fxFlex="40">-->
                                            <!--<mat-select placeholder="Supervisor" formControlName="supervisorId">-->
                                                <!--<mat-option *ngFor="let user of userList" [value]="user.id">-->
                                                    <!--{{getUserFullName(user)}}-->
                                                <!--</mat-option>-->
                                            <!--</mat-select>-->
                                        <!--</mat-form-field>-->
                                        <!--<div fxFlex="40"></div>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
                                    <button class="primary-color" mat-raised-button (click)="submitUserProfile()">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-card>
            </div>
        </div>
</div>