<div class="relative">
    <div fxLayout="row" fxLayoutAlign="center start" class="pt-2">
        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="40" fxFlex.sm="70" fxFlex.xs="80"
        >
            <mat-card class="login-session card-background">
                <div>
                    <h3 class="base-border" style="padding-bottom: 15px">Welcome {{username}}!</h3>
                    <p style="padding-bottom: 20px">Please set your password.</p>
                    <form #form="ngForm" [formGroup]="changePasswordForm" (ngSubmit)="setPassword()">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <mat-form-field style="width: 100%; padding-bottom: 20px">
                                <input matInput placeholder="Current Password" [type]="hide ? 'password' : 'text'" name="currentPassword" required formControlName="currentPassword">
                                <mat-icon style="cursor:pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                            <mat-form-field style="width: 100%; padding-bottom: 20px">
                                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" name="password" required formControlName="password">
                                <mat-icon style="cursor:pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;" class="mb-1">
                                <input matInput placeholder="Confirm Password" [type]="hide ? 'password' : 'text'" name="confirmPassword" required formControlName="confirmPassword">
                                <mat-icon style="cursor:pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="showMismatchError">Passwords don't match</mat-error>
                            </mat-form-field>
                            <div *ngFor="let error of errors">
                                <label style="color: red;">{{error.description}}</label>
                            </div>
                            <button class="primary-color" mat-raised-button [disabled]="changePasswordForm.invalid">Submit</button>
                        </div>
                    </form>
                </div>
            </mat-card>
        </div>
    </div>
</div>