import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  subchildren?: SuperChildrenItems[];
}

export interface SuperChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const HORIZONTALMENUITEMS = [
  {
    state: 'events',
    name: 'Events',
    type: 'link',
    icon: 'calendar_today'
  }, {
    state: 'advanceRequest/list',
    name: 'Advance Requests',
    type: 'link',
    icon: 'receipt'
  },
  {
    state: 'profile/view',
    name: 'Profile',
    type: 'link',
    icon: 'person',
  },
  {
    name: 'Settings',
    type: 'sub',
    icon: 'settings',
    state: 'settings',
    children: [
      {state: 'settings/profile/edit', name: 'Edit my Profile'},
      // {state: 'settings/lookupConfig', name: 'Lookup Configurations'},
      {state: 'settings/changePassword', name: 'Change Password'}
    ]
  }

];

@Injectable()
export class HorizontalMenuItems {
  getAll(): Menu[] {
    return HORIZONTALMENUITEMS;
  }
  add(menu: Menu) {
    // HORIZONTALMENUITEMS.push(Menu);
  }
}
