import {Component, NgZone, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    ActionButton,
    AdvanceRequest,
    RequestAccommodationsModel,
    RequestExpensesModel,
    RequestObjectiveModel
} from '../../models/AdvanceRequest';
import {LookupModel} from '../../models/Common';
import {Location} from '../../models/User';
import {LookupService} from '../../services/lookup.service';
import * as Constants from '../../models/Constants';
import {MatTableDataSource} from '@angular/material/table';
import {AdvanceRequestService} from '../../services/advance-request.service';
import {AlertService} from '../../services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {LoaderService} from '../../services/loader.service';

@Component({
    selector: 'app-advance-form',
    templateUrl: './advance-form.component.html',
    styleUrls: ['./advance-form.component.css']
})
export class AdvanceFormComponent implements OnInit {
    public chargeTypes: LookupModel[];
    public accommodationTypes: LookupModel[];
    public LDGAccommocation: LookupModel;
    public MIEAccommocation: LookupModel;
    public requestTypes: LookupModel[];
    public serviceTypes: LookupModel[];
    public products: LookupModel[];
    public actionButtons: ActionButton[];
    public advanceRequest: AdvanceRequest;
    public sites: Location[];
    public lodgingLocations: Location[];
    public leObservableLodgingLocations: Observable<Location[]>;
    public mieObservableLodgingLocations: Observable<Location[]>;
    public otherExpensePurposeList: LookupModel[];
    public advanceRequestForm: FormGroup;
    public poDataSource: MatTableDataSource<RequestObjectiveModel>;
    public leDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public mieDataSource: MatTableDataSource<RequestAccommodationsModel>;
    public oeDataSource: MatTableDataSource<RequestExpensesModel>;
    public advanceRequestId: number;
    public displayedColumns: string[] = ['site', 'product', 'type', 'summary', 'actions'];
    public otherExpenseDisplayedColumns: string[] = ['purpose', 'otherAmount', 'actions'];
    public lodgingExpenseDisplayedColumns: string[] = ['city', 'type', 'lodgingPerDay', 'numberOfNights', 'amount', 'actions'];
    public MIEExpenseDisplayedColumns: string[] = ['city', 'type', 'MIEPerDay', 'numberOfDays', 'amount', 'actions'];
    public mieRequestAccommodationsModels: RequestAccommodationsModel[];
    public lodgingRequestAccommodationsModels: RequestAccommodationsModel[];
    public minDate = new Date();
    public showComment = false;
    public comments = [];

    constructor(private _ngZone: NgZone, private fb: FormBuilder, private lookupService: LookupService,
                private advanceRequestService: AdvanceRequestService, private alertService: AlertService,
                private router: Router, private route: ActivatedRoute, private loadingService: LoaderService) {
    }

    ngOnInit(): void {
        this.getLookups();
        this.advanceRequestId = this.route.snapshot.params['id'];
        this.poDataSource = new MatTableDataSource<RequestObjectiveModel>([]);
        this.oeDataSource = new MatTableDataSource<RequestExpensesModel>([]);
        this.mieDataSource = new MatTableDataSource<RequestAccommodationsModel>([]);
        this.leDataSource = new MatTableDataSource<RequestAccommodationsModel>([]);
        if (this.advanceRequestId) {
            this.getAdvanceRequest();
            this.getAdvanceRequestCommentList()
        } else {
            this.initializeForm();
        }
        this.getAdvanceRequestWorkflow();

        // tie Lodging Advances and M&IE Advances together 
        this.updateMIEAdvancesWithLodgingAdvances();
    }

    updateMIEAdvancesWithLodgingAdvances() {
        
        this.leRequestAccommodations.valueChanges.subscribe(leAccomodations => {
            
            this.leRequestAccommodations.controls.forEach((acc, index) => {
                const locationFormControl = acc.get('location');
                const daysFormControl = acc.get('numberOfDays');

                // update location for M & IE Advances
                if (locationFormControl && locationFormControl.valid) {
                    this.mieRequestAccommodations.at(index).get('location').setValue(
                        locationFormControl.value
                    );
                    this.mieRequestAccommodations.at(index).get('locationId').setValue(
                        locationFormControl.value.id
                    );
                }

                // update noOfDays for M & IE Advances
                if (daysFormControl && daysFormControl.valid) {
                    let noOfDays = daysFormControl.value;
                    
                    // add one extra day cost to the last M & IE
                    if (index == this.leRequestAccommodations.length - 1) {
                        noOfDays = noOfDays + 1;
                    }

                    this.mieRequestAccommodations.at(index).get('numberOfDays').setValue(
                        noOfDays
                    );
                }
            });

        });
    }

    get loading() {
        return this.loadingService.loading;
    }
    getUserInitial(fullName) {
        const names = fullName.split('\\s+');
        let initials = '';
        names.forEach(function (name) {
            initials += name[0].toUpperCase();
        });
        return initials;
    }
    appendLeadingZeroes(n) {
        if (n <= 9) {
            return '0' + n;
        }
        return n
    }
    formatDateTime(date) {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const dateTime = new Date(date);
        return (new Date(date)).toLocaleTimeString() + ' ' + months[dateTime.getMonth()] + ' ' +
            this.appendLeadingZeroes(dateTime.getDate());
    }
    getAdvanceRequestCommentList() {
        this.advanceRequestService.getAdvanceRequestCommentList(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.comments = resp.model.filter(comm => comm.comment != null);
                }
            }
        )
    }
    getAdvanceRequestWorkflow() {
        this.advanceRequestService.getAdvanceRequestWorkflow(this.advanceRequestId ? this.advanceRequestId : '').subscribe(
            resp => {
                if (resp.result === 0) {
                    this.actionButtons = resp.model;
                }
            }
        )
    }

    getAdvanceRequest() {
        this.advanceRequestService.getAdvanceRequest(this.advanceRequestId).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.advanceRequest = resp.model;
                    this.mieRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.MIE) : [];
                    this.lodgingRequestAccommodationsModels = this.advanceRequest.requestAccommodations ?
                        this.advanceRequest.requestAccommodations.filter(
                            accommodation => accommodation.accommodationType.code === Constants.LODGING) : [];
                    this.initializeForm();

                }
            }
        )
    }

    initializeForm() {
        if (this.advanceRequestId) {
            this.advanceRequestForm = this.fb.group({
                chargeCodeId: [this.advanceRequest.chargeCodeId, Validators.required],
                requestTypeId: [this.advanceRequest.requestTypeId, Validators.required],
                startDate: [this.advanceRequest.startDate, Validators.required],
                endDate: [this.advanceRequest.endDate],
                description: [this.advanceRequest.description],
                requestObjectives: this.fb.array([]),
                requestExpenses: this.fb.array([]),
                requestAccommodations: this.fb.array([]),
                mieRequestAccommodations: this.fb.array([]),
                leRequestAccommodations: this.fb.array([])
            });
            this.advanceRequest.requestObjectives.forEach(reqObj => {
                this.addExistingProjectObjective(reqObj);
            });
            this.advanceRequest.requestExpenses.forEach(reqExp => {
                this.addExistingRequestExpense(reqExp);
            });
            this.mieRequestAccommodationsModels.forEach(reqAcc => {
                this.addExistingRequestAccommodation(reqAcc, Constants.MIE);
            });
            this.lodgingRequestAccommodationsModels.forEach(reqAcc => {
                this.addExistingRequestAccommodation(reqAcc, Constants.LODGING);
            });
        } else {
            this.advanceRequestForm = this.fb.group({
                chargeCodeId: [this.chargeTypes ? this.chargeTypes[0].id : '', Validators.required],
                requestTypeId: ['', Validators.required],
                startDate: ['', Validators.required],
                endDate: [''],
                description: [''],
                requestObjectives: this.fb.array([]),
                requestExpenses: this.fb.array([]),
                mieRequestAccommodations: this.fb.array([]),
                leRequestAccommodations: this.fb.array([]),
                requestAccommodations: this.fb.array([])
            });
            this.addNewRequestAccommodations(Constants.MIE);
            this.addNewRequestAccommodations(Constants.LODGING);
            this.addNewRequestExpense();
            this.addNewProjectObjective();
        }

    }

    displayFn(location): string {
        return location && location.name ? location.name : '';
    }

    private _filter(name: string): Location[] {
        const filterValue = name.toLowerCase();

        return this.lodgingLocations.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
    }

    get requestObjectives() {
        return this.advanceRequestForm.get('requestObjectives') as FormArray;
    }

    get requestExpenses() {
        return this.advanceRequestForm.get('requestExpenses') as FormArray;
    }

    get leRequestAccommodations() {
        return this.advanceRequestForm.get('leRequestAccommodations') as FormArray;
    }

    get mieRequestAccommodations() {
        return this.advanceRequestForm.get('mieRequestAccommodations') as FormArray;
    }

    addNewProjectObjective() {
        this.poDataSource.data.push(new RequestObjectiveModel());
        this.poDataSource.data = this.poDataSource.data.slice();
        this.requestObjectives.push(
            this.fb.group({
                locationId: ['', Validators.required],
                productId: ['', Validators.required],
                serviceTypeId: ['', Validators.required],
                description: ['', Validators.required]
            })
        )
    }

    addExistingProjectObjective(projectObjective) {
        this.poDataSource.data.push(projectObjective);
        this.poDataSource.data = this.poDataSource.data.slice();
        this.requestObjectives.push(
            this.fb.group({
                id: [projectObjective.id],
                rowGuid: [projectObjective.rowGuid],
                createdDate: [projectObjective.createdDate],
                modifiedDate: [projectObjective.modifiedDate],
                modifiedBy: [projectObjective.modifiedBy],
                locationId: [projectObjective.locationId, Validators.required],
                productId: [projectObjective.productId, Validators.required],
                serviceTypeId: [projectObjective.serviceTypeId, Validators.required],
                description: [projectObjective.description, Validators.required]
            })
        )
    }

    addNewRequestExpense() {
        this.oeDataSource.data.push(new RequestExpensesModel());
        this.oeDataSource.data = this.oeDataSource.data.slice();
        this.requestExpenses.push(
            this.fb.group({
                purposeId: [''],
                cost: [0]
            })
        )
    }

    addExistingRequestExpense(requestExpense) {
        this.oeDataSource.data.push(requestExpense);
        this.oeDataSource.data = this.oeDataSource.data.slice();
        this.requestExpenses.push(
            this.fb.group({
                id: [requestExpense.id],
                rowGuid: [requestExpense.rowGuid],
                createdDate: [requestExpense.createdDate],
                modifiedDate: [requestExpense.modifiedDate],
                modifiedBy: [requestExpense.modifiedBy],
                purposeId: [requestExpense.purposeId],
                cost: [requestExpense.cost]
            })
        )
    }

    addNewRequestAccommodations(accommodationType) {
        if (accommodationType === Constants.LODGING) {

            /* Add Request Accomodation for LODING */

            this.leDataSource.data.push(new RequestAccommodationsModel());
            this.leDataSource.data = this.leDataSource.data.slice();
            this.leRequestAccommodations.push(
                this.fb.group({
                    location: ['', Validators.required],
                    locationId: ['', Validators.required],
                    numberOfDays: ['', Validators.required],
                    accommodationTypeId: [this.LDGAccommocation ? this.LDGAccommocation.id : '']
                })
            );
            this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.leObservableLodgingLocations = this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );

            /* Also add accomodation for MIE */

            this.mieDataSource.data.push(new RequestAccommodationsModel());
            this.mieDataSource.data = this.mieDataSource.data.slice();
            this.mieRequestAccommodations.push(
                this.fb.group({
                    location: ['', Validators.required],
                    locationId: ['', Validators.required],
                    numberOfDays: ['', Validators.required],
                    accommodationTypeId: [this.MIEAccommocation ? this.MIEAccommocation.id : '']
                })
            );
            this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
        }
        // else if (accommodationType === Constants.MIE) {

        //     this.mieDataSource.data.push(new RequestAccommodationsModel());
        //     this.mieDataSource.data = this.mieDataSource.data.slice();
        //     this.mieRequestAccommodations.push(
        //         this.fb.group({
        //             location: ['', Validators.required],
        //             locationId: ['', Validators.required],
        //             numberOfDays: ['', Validators.required],
        //             accommodationTypeId: [this.MIEAccommocation ? this.MIEAccommocation.id : '']
        //         })
        //     );
        //     this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
        //         this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
        //     });
        //     this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges
        //         .pipe(
        //             startWith<string | Location>(''),
        //             map(value => typeof value === 'string' ? value : (value as Location).name),
        //             map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
        //         );
        // }

    }

    addExistingRequestAccommodation(accommodationExpense, accommodationType) {
        if (accommodationType === Constants.LODGING) {
            this.leDataSource.data.push(accommodationExpense);
            this.leDataSource.data = this.leDataSource.data.slice();
            this.leRequestAccommodations.push(
                this.fb.group({
                    id: [accommodationExpense.id],
                    rowGuid: [accommodationExpense.rowGuid],
                    createdDate: [accommodationExpense.createdDate],
                    modifiedDate: [accommodationExpense.modifiedDate],
                    modifiedBy: [accommodationExpense.modifiedBy],
                    location: [accommodationExpense.location, Validators.required],
                    locationId: [accommodationExpense.locationId, Validators.required],
                    numberOfDays: [accommodationExpense.numberOfDays, Validators.required],
                    accommodationTypeId: [accommodationExpense.accommodationTypeId]
                })
            );
            this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.leObservableLodgingLocations = this.leRequestAccommodations.at(this.leRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
        } else if (accommodationType === Constants.MIE) {
            this.mieDataSource.data.push(accommodationExpense);
            this.mieDataSource.data = this.mieDataSource.data.slice();
            this.mieRequestAccommodations.push(
                this.fb.group({
                    id: [accommodationExpense.id],
                    rowGuid: [accommodationExpense.rowGuid],
                    createdDate: [accommodationExpense.createdDate],
                    modifiedDate: [accommodationExpense.modifiedDate],
                    modifiedBy: [accommodationExpense.modifiedBy],
                    location: [accommodationExpense.location, Validators.required],
                    locationId: [accommodationExpense.locationId, Validators.required],
                    numberOfDays: [accommodationExpense.numberOfDays, Validators.required],
                    accommodationTypeId: [accommodationExpense.accommodationTypeId]
                })
            );
            this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges.subscribe(selectedValue => {
                this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('locationId').setValue(selectedValue.id);
            });
            this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(this.mieRequestAccommodations.length - 1).get('location').valueChanges
                .pipe(
                    startWith<string | Location>(''),
                    map(value => typeof value === 'string' ? value : (value as Location).name),
                    map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                );
        }



    }

    getLookups() {
        this.lookupService.getLookups(Constants.CHARGE_CODE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.chargeTypes = resp.model;
                    if (this.advanceRequestForm && ! this.advanceRequestId) {
                        this.advanceRequestForm.get('chargeCodeId').setValue(this.chargeTypes[0].id)
                    }
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.ACCOMMODATION_TYPE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.accommodationTypes = resp.model;
                    this.MIEAccommocation = this.accommodationTypes.find(at => at.code === Constants.MIE);
                    this.LDGAccommocation = this.accommodationTypes.find(at => at.code === Constants.LODGING);
                    if (this.advanceRequestForm && !this.advanceRequestId) {
                        this.mieRequestAccommodations.controls[0].get('accommodationTypeId').setValue(this.MIEAccommocation.id)
                        this.leRequestAccommodations.controls[0].get('accommodationTypeId').setValue(this.LDGAccommocation.id)
                    }
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.PURPOSE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.otherExpensePurposeList = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.PRODUCT).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.products = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.REQUEST_TYPE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.requestTypes = resp.model;
                    const supportiveSupervisionRT = this.requestTypes.find(rt => rt.code === Constants.SUPPORTIVE_SUPERVISION_CODE);
                    if (supportiveSupervisionRT) {
                        this.advanceRequestForm.get('requestTypeId').setValue(supportiveSupervisionRT.id);
                    }
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLookups(Constants.SERVICE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.serviceTypes = resp.model;
                } else {
                    // TODO: log err
                }
            }, err => {
            }
        );
        this.lookupService.getLocationsWithType(Constants.VISIT_SITE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.sites = resp.model;
                }
            },
            err => {
            }
        );
        this.lookupService.getLocationsWithType(Constants.SITE).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.lodgingLocations = resp.model;
                    if (this.advanceRequestForm) {
                        this.leRequestAccommodations.at(0).get('location').valueChanges.subscribe(selectedValue => {
                            this.leRequestAccommodations.at(0).get('locationId').setValue(selectedValue.id);
                        });
                        this.leObservableLodgingLocations = this.leRequestAccommodations.at(0).get('location').valueChanges
                            .pipe(
                                startWith<string | Location>(''),
                                map(value => typeof value === 'string' ? value : (value as Location).name),
                                map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                            );
                        this.mieRequestAccommodations.at(0).get('location').valueChanges.subscribe(selectedValue => {
                            this.mieRequestAccommodations.at(0).get('locationId').setValue(selectedValue.id);
                        });
                        this.mieObservableLodgingLocations = this.mieRequestAccommodations.at(0).get('location').valueChanges
                            .pipe(
                                startWith<string | Location>(''),
                                map(value => typeof value === 'string' ? value : (value as Location).name),
                                map(location => location ? this._filter(location as string) : this.lodgingLocations.slice())
                            );
                    }
                }
            },
            err => {
            },
            () => {
            }
        )
    }

    getLocationTypeForLodgingExpense(index) {

        let locationType = '-';
        const lodgingExpense = this.leRequestAccommodations.value;
        if (lodgingExpense && lodgingExpense[index] && lodgingExpense[index].location) {
            const site = lodgingExpense[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType ?
                site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }

    getLodgingAllowanceForElement(index) {
        let lodgingAllowance = '';

        const lodgingExpense = this.leRequestAccommodations.value;
        if (lodgingExpense && lodgingExpense[index] && lodgingExpense[index].location) {
            const site = lodgingExpense[index].location;
            lodgingAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : '' : '';
        }
        return lodgingAllowance;
    }

    getMIEAllowanceForElement(index) {
        let mieAllowance = '';

        const mieExpenses = this.mieRequestAccommodations.value;
        if (mieExpenses && mieExpenses[index] && mieExpenses[index].location) {
            const site = mieExpenses[index].location;
            mieAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.mieAllowance : '' : '';
        }
        return mieAllowance;
    }

    getLocationTypeForMIEExpense(index) {

        let locationType = '-';
        const mieExpense = this.mieRequestAccommodations.value;
        if (mieExpense && mieExpense[index] && mieExpense[index].location) {
            const site = mieExpense[index].location;
            locationType = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType ?
                site.locationAllowance.locationAllowanceType.name : '-' : '-' : '-';
        }
        return locationType;
    }

    public checkError = (controlName: string, errorName: string) => {
        return this.advanceRequestForm.controls[controlName].hasError(errorName);
    };

    public checkREError = (index: number, controlName: string, errorName: string) => {
        return (this.requestExpenses.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };

    public checkROError = (index: number, controlName: string, errorName: string) => {
        return (this.requestObjectives.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };

    public checkLEError = (index: number, controlName: string, errorName: string) => {
        return (this.leRequestAccommodations.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };

    public checkMIEError = (index: number, controlName: string, errorName: string) => {
        return (this.mieRequestAccommodations.controls[index] as FormGroup).controls[controlName].hasError(errorName);
    };
    get totalLodgingDays() {
        let totalLodgingDays = 0;
        
        this.leRequestAccommodations.value.forEach(function (lodgingExpense) {
            if (lodgingExpense.numberOfDays) {
                totalLodgingDays += lodgingExpense.numberOfDays;
            }
        });
        return totalLodgingDays;
    }
    advanceFormAction(button) {
        const buttonName = button.name;

        const endDate = new Date(this.advanceRequestForm.get('startDate').value);
        endDate.setDate(endDate.getDate() + this.totalLodgingDays);
        this.advanceRequestForm.get('endDate').setValue(endDate);

        this.advanceRequestForm.value.requestAccommodations =
            this.mieRequestAccommodations.value.concat(this.leRequestAccommodations.value);
        delete this.advanceRequestForm.value.mieRequestAccommodations;
        delete this.advanceRequestForm.value.leRequestAccommodations;
        const advanceRequestToBeSaved = this.advanceRequestForm.value;
        advanceRequestToBeSaved.requestMeals = null;
        advanceRequestToBeSaved.requestLodgings = null;
        
        if (this.advanceRequestId) {
            advanceRequestToBeSaved.id = this.advanceRequest.id;
            advanceRequestToBeSaved.createdDate = this.advanceRequest.createdDate;
            advanceRequestToBeSaved.modifiedDate = this.advanceRequest.modifiedDate;
            advanceRequestToBeSaved.createdBy = this.advanceRequest.createdBy;
            advanceRequestToBeSaved.modifiedBy = this.advanceRequest.modifiedBy;
            advanceRequestToBeSaved.isActive = this.advanceRequest.isActive;
            advanceRequestToBeSaved.rowGuid = this.advanceRequest.rowGuid;
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.requestObjectives) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.requestObjectives[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.requestAccommodations) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.requestAccommodations[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
            for (let advanceRequestToBeSavedKey in advanceRequestToBeSaved.requestExpenses) {
                const advanceRequestToBeSavedElement = (advanceRequestToBeSaved.requestExpenses[advanceRequestToBeSavedKey]);
                if (advanceRequestToBeSavedElement.id) {
                    advanceRequestToBeSavedElement.createdDate = this.advanceRequest.createdDate;
                    advanceRequestToBeSavedElement.modifiedDate = this.advanceRequest.modifiedDate;
                    advanceRequestToBeSavedElement.createdBy = this.advanceRequest.createdBy;
                    advanceRequestToBeSavedElement.modifiedBy = this.advanceRequest.modifiedBy;
                    advanceRequestToBeSavedElement.isActive = this.advanceRequest.isActive;
                    advanceRequestToBeSavedElement.requestId = this.advanceRequest.id;
                }
            }
        }
        this.advanceRequestService.createAdvanceRequest(advanceRequestToBeSaved, buttonName,
            this.advanceRequestId ? this.advanceRequestId : '', null).subscribe(
            resp => {
                if (resp.result === 0) {
                    this.alertService.success(resp.message);
                    if (this.checkValidationRequired(button)) {
                        this.router.navigateByUrl('/advanceRequest/view/' + resp.model);
                    }
                } else {
                    this.alertService.error(resp.message)
                }
            }, err => {
                this.alertService.error('Error creating advance request. Please try again later.')
            }
        )
    }

    removeRow(rowType, rowIndex) {
        switch (rowType) {
            case 'objectives':
                this.poDataSource.data.splice(rowIndex, 1);
                this.poDataSource.data = this.poDataSource.data.slice();
                this.requestObjectives.removeAt(rowIndex);
                break;
            case 'MIE':
                this.mieDataSource.data.splice(rowIndex, 1);
                this.mieDataSource.data = this.mieDataSource.data.slice();
                this.mieRequestAccommodations.removeAt(rowIndex);

                break;
            case 'otherExpenses':
                this.oeDataSource.data.splice(rowIndex, 1);
                this.oeDataSource.data = this.oeDataSource.data.slice();
                this.requestExpenses.removeAt(rowIndex);

                break;
            case 'lodging':
                this.leDataSource.data.splice(rowIndex, 1);
                this.leDataSource.data = this.leDataSource.data.slice();
                this.leRequestAccommodations.removeAt(rowIndex);

                // remove MIE Row too
                this.mieDataSource.data.splice(rowIndex, 1);
                this.mieDataSource.data = this.mieDataSource.data.slice();
                this.mieRequestAccommodations.removeAt(rowIndex);
                
                break;
        }
    }

    checkValidationRequired(button) {
        if (button.options && button.options.Tags) {
            return button.options.Tags.indexOf('RequiresValidation') > -1;
        }
        return false
    }

    calculateLodgingAmount(index) {
        const lodgingExpenses = this.leRequestAccommodations.value;
        let lodgingAllowance = 0;
        let numberOfNights = 0;
        if (lodgingExpenses && lodgingExpenses[index] && lodgingExpenses[index].location) {
            const site = lodgingExpenses[index].location;
            lodgingAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.lodgingAllowance : '' : '';
            numberOfNights = lodgingExpenses[index].numberOfDays;
        }
        return lodgingAllowance * numberOfNights;
    }

    calculateMIEAmount(index) {
        const mieExpenses = this.mieRequestAccommodations.value;
        let mieAllowance = 0;
        let numberOfDays = 0;
        if (mieExpenses && mieExpenses[index] && mieExpenses[index].location) {
            const site = mieExpenses[index].location;
            mieAllowance = site ? site.locationAllowance ? site.locationAllowance.locationAllowanceType.mieAllowance : '' : '';
            numberOfDays = mieExpenses[index].numberOfDays;
        }
        return mieAllowance * numberOfDays;
    }

    calculateTotalOtherExpense() {
        let totalAmount = 0;

        if (this.requestExpenses) {
            for (let i = 0; i < this.requestExpenses.value.length; i++) {
                totalAmount += this.requestExpenses.value[i].cost;
            }
        }
        return totalAmount;
    }

    calculateTotalMIEExpense() {
        let totalAmount = 0;

        if (this.mieRequestAccommodations) {
            for (let i = 0; i < this.mieRequestAccommodations.value.length; i++) {
                if (this.mieRequestAccommodations.value[i].location && this.mieRequestAccommodations.value[i].location.locationAllowance) {
                    totalAmount += this.mieRequestAccommodations.value[i].numberOfDays *
                        this.mieRequestAccommodations.value[i].location.locationAllowance.locationAllowanceType.mieAllowance;
                }
            }
        }
        return totalAmount * .75;
    }

    calculateTotalLodgingExpense() {
        let totalAmount = 0;

        if (this.leRequestAccommodations) {
            for (let i = 0; i < this.leRequestAccommodations.value.length; i++) {
                if (this.leRequestAccommodations.value[i].location && this.leRequestAccommodations.value[i].location.locationAllowance) {
                    totalAmount += this.leRequestAccommodations.value[i].numberOfDays *
                        this.leRequestAccommodations.value[i].location.locationAllowance.locationAllowanceType.lodgingAllowance;
                }
            }
        }
        return totalAmount;
    }

    calculateTotalExpense() {
        return (this.calculateTotalLodgingExpense() + this.calculateTotalMIEExpense() + this.calculateTotalOtherExpense()) + ' Birr';
    }
}
