import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountService} from './account.service';
import {ResponseModel} from '../models/Common';
import {AdvanceRequest} from '../models/AdvanceRequest';
import {UserResponseModel} from '../models/User';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userURL = environment.baseUrl + '/User';

  constructor(private http: HttpClient, private accountService: AccountService) {
  }

  getSupervisorsList() {
    return this.http.get<ResponseModel<UserResponseModel[]>>(this.userURL, httpOptions);
  }
}
