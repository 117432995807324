<div class="app search-i" [dir]="layoutDir" [ngClass]="[ menuLayout, selectedSidebarImage, selectedSidebarColor, selectedHeaderColor, collapsedClass]" [class.app-dark]="dark" [class.boxed]="boxed" [class.collapsed-sidebar]="collapseSidebar" [class.compact-sidebar]="compactSidebar" [class.bg-img-disable]="!sidebarBg">
<mat-sidenav-container class="app-inner">
   <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" mode="side" [opened]="sideNavOpened" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <div class="opt-side relative ">
         <div class="sidebar-container ">
            <div class="branding text-center" style="display: inline-flex">
               <button (click)="menuToggleFunc()" mat-icon-button class="lines-btn">
                  <mat-icon>menu</mat-icon>
               </button>
               <h2><i class="fa fa-superpowers" style="color: #FFF !important; background-color: white !important;" aria-hidden="true"></i> <span style="color:#FFF"> DHA CPM</span>
               </h2>
            </div>
            <div class="main-nav">
               <mat-nav-list appAccordion class="navigation relative">
                  <mat-list-item appAccordionLink *ngFor="let menuitem of horizontalMenuItems.getAll()">

                     <a appAccordionToggle [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''" class="relative" mat-ripple [routerLink]="['/'+ menuitem.state]" *ngIf="menuitem.type === 'link'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                        <span fxFlex></span>
                        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                     </a>
                     <a appAccordionToggle [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''" class="relative" mat-ripple href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                        <span fxFlex></span>
                        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                     </a>
                     <a appAccordionToggle [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''" class="relative" mat-ripple href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                        <span fxFlex></span>
                        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                     </a>
                     <a appAccordionToggle [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''" class="relative" mat-ripple href="javascript:;" *ngIf="menuitem.type === 'sub'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                        <span fxFlex></span>
                        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
                     </a>
                     <mat-nav-list *ngIf="menuitem.type === 'sub'">
                        <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
                           <a [ngClass]="isActive(childitem)? 'active-horizontal-menu-item': ''" [routerLink]="['/'+ childitem.state ]" class="relative" mat-ripple>{{ childitem.name | translate }}</a>
                        </mat-list-item>
                     </mat-nav-list>
                  </mat-list-item>
               </mat-nav-list>
            </div>
         </div>
      </div>
      <div class="bg-overlay"></div>
   </mat-sidenav>
   <mat-toolbar class="main-header" style="background: rgb(195 0 4) !important; height: 45px;   font-size: revert;   color: white;">
      <div class="horizontal-top-bar w-100">
        <!-- <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
         </div> -->
         <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
          <div class="horizontal-logo">
            <div class="branding text-center">
               <h2 style="font-size: revert;"><i class="fa fa-superpowers" style="color: #FFF;" aria-hidden="true"></i> <span style="color:#FFF"> DHA CPM</span>
               </h2>
            </div>
         </div>
         </div>
         <div *ngIf="!hideMenuForFirstTimeLogin" class="horizontal-menu text-center">
            <nav>
               <ul class="main-h-list">
                  <li *ngFor="let menuitem of horizontalMenuItems.getAll()"  class="font-weight-bold">
                     <a [routerLink]="['/'+menuitem.state]" [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''"  *ngIf="menuitem.type === 'link'">
                     <mat-icon>{{ menuitem.icon }}</mat-icon>
                     <span>{{ menuitem.name | translate }}</span>
                     </a>
                     <a [ngClass]="isActive(menuitem)? 'active-horizontal-menu-item': ''" [routerLink]="['/'+menuitem.state]" *ngIf="menuitem.type === 'sub'">
                     <mat-icon>{{ menuitem.icon }}</mat-icon>
                     <span>{{ menuitem.name | translate }}</span>
                         <mat-icon>arrow_right</mat-icon>
      
                     </a>
                     <ul class="dropdown" *ngIf="menuitem.type === 'sub'">
                        <li *ngFor="let childitem of menuitem.children">
                           <a [ngClass]="isActive(childitem)? 'active-horizontal-menu-item': ''"
                              [routerLink]="['/'+childitem.state ]">{{ childitem.name | translate }}</a>
                           <ul class="dropdown" *ngIf="childitem.type === 'super-sub'">
                              <li *ngFor="let subchild of childitem.subchildren">
                                 <a [ngClass]="isActive(subchild)? 'active-horizontal-menu-item': ''"
                                    [routerLink]="['/'+subchild.state ]">{{subchild.name | translate}}</a>
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </li>
               </ul>
            </nav>
         </div>
         <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
         <div class="menu-links">
            <h3>
               {{user.userName}}
            </h3>
            <button [mat-menu-trigger-for]="user" mat-icon-button class="ml-xs">
               <i mat-card-avatar class="small-avatar">
                  {{getUserInitials()}}
               </i>
            </button>
            <mat-menu #user="matMenu" class="opt-menu" x-position="before">
               <button mat-menu-item (click)="viewProfile()">
                  <mat-icon>account_box</mat-icon>
                  Profile
               </button>
               <button mat-menu-item (click)="logout()">
                  <mat-icon>exit_to_app</mat-icon>
                  Sign Out
               </button>
            </mat-menu>
         </div>
        </div>
      </div>
      <div class="vertical-menu w-100">
         <button (click)="menuToggleFunc()" mat-icon-button class="lines-btn">
            <mat-icon>menu</mat-icon>
         </button>
         <span fxFlex></span>
         <div class="menu-links">
            <h3>
               {{user.userName}}
            </h3>
            <button [mat-menu-trigger-for]="user" mat-icon-button class="ml-xs">
               <i mat-card-avatar class="small-avatar">
                  {{getUserInitials()}}
               </i>
            </button>
            <mat-menu #user="matMenu" class="opt-menu" x-position="before">
               <button mat-menu-item (click)="viewProfile()">
                  <mat-icon>account_box</mat-icon>
                  Profile
               </button>
               <button mat-menu-item (click)="logout()">
                  <mat-icon>exit_to_app</mat-icon>
                  Sign Out
               </button>
            </mat-menu>
         </div>

      </div>

   </mat-toolbar>
   
   <div class="body-container">
      <router-outlet></router-outlet>
   </div>
</mat-sidenav-container>
</div>

