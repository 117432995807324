import { Injectable } from '@angular/core';
import {ResponseModel} from '../models/Common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountService} from './account.service';
import {environment} from '../../environments/environment';
import {AdvanceRequest} from '../models/AdvanceRequest';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdvanceRequestService {

  public advanceRequestWorkflowURL = environment.baseUrl + '/Workflow?Name=Request';
  public advanceRequestWorkflowCommentURL = environment.baseUrl + '/Workflow/Comments?Name=Request';
  public advanceRequestURL = environment.baseUrl + '/Request';

  public expenseWorkflowURL = environment.baseUrl + '/Workflow?Name=Expense';

  constructor(private http: HttpClient, private accountService: AccountService) {
  }

  createAdvanceRequest(advanceRequest, buttonName, advanceRequestId, comment) {
    let encodedComment = '';
    if (comment) {
      encodedComment = encodeURIComponent(comment);
    }
    return this.http.post<ResponseModel<AdvanceRequest>>(this.advanceRequestWorkflowURL + '&Button='
        + buttonName + '&id=' + advanceRequestId + '&comment=' + encodedComment, advanceRequest, httpOptions);
  }
  createExpense(expenseRequest, buttonName, expenseId, comment) {
    let encodedComment = '';
    if (comment) {
      encodedComment = encodeURIComponent(comment);
    }
    return this.http.post<ResponseModel<AdvanceRequest>>(this.expenseWorkflowURL + '&Button='
        + buttonName + '&id=' + expenseId + '&comment=' + encodedComment, expenseRequest, httpOptions);
  }
    getAdvanceRequestWorkflow(advanceRequestId) {
      return this.http.get<ResponseModel<any>>(this.advanceRequestWorkflowURL + '&id=' +  advanceRequestId, httpOptions);
  }
    getAdvanceRequest(advanceRequestId) {
      return this.http.get<ResponseModel<AdvanceRequest>>(this.advanceRequestURL + '/' +  advanceRequestId, httpOptions);
  }
  getAdvanceRequestListCreatedByUser() {
    return this.http.get<ResponseModel<AdvanceRequest[]>>(this.advanceRequestURL + '?createdByUser=true', httpOptions);
  }
  getAdvanceRequestListToBeApprovedByUser() {
    return this.http.get<ResponseModel<AdvanceRequest[]>>(this.advanceRequestURL + '?createdByUser=false', httpOptions);
  }
  getAdvanceRequestCommentList(advanceRequestId) {
    return this.http.get<any>(this.advanceRequestWorkflowCommentURL + '&id=' + advanceRequestId, httpOptions);
  }
  
  getExpenseWorkflow(expenseId) {
    return this.http.get<ResponseModel<any>>(this.expenseWorkflowURL + '&id=' + expenseId, httpOptions);
  }

}
