import { Component, OnInit } from '@angular/core';
import * as Constants from '../../models/Constants';
import {LookupService} from '../../services/lookup.service';
import {MatTableDataSource} from '@angular/material/table';
import {LookupModel} from '../../models/Common';
import {RequestAccommodationsModel} from '../../models/AdvanceRequest';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-lookup-configuration',
  templateUrl: './lookup-configuration.component.html',
  styleUrls: ['./lookup-configuration.component.css']
})
export class LookupConfigurationComponent implements OnInit {

  public lookupTypes: LookupModel[];
  public _selectedLookupType: LookupModel;
  public dataSource: MatTableDataSource <LookupModel>;
  public displayedColumns: string[] = ['position', 'name', 'code', 'actions'];

  constructor(private lookupService: LookupService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.getLookupTypes()
  }
  set selectedLookupType(lookup: LookupModel) {
    this._selectedLookupType = lookup;
    this.getLookupValues();
  }
  get selectedLookupType() {
    return this._selectedLookupType;
  }
  getLookupTypes() {
    this.lookupService.getLookupTypes().subscribe(
        resp => {
          if (resp.result === 0) {
            this.lookupTypes = resp.model;
            this.selectedLookupType = this.lookupTypes[0];
          }
        }, err => {
        }
    );
  }
  getLookupValues() {
    this.lookupService.getLookups(this.selectedLookupType.code).subscribe(
        resp => {
          if (resp.result === 0) {
            this.dataSource = new MatTableDataSource<LookupModel>(resp.model);
          }
        }, err => {
        }
    );
  }
  selectLookup(lookup) {
    if (lookup.code !== this.selectedLookupType.code) {
      this.selectedLookupType = lookup;
      this.getLookupValues();
    }
  }

  addNewLookup() {
    this.dataSource.data.push(new LookupModel());
    this.dataSource.data = this.dataSource.data.slice();
  }

  saveNewLookup() {
    const lookupToBeSaved = this.dataSource.data.find(lookup => !lookup.id);
    this.lookupService.createLookup(this.selectedLookupType.code, lookupToBeSaved).subscribe(
        resp => {
          if (resp.result === 0) {
            this.alertService.success('Successfully created lookup.')
          } else {
            this.alertService.error('Unable to create lookup. Please try again later.')
          }
        }, err => {
          this.alertService.error('Unable to create lookup. Please try again later.')

        }
    )
  }

}
