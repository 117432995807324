import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {ChangePasswordModel, PasswordResetModel, PasswordResetRequestModel, User, UserResponseModel} from '../../models/User';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

public user: User;
public username: string;
public changePasswordForm: FormGroup;
public hide = true;
public errors: any[];

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private accountService: AccountService,
              private alertService: AlertService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.user = this.accountService.getUserData();
  this.username = this.user.userName;
  this.changePasswordForm = this.fb.group ({
    currentPassword: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  })
}

  setPassword() {
    if (!this.showMismatchError) {
      const changePasswordModel = new ChangePasswordModel();

      changePasswordModel.newPassword = this.changePasswordForm.controls.password.value;
      changePasswordModel.currentPassword = this.changePasswordForm.controls.currentPassword.value;
      changePasswordModel.username = this.username;
      this.accountService.changePassword(changePasswordModel).subscribe(
          resp => {
            if (resp.succeeded) {
              this.alertService.success('Successfully changed password.');
              if (this.user.isFirstLogin) {
                this.router.navigateByUrl('/settings/profile/edit');
              } else {
                this.router.navigateByUrl('/profile/view')
              }

            } else {
              this.errors = resp.errors;
              this.alertService.error('Unable to change password. Please check your input and try again later.')
            }
          },
          err => {
            this.alertService.error('Unable to change password. Please check your input and try again later.')
          }
      );
    } else {
    }
  }

  get showMismatchError() {
    const notMatched: boolean = this.changePasswordForm.controls.confirmPassword.value !==
        this.changePasswordForm.controls.password.value;

    if (!notMatched) {
      this.changePasswordForm.controls.confirmPassword.setErrors(null);
    } else {
      this.changePasswordForm.controls.confirmPassword.setErrors({
        notMatched: true
      });
    }
    return notMatched;
  }

}
