import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventRequest } from '../../models/EventRequest';
import { EventService } from '../../services/event.service';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookupService } from '../../services/lookup.service';
import { LookupModel, OtherCost, ResponseModel, TotalCost } from 'app/models/Common';
import { disableOptions } from 'app/util/util';
import {
  TrainerClassification,
  TraineeClassification,
  PER_DIEM_RATE_FLAT_TRAINEE,
  PER_DIEM_RATE_HOTEL_TRAINEE,
  PER_DIEM_RATE_FLAT_TRAINER,
  PER_DIEM_RATE_HOTEL_TRAINER,
  AVG_AIR_TRAVEL_COST,
  AVG_GROUND_TRAVEL_COST,
  OTHER_COST_PER_TRAINER,
  MEETING_MATERIAL_PRINTING,
  REFRESMENT_PER_PERSON_PER_DAY,
  VENUE_COST_PER_DAY,
  STATIONARY_COST_PER_PERSON
} from 'app/constants/constants';

@Component({
  selector: 'app-event-request',
  templateUrl: './event-request.component.html',
  styleUrls: ['./event-request.component.css'],
})

export class EventRequestComponent implements OnInit {
  public loading: boolean;
  public eventRequestForm: FormGroup;
  public trainerTraineeForm: FormGroup;
  // public sessionForm: FormGroup;
  public lookups: ResponseModel<LookupModel[]>;
  public transportationAllowance = 100;
  public minStartDate: Date;
  public minEndDate: Date;
  public disableEndDate = true;
  public parDiem = 200;
  public editMode = false;
  public eventRequestId: number;
  public eventDetail = {};
  // public venueDisabled = true;
  public projects = [];
  public eventTypes = [];
  public hostInstitutions = [];
  public workPlanActivities = [];
  public cities = [];
  public testLookups = [
    { id: 1, name: 'a', disabled: false },
    { id: 3, name: 'b', disabled: false },
    { id: 2, name: 'c', disabled: false },
  ];
  public testVenues = [
    { id: 1, name: 'Dreamland Resort', disabled: false },
    { id: 3, name: 'East Gate Hotel', disabled: false },
    { id: 2, name: 'Hayatt regency', disabled: false },
  ];
  public travelDays = [
    { id: 1, name: '1', value: 1, disabled: false },
    { id: 3, name: '1.5', value: 1.5, disabled: false },
    { id: 2, name: '2', value: 2, disabled: false },
  ];
  public byInstitution = [
    { id: 1, name: 'AA RHB', disabled: false },
    { id: 2, name: 'Oromia RHB', disabled: false },
    { id: 3, name: 'PFSA', disabled: false }
  ];
  public byJobClassification = [
    { id: 1, name: 'Medical Director', disabled: false },
    { id: 2, name: 'HIT', disabled: false }
  ];
  public byInstitutionTrainee = [
    { id: 1, name: 'AA RHB', disabled: false },
    { id: 2, name: 'Oromia RHB', disabled: false },
    { id: 3, name: 'PFSA', disabled: false }
  ];
  public trainingQuarters = [
    { id: 1, name: 'Q1', disabled: false },
    { id: 2, name: 'Q2', disabled: false },
    { id: 3, name: 'Q3', disabled: false },
    { id: 4, name: 'Q4', disabled: false },
  ];
  public byJobClassificationTrainee = [
    { id: 1, name: 'Medical Director', disabled: false },
    { id: 2, name: 'HIT', disabled: false }
  ];
  public usersList = [
    { id: 1, name: 'Mike', disabled: false },
    { id: 3, name: 'Girum', disabled: false },
    { id: 2, name: 'Rahel', disabled: false },
    { id: 3, name: 'Rute', disabled: false },
    { id: 2, name: 'Tselote', disabled: false }
  ];
  public currentUser;
  public isLinear = false;
  public step = 0;
  public pocTypes = [];
  public defaultPocType = null;

  public totalTrainer = {
    starterForm: TrainerClassification.Initial,
    value: 0
  };
  public totalTrainee = {
    starterForm: TraineeClassification.Initial,
    value: 0
  };

  public totalTrainerCost = new TotalCost;
  public totalTraineeCost = new TotalCost;
  public totalOtherCost = new OtherCost;

  public trainerByJob = 0;
  public trainerByInstitution = 0;
  public traineeByJob = 0;
  public traineeByInstitution = 0;
  public totalAttendees = 0;
  public noHotelDaysToBudget = 0;
  actualTrainingDays = 0;
  hotelDays = 0;
  // booleans
  trainerValuesMatch = true
  traineeValuesMatch = true
  public disableTotalAttendee = true;
  public flatRateDisabled = false;
  public nonFlatRateDisabled = false;
  isVenuEditMode = false;
  // constants
  public PER_DIEM_RATE_FLAT_TRAINEE = PER_DIEM_RATE_FLAT_TRAINEE
  public PER_DIEM_RATE_HOTEL_TRAINEE = PER_DIEM_RATE_HOTEL_TRAINEE
  public PER_DIEM_RATE_FLAT_TRAINER = PER_DIEM_RATE_FLAT_TRAINER
  public PER_DIEM_RATE_HOTEL_TRAINER = PER_DIEM_RATE_HOTEL_TRAINER
  public AVG_AIR_TRAVEL_COST = AVG_AIR_TRAVEL_COST
  public AVG_GROUND_TRAVEL_COST = AVG_GROUND_TRAVEL_COST
  public OTHER_COST_PER_TRAINER = OTHER_COST_PER_TRAINER
  public MEETING_MATERIAL_PRINTING = MEETING_MATERIAL_PRINTING
  public REFRESMENT_PER_PERSON_PER_DAY = REFRESMENT_PER_PERSON_PER_DAY
  public VENUE_COST_PER_DAY = VENUE_COST_PER_DAY
  public STATIONARY_COST_PER_PERSON = STATIONARY_COST_PER_PERSON

  setStep(numIndex: number) {
    this.step = numIndex;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private lookupService: LookupService
  ) {

    this.getInitialLookups();

    this.currentUser = JSON.parse(localStorage.getItem('user'));
    // Forms
    this.eventRequestForm = this.createEventRequestForm(null);
    this.trainerTraineeForm = this.createTraineeTrainerForm(null);
    // this.sessionForm = this.createSessionForm(null);

    const currentDate = new Date();
    this.minStartDate = currentDate;
    this.minEndDate = currentDate;
  }

  ngOnInit(): void {
    this.eventRequestId = this.route.snapshot.params['id'];
    this.isVenuEditMode = this.route.snapshot.params['venue']? true: false;
    if (this.eventRequestId) {
      this.initiateEditMode(this.eventRequestId);
    }

    this.eventRequestForm.get('noActualTrainingDays').valueChanges.subscribe(async value => {
      this.calculateFinancialDetails();
    });

    this.eventRequestForm.get('estimatedOneWayTravelDays').valueChanges.subscribe(async value => {
      this.calculateFinancialDetails();
    });

    this.eventRequestForm
      .valueChanges
      .subscribe(x => {
        this.calculateOtherCost()
      })

    const institutionTrainers = this.trainerTraineeForm.controls.institutionTrainer as FormArray

    institutionTrainers
      .valueChanges
      .subscribe(x => {
        this.totalTrainer.starterForm = this.totalTrainer.starterForm == TrainerClassification.ByInstitution
          || this.totalTrainer.starterForm == TrainerClassification.Initial
          ? TrainerClassification.ByInstitution : TrainerClassification.ByJobClassification
        this.trainerByInstitution = 0
        for (let index = 0; index < institutionTrainers.controls.length; index++) {
          const element = institutionTrainers.controls[index]['controls'];
          this.trainerByInstitution += +element['numberOfInstitutionTrainer'].value
        }
        if (this.totalTrainer.starterForm == TrainerClassification.ByInstitution) {
          this.totalTrainer.value = this.trainerByInstitution
        }
        this.checkTrainerDescrepancy();
        disableOptions('institutionTrainerType', this.institutionTrainer.value, this.byInstitution);
        this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
        this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
        this.calculateTotalTrainerCost()
      });

    const institutionTrainees = this.trainerTraineeForm.controls.institutionTrainee as FormArray

    institutionTrainees
      .valueChanges
      .subscribe(x => {
        this.totalTrainee.starterForm = this.totalTrainee.starterForm == TraineeClassification.ByInstitution
          || this.totalTrainee.starterForm == TraineeClassification.Initial
          ? TraineeClassification.ByInstitution : TraineeClassification.ByJobClassification
        this.traineeByInstitution = 0
        for (let index = 0; index < institutionTrainees.controls.length; index++) {
          const element = institutionTrainees.controls[index]['controls'];
          this.traineeByInstitution += +element['numberOfInstitutionTrainee'].value
        }
        if (this.totalTrainee.starterForm == TraineeClassification.ByInstitution) {
          this.totalTrainee.value = this.traineeByInstitution
        }
        this.checkTraineeDescrepancy();
        disableOptions('institutionTraineeType', this.institutionTrainee.value, this.byInstitutionTrainee);
        this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
        this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
        this.calculateTotalTraineeCost()
      });

    const jobTrainers = this.trainerTraineeForm.controls.jobTrainer as FormArray;

    jobTrainers
      .valueChanges
      .subscribe(x => {
        this.totalTrainer.starterForm = this.totalTrainer.starterForm == TrainerClassification.ByJobClassification
          || this.totalTrainer.starterForm == TrainerClassification.Initial
          ? TrainerClassification.ByJobClassification : TrainerClassification.ByInstitution
        this.trainerByJob = 0
        for (let index = 0; index < jobTrainers.controls.length; index++) {
          const element = jobTrainers.controls[index]['controls'];
          this.trainerByJob += +element['numberOfJobTrainer'].value
        }
        if (this.totalTrainer.starterForm == TrainerClassification.ByJobClassification) {
          this.totalTrainer.value = this.trainerByJob
        }
        this.checkTrainerDescrepancy();
        disableOptions('jobTrainerType', this.jobTrainer.value, this.byJobClassification);
        this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
        this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
        this.calculateTotalTrainerCost()
      });

    const jobTrainees = this.trainerTraineeForm.controls.jobTrainee as FormArray;

    jobTrainees
      .valueChanges
      .subscribe(x => {
        this.totalTrainee.starterForm = this.totalTrainee.starterForm == TraineeClassification.ByJobClassification
          || this.totalTrainee.starterForm == TraineeClassification.Initial
          ? TraineeClassification.ByJobClassification : TraineeClassification.ByInstitution
        this.traineeByJob = 0
        for (let index = 0; index < jobTrainees.controls.length; index++) {
          const element = jobTrainees.controls[index]['controls'];
          this.traineeByJob += +element['numberOfJobTrainee'].value
        }
        if (this.totalTrainee.starterForm == TraineeClassification.ByJobClassification) {
          this.totalTrainee.value = this.traineeByJob
        }
        this.checkTraineeDescrepancy();
        disableOptions('jobTraineeType', this.jobTrainee.value, this.byJobClassificationTrainee);
        this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
        this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
        this.calculateTotalTraineeCost()
      });
  }

  calculateFinancialDetails() {
    const estimatedOneWayTravelDays = +this.eventRequestForm.get('estimatedOneWayTravelDays').value;
    const estimatedTotalTravelDays = +this.getOptionName(estimatedOneWayTravelDays, this.travelDays, false).value * 2;
    const actualTrainingDays = this.eventRequestForm.get('noActualTrainingDays').value;

    const finalTrainingDays = estimatedTotalTravelDays + actualTrainingDays;
    this.eventRequestForm.get('totalTrainingDays').setValue(finalTrainingDays);
    this.eventRequestForm.get('noHotelDaysBudget').setValue((actualTrainingDays + estimatedTotalTravelDays) - 1);
  }

  SaveForm() {
    this.router.navigateByUrl('/events/view/' + this.eventRequestId);
  }
  
  getInitialLookups() {
    this.lookupService.getProjects().subscribe(resp => {
      this.projects = resp.response.items;
    });
    this.lookupService.getEventTypes().subscribe(resp => {
      this.eventTypes = resp.response.items;
    });
    this.lookupService.getInstitutions().subscribe(resp => {
      this.hostInstitutions = resp.response.items;
    });
    this.lookupService.getWorkPlanActivities().subscribe(resp => {
      this.workPlanActivities = resp.response.items;
    });
    // this.lookupService.getCity().subscribe(resp => {
    //   this.cities = resp.response.items;
    // });
    this.lookupService.getPocType().subscribe(resp => {
      this.pocTypes = resp.response.items;

      // const requestPocType = this.pocTypes.filter(inputData => {
      //   return inputData.data.slug === 'req';
      // })[0];

      // const pocControls = <FormArray>this.eventRequestForm.controls['poc'];
      // pocControls.value[0].pocType = requestPocType.id;
      // console.log(pocControls.value[0].pocType);

    });
  }

  createEventRequestForm(data) {
    return this.fb.group({
      eventName: [{ value: data ? data.eventName : null, disabled: this.editMode }, [Validators.required, Validators.minLength(5)]],
      eventType: [{ value: data ? data.eventType : null, disabled: this.editMode }, [Validators.required]],
      eventSummary: [{ value: data ? data.eventSummary : null, disabled: this.editMode }, [Validators.required, Validators.minLength(100)]],
      eventDescription: [{ value: data ? data.eventDescription : null, disabled: this.editMode },
      [Validators.required, Validators.minLength(50)]],
      trainingQuarter: [{ value: data ? data.Quarter : null, disabled: this.editMode }, [Validators.required]],
      startDate: [{ value: data ? data.startDate : null, disabled: this.editMode }, [Validators.required]],
      endDate: [{ value: data ? data.endDate : null, disabled: this.editMode }, [Validators.required]],
      hostInstitution: [{ value: data ? data.hostInstitution : null, disabled: this.editMode }, [Validators.required]],
      toInstitution: [{ value: data ? data.toInstitution : null, disabled: this.editMode }, [Validators.required]],
      project: [{ value: data ? data.project : null, disabled: this.editMode }, [Validators.required]],
      subContractor: [{ value: data ? data.project : null, disabled: this.editMode }, [Validators.required]],
      workPlanActivity: [{ value: data ? data.workPlanActivity : null, disabled: this.editMode }, [Validators.required]],
      breakfastProvided: [{ value: data ? data.breakfastProvided : 0, disabled: this.editMode }],
      lunchProvided: [{ value: data ? data.lunchProvided : 0, disabled: this.editMode }],
      dinnerProvided: [{ value: data ? data.dinnerProvided : 0, disabled: this.editMode }],
      refreshmentProvided: [{ value: data ? data.refreshmentProvided : 1, disabled: this.editMode }],
      stationaryCost: [{ value: data ? data.stationaryCost : 0, disabled: this.editMode }],
      airTimeCost: [{ value: data ? data.airTimeCost : 0, disabled: this.editMode }],
      venueId: [data ? data.venueId : null],
      cityId: [{ value: data ? data.cityId : null, disabled: this.editMode }, [Validators.required]],
      poc: this.fb.group({
        eventRequester: [{ value: 1, disabled: true }],
        eventApprover: [{ value: data ? data.eventApprover : 0, disabled: this.editMode }, [Validators.required]],
        programOfficer: [{ value: data ? data.programOfficer : 0, disabled: this.editMode }, [Validators.required]],
        meetingManager: [{ value: data ? data.meetingManager : 0, disabled: this.editMode }, [Validators.required]],
        finance: [{ value: data ? data.finance : 0, disabled: this.editMode }, [Validators.required]],
      }),
      noActualTrainingDays: [{ value: data ? data.noActualTrainingDays : 0, disabled: this.editMode }, [Validators.required]],
      totalHotelDays: [{ value: data ? data.totalHotelDays : 0, disabled: this.editMode }, [Validators.required]],
      estimatedOneWayTravelDays: [{ value: data ? data.estimatedOneWayTravelDays : 0, disabled: this.editMode }, [Validators.required]],
      noHotelDaysBudget: [{ value: 0, disabled: true }],
      totalTrainingDays: [{ value: this.totalAttendees, disabled: true }],
      noUniqueSessions: [{ value: data ? data.noUniqueSessions : 0, disabled: this.editMode }, [Validators.required]],
    });
  }

  createTraineeTrainerForm(data) {
    return this.fb.group({
      institutionTrainer: this.fb.array([this.fb.group({
        institutionTrainerType: null,
        numberOfInstitutionTrainer: null,
      })]),
      jobTrainer: this.fb.array([this.fb.group({
        jobTrainerType: null,
        numberOfJobTrainer: null,
      })]),
      institutionTrainee: this.fb.array([this.fb.group({
        institutionTraineeType: null,
        numberOfInstitutionTrainee: null,
      })]),
      jobTrainee: this.fb.array([this.fb.group({
        jobTraineeType: null,
        numberOfJobTrainee: null,
      })]),
      session: this.fb.array([this.fb.group({
        city: [{ value: null, disabled: this.editMode }],
        venue: [{ value: null, disabled: !this.isVenuEditMode }],
        meetingManager: [{ value: null, disabled: this.editMode }],
        sessionManager: [{ value: null, disabled: this.editMode }],
        numberOfTrainers: [{ value: null, disabled: this.editMode }],
        numberOfTrainees: [{ value: null, disabled: this.editMode }],
      })]),
      numberOfAirTravelers: 0,
      mAndE: [{ value: '', disabled: this.flatRateDisabled }],
      lodging: [{ value: '', disabled: this.flatRateDisabled }],
      flatRate: [{ value: '', disabled: this.flatRateDisabled }],
      totalAttendees: [{ value: this.totalAttendees, disabled: true }]    
    })
  }

  // createSessionForm(data) {
  //   return this.fb.group({
  //     session: this.fb.array([this.fb.group({
  //       city: null,
  //       meetingManager: null,
  //       sessionManager: null,
  //       numberOfTrainers: null,
  //       numberOfTrainees: null,
  //     })])
  //   })
  // }
  get institutionTrainer() {
    return this.trainerTraineeForm.get('institutionTrainer') as FormArray;
  }

  checkAddHidden(formName: string, jobIndex: number) {
    const isHidden = this.trainerTraineeForm.controls[formName]['controls'].length - jobIndex != 1 &&
      this.trainerTraineeForm.controls[formName]['controls'].length > 1
    return isHidden
  }

  checkDeleteHidden(formName: string, jobIndex: number) {
    const isHidden = this.trainerTraineeForm.controls[formName]['controls'].length == 1
    return isHidden
  }

  getOptionName(id: number, optionArray: any[], nameOnly = true) {
    let optionValue = optionArray.find(t => t.id == id)
    return nameOnly ? (optionValue != null ? optionValue.name : '') : optionValue
  }

  calculateTotalTrainerCost() {
    this.totalTrainerCost.perDiemFlat = this.trainerByInstitution * PER_DIEM_RATE_FLAT_TRAINER
    this.totalTrainerCost.perDiemHotel = this.trainerByInstitution * PER_DIEM_RATE_HOTEL_TRAINER
    this.totalTrainerCost.transport = ((this.trainerTraineeForm.value['numberOfAirTravelers'] != null ?
      this.trainerTraineeForm.value['numberOfAirTravelers'] : 0) *
      AVG_AIR_TRAVEL_COST) +
      ((this.eventRequestForm.value['estimatedOneWayTravelDays'] != null ?
        this.eventRequestForm.value['estimatedOneWayTravelDays'] : 0) *
        AVG_GROUND_TRAVEL_COST * this.trainerByInstitution)
    this.totalTrainerCost.total = this.totalTrainerCost.perDiemFlat + this.totalTrainerCost.perDiemHotel + this.totalTrainerCost.transport
    this.calculateOtherCost()
  }

  calculateTotalTraineeCost() {
    this.totalTraineeCost.perDiemFlat = this.traineeByInstitution * PER_DIEM_RATE_FLAT_TRAINEE
    this.totalTraineeCost.perDiemHotel = this.traineeByInstitution * PER_DIEM_RATE_HOTEL_TRAINEE
    this.totalTraineeCost.transport = ((this.eventRequestForm.value['estimatedOneWayTravelDays'] != null ?
      this.eventRequestForm.value['estimatedOneWayTravelDays'] : 0) *
      AVG_GROUND_TRAVEL_COST * this.traineeByInstitution)
    this.totalTraineeCost.total = this.totalTraineeCost.perDiemFlat + this.totalTraineeCost.perDiemHotel + this.totalTraineeCost.transport
    this.calculateOtherCost()
  }

  calculateOtherCost() {
    this.totalOtherCost.venue = ((this.eventRequestForm.value['noActualTrainingDays'] != null ?
      this.eventRequestForm.value['noActualTrainingDays'] : 0) *
      VENUE_COST_PER_DAY)
    this.totalOtherCost.stationary = STATIONARY_COST_PER_PERSON * this.totalAttendees
    this.totalOtherCost.refreshment = REFRESMENT_PER_PERSON_PER_DAY * this.totalAttendees
    this.totalOtherCost.total = this.totalOtherCost.venue + this.totalOtherCost.stationary + this.totalOtherCost.refreshment
  }

  addInstitutionTrainer(trainerIndex) {
    const trainers = this.trainerTraineeForm.controls.institutionTrainer as FormArray;
    trainers.push(this.fb.group({
      institutionTrainerType: null,
      numberOfInstitutionTrainer: null
    }));
  }

  addSession() {
    const session = this.trainerTraineeForm.controls.session as FormArray;
    session.push(this.fb.group({
      city: null,
      meetingManager: null,
      sessionManager: null,
      numberOfTrainers: null,
      numberOfTrainees: null,
    }));
  }

  removeSession(index) {
    this.session.removeAt(index);
  }

  removeInstitutionTrainer(instIndex) {
    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);

    this.institutionTrainer.removeAt(instIndex);
    this.checkTrainerDescrepancy();
    disableOptions('institutionTrainerType', this.institutionTrainer.value, this.byInstitution);
  }

  checkTrainerDescrepancy() {
    this.trainerValuesMatch = this.trainerByInstitution - this.trainerByJob == 0 ||
      this.trainerByInstitution == 0 || this.trainerByJob == 0
      ? true : false;
  }

  checkTraineeDescrepancy() {
    this.traineeValuesMatch = this.traineeByInstitution - this.traineeByJob == 0 ||
      this.traineeByInstitution == 0 || this.traineeByJob == 0
      ? true : false;
  }

  get jobTrainer() {
    return this.trainerTraineeForm.get('jobTrainer') as FormArray;
  }

  addJobTrainer(trainerIndex) {
    const trainers = this.trainerTraineeForm.controls.jobTrainer as FormArray;

    trainers.push(this.fb.group({
      jobTrainerType: null,
      numberOfJobTrainer: null
    }));

    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
    this.checkTrainerDescrepancy();
    disableOptions('jobTrainerType', this.jobTrainer.value, this.byJobClassification);
  }

  removeJobTrainer(trainerIndex) {
    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);

    this.jobTrainer.removeAt(trainerIndex);
    this.checkTrainerDescrepancy();
    disableOptions('jobTrainerType', this.jobTrainer.value, this.byJobClassification);
  }

  get institutionTrainee() {
    return this.trainerTraineeForm.get('institutionTrainee') as FormArray;
  }
  get session() {
    return this.trainerTraineeForm.get('session') as FormArray;
  }

  addInstitutionTrainee(traineeIndex) {
    const trainees = this.trainerTraineeForm.controls.institutionTrainee as FormArray;
    trainees.push(this.fb.group({
      institutionTraineeType: null,
      numberOfInstitutionTrainee: null
    }));

    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);

  }

  removeInstitutionTrainee(traineeIndex) {
    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);

    this.institutionTrainee.removeAt(traineeIndex);
    this.checkTraineeDescrepancy();
    disableOptions('institutionTraineeType', this.institutionTrainee.value, this.byInstitution);
  }

  get jobTrainee() {
    return this.trainerTraineeForm.get('jobTrainee') as FormArray;
  }

  addJobTrainee(traineeIndex) {
    const trainees = this.trainerTraineeForm.controls.jobTrainee as FormArray;

    trainees.push(this.fb.group({
      jobTraineeType: null,
      numberOfJobTrainee: null
    }));

    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);
    this.checkTraineeDescrepancy();
    disableOptions('jobTraineeType', this.jobTrainee.value, this.byJobClassificationTrainee);
  }

  removeJobTrainee(traineeIndex) {
    this.totalAttendees = this.totalTrainee.value + this.totalTrainer.value;
    this.trainerTraineeForm.controls.totalAttendees.setValue(this.totalAttendees);

    this.jobTrainee.removeAt(traineeIndex);
    this.checkTraineeDescrepancy();
    disableOptions('jobTraineeType', this.jobTrainee.value, this.byJobClassification);
  }

  startDateChanged(event) {
    this.disableEndDate = false;
    this.minEndDate = event.value;
  }

  checkError(a, b) {
    return false;
  }

  onFinanceChange() {
    if (this.trainerTraineeForm.controls.mAndE.value === true || this.trainerTraineeForm.controls.lodging.value === true) {
      this.flatRateDisabled = true;
      this.nonFlatRateDisabled = false;
    } else if (this.trainerTraineeForm.controls.flatRate.value === true) {
      this.flatRateDisabled = false;
      this.nonFlatRateDisabled = true;
    } else {
      this.flatRateDisabled = false;
      this.nonFlatRateDisabled = false;
    }
  }

  saveEventRequest() {
    const eventRequestParams = this.eventRequestForm.value as EventRequest;
    eventRequestParams.eventRequester = this.currentUser.email;
    console.log(eventRequestParams);

    // this.eventService.createEventRequest(eventRequestParams).subscribe(
    //   (resp) => {
    //     if (resp.success === true) {
    //       this.alertService.success(EVENT_REQUEST_SUCCESS_MESSAGE);
    //       // if (this.checkValidationRequired(button)) {
    //       this.router.navigateByUrl('/events/' + resp.model);
    //       // }
    //     } else {
    //       this.alertService.error(EVENT_REQUEST_ERROR_MESSAGE);
    //     }
    //   },
    //   (err) => {
    //     this.alertService.error(
    //       'Error creating event request. Please try again later.'
    //     );
    //   }
    // );
  }

  initiateEditMode(eventId) {
    this.editMode = true;

    this.eventService.getEventTestId(eventId).subscribe(data => {
      this.eventDetail = data;

      this.eventRequestForm = this.createEventRequestForm(this.eventDetail);
    });
  }

  // getVenue(event) {
  // this.venueDisabled = false;

  // const selectedCityId = event.value;
  // this.lookupService.getLookups(selectedCityId).subscribe(data => {
  //   this.venue = data;
  // });

  // }

  updateEventRequest() {
    const editedEventRequestParams = this.eventRequestForm.value as EventRequest;
    console.log(editedEventRequestParams);
  }
}
